import React from 'react';
import { Notification, NotificationType } from '../notifications.models';
import * as layouts from '../../widgets/layouts';
import { TileNotificationsItemBody, TileNotificationsItemBodyClose, TileNotificationsItemBodyContent } from './tile-hub.styles';

export function TileLayoutContainer(props: Notification & { onAction: () => void }): JSX.Element {
  if (props.type === NotificationType.Activity) {
    return <layouts.ActivityTileLayout {...props} />;
  }

  if (props.type === NotificationType.LoginStreak) {
    return <layouts.LoginStreakTileLayout {...props} />;
  }

  return (
    <TileNotificationsItemBody>
      <TileNotificationsItemBodyContent>
        <h6>{props.title}</h6>
        <p>{props.message}</p>
      </TileNotificationsItemBodyContent>

      <TileNotificationsItemBodyClose>
        <button onClick={props.onAction} />
      </TileNotificationsItemBodyClose>
    </TileNotificationsItemBody>
  );
}
