import { colourString } from '@keplerco/core';
import React, { PropsWithChildren } from 'react';

export function EntitiesCardWidget({ title, description, children }: PropsWithChildren<{ title: string; description?: string }>): JSX.Element {
  return (
    <div className="card" style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <h5 className="heading5">{title}</h5>
      {!!description && (
        <p className="body2" style={{ color: colourString('text_1') }}>
          {description}
        </p>
      )}
      <div style={{ marginTop: !!children ? 5 : 0 }}>{children}</div>
    </div>
  );
}
