import React, { useState } from 'react';
import { useAppState } from '../../../overmind';
import { PageHeader, Tab, Tabs } from '@keplerco/core';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { OrganizationLevelType } from '../../../enums';
import { CompanyRolesLayout } from './company-roles.layout';
import { GlobalRolesLayout } from './global-roles.layout';
import { TabType } from './roles.models';

export function RolesPage(): JSX.Element {
  const { permissions, user } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);

  const [activeTabType, setActiveTabType] = useState<TabType>('Company');

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader
        breadcrumbs={[{ name: 'Role Management', url: window.location.pathname }]}
        title="Role Management"
        subtitle="A library of all the roles within your company"
      />

      {(user?.isSystemAdmin || organizationLevel?.organizationLevel === OrganizationLevelType.Company) && (
        <Tabs static>
          <Tab active={activeTabType === 'Company'} id="Company" name="Company roles" onClick={() => setActiveTabType('Company')} />
          <Tab active={activeTabType === 'Global'} id="Global" name="Global roles" onClick={() => setActiveTabType('Global')} />
        </Tabs>
      )}

      {activeTabType === 'Global' ? <GlobalRolesLayout /> : <CompanyRolesLayout />}
    </div>
  );
}
