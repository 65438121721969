export enum ResponseGroup {
  Overall = 1,
  SkillSubType = 2,
  Skill = 3,
  SkillType = 4,
}

export enum UrgencyIndication {
  BelowExpectation = 'Needs urgent improvement',
  MeetsExpectation = 'Needs improvement',
  ExceedsExpectation = 'Needs maintenance',
}
