import React, { useState } from 'react';
import { SmartSelect, SmartSelectSection, PageHeader, Panel } from '@keplerco/core';
import { BulkUploadDto, UserBulkUploadRow } from '../../../../../models/bulk-uploads/bulk-upload-dto';
import { BulkUpload } from '../bulk-upload.widget';
import { BulkUploadType } from '../../../../../enums/bulk-upload-type';
import { BulkUploadPreviewWidget } from '../bulk-upload-preview/bulk-upload-preview.widget';
import BulkUploadEditFocusPanel from '../bulk-upload-edit.focus-panel';
import * as base from '../../../../../overmind/actions/base';
import { useParams } from 'react-router-dom';
import styles from './bulk-add-people.module.css';

export function BulkAddPeople() {
  const params = useParams();
  const [activeStep, setActiveStep] = useState<number>(0);

  const [editedRows, setEditedRows] = useState<Set<string>>(new Set());
  const [selectedRow, setSelectedRow] = useState<UserBulkUploadRow | undefined>(undefined);

  const [data, setData] = useState<BulkUploadDto<UserBulkUploadRow> | undefined>(undefined);

  function handleNextStep() {
    setActiveStep(prevStep => prevStep + 1);
  }

  function updateRow(updatedRow: UserBulkUploadRow): void {
    setData(currentRows => {
      if (currentRows && currentRows.rows) {
        const rowIndex = currentRows.rows.findIndex(row => row.rowNumber === updatedRow.rowNumber);

        if (rowIndex !== -1) {
          const updatedData = [...currentRows.rows];
          updatedData[rowIndex] = { ...updatedRow };

          setEditedRows(currentEdited => {
            const newEditedRows = new Set(currentEdited);
            newEditedRows.add(updatedRow.email);
            return newEditedRows;
          });

          return { ...currentRows, rows: updatedData };
        }
      }
      return currentRows;
    });

    setSelectedRow(undefined);
  }

  function resetBulkUpload() {
    setData(undefined);
    setSelectedRow(undefined);
    setActiveStep(0);
  }

  const sections = [
    <SmartSelectSection
      key="upload"
      headerContent={
        <div className={styles.bulkUploadHeader}>
          <h2 className="heading2">Bulk Upload People</h2>
          <h6 className="subtitle">
            <a href={base.url(`bulkupload`, BulkUploadType.Users, `excel-template?companySlug=${params.companySlug}`)}>Download Excel</a> or <a href={base.url(`bulkupload`, BulkUploadType.Users, `csv-template?companySlug=${params.companySlug}`)}>download CSV</a> template to get started with a bulk
            upload.
          </h6>
        </div>
      }
    >
      <BulkUpload
        bulkUploadType={BulkUploadType.Users}
        onValidate={() => {
          handleNextStep();
        }}
        setData={setData}
      />
    </SmartSelectSection>,

    activeStep >= 1 && data && (
      <SmartSelectSection key="preview" headerContent={<PageHeader title={'Upload Preview'} subtitle={'Take a moment to review your bulk upload preview, ensuring accuracy before finalising, and make any necessary adjustments to guarantee seamless data integration.'} />}>
        <BulkUploadPreviewWidget
          setEditedRows={setEditedRows}
          setData={setData}
          data={data}
          editedRows={editedRows}
          setSelectedRow={row => {
            setSelectedRow(row);
          }}
          next={handleNextStep}
          reset={resetBulkUpload}
        />
      </SmartSelectSection>
    ),
  ].filter(Boolean);

  return (
    <div className="pageWrapper wrapper">
      <SmartSelect headerDivider={false}>
        {sections as JSX.Element[]}
      </SmartSelect>

      <Panel open={!!selectedRow} onClose={() => setSelectedRow(undefined)}>
        {selectedRow && <BulkUploadEditFocusPanel selectedRow={selectedRow} onSubmit={updateRow} />}
      </Panel>
    </div>
  );
}
