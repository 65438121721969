import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { DepartmentsCMSPage } from '../../pages/administration/departments/departments.cms.page';
import { DepartmentTeamsCMSPage } from '../../pages/administration/teams/department-teams.cms.page';
import { TagManagementCMSPage } from '../../pages/administration/tag-management/tag-management.cms.page';
import { DepartmentPeopleCMSPage } from '../../pages/administration/people/pages/department-people.cms.page';
import { CompanyTeamsCMSPage } from '../../pages/administration/teams/company-teams.cms.page';
import { TeamPeopleCMSPage } from '../../pages/administration/people/pages/team-people.cms.page';
import { CompanyPeopleCMSPage } from '../../pages/administration/people/pages/company-people.cms.page';
import { OrganizationLevelType } from '../../enums';
import { PermissionType } from '../../enums/permission-type';
import { PageGuard } from '../guards/page.guard';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';
import { BulkAddPeople } from '../../pages/administration/people/bulk-upload/bulk-add-people/bulk-add-people';

export function AdministrationRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.administrationBase);

  return (
    <Routes>
      <Route path={PagePath.administrationPeople} element={<CompanyPeopleCMSPage />} />

      <Route path={PagePath.administrationPeopleBulkUpload} element={<BulkAddPeople />} />
      <Route
        path={PagePath.administrationDepartments}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Administration,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: true },
              ],
            }}
          >
            <DepartmentsCMSPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.administrationDepartmentTeams}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Administration,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
              ],
            }}
          >
            <DepartmentTeamsCMSPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.administrationDepartmentPeople}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Administration,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
              ],
            }}
          >
            <DepartmentPeopleCMSPage />
          </PageGuard>
        }
      />

      <Route
        path={PagePath.administrationTeams}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Administration,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Team, multipleEntities: true },
              ],
            }}
          >
            <CompanyTeamsCMSPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.administrationTeamPeople}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Administration,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Team, multipleEntities: false },
              ],
            }}
          >
            <TeamPeopleCMSPage />
          </PageGuard>
        }
      />

      <Route
        path={PagePath.administrationTags}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Administration,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <TagManagementCMSPage />
          </PageGuard>
        }
      />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.administrationBase}${PagePath.administrationPeople}`} />} />
    </Routes>
  );
}
