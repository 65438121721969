import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { AnalyticsOverviewPage } from '../../pages/analytics/overview/overview.page';
import { AnalyticsEngagementPage } from '../../pages/analytics/engagement/engagement.page';
import { DepartmentEntitiesPage } from '../../pages/analytics/entities/department-entities.page';
import { DepartmentEntityPage } from '../../pages/analytics/entity/department-entity.page';
import { TeamEntitiesPage } from '../../pages/analytics/entities/team-entities.page';
import { TeamEntityPage } from '../../pages/analytics/entity/team-entity.page';
import { SkillPage } from '../../pages/analytics/skills/skill.page';
import { AnalyticsReportTemplatesPage } from '../../pages/analytics/report-templates/report-templates.page';
import { PeoplePage } from '../../pages/analytics/people/people.page';
import { PersonPage } from '../../pages/analytics/people/person/person.page';
import { ReportTemplatePage } from '../../pages/analytics/report-templates/report-template.page';
import { ReportTemplateNamePage } from '../../pages/analytics/report-templates/report-template-name.page';
import { OrganizationLevelType } from '../../enums';
import { PermissionType } from '../../enums/permission-type';
import { SkillsPage } from '../../pages/analytics/skills/skills.page';
import { PageGuard } from '../guards/page.guard';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { SkillSubTypePage } from '../../pages/analytics/skills/skill-sub-type.page';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';
import { RolesPage } from '../../pages/analytics/roles/roles.page';
import { RolePage } from '../../pages/analytics/roles/role.page';
import { ComparisonToolsPage } from '../../pages/analytics/comparison-tools/role-fit-comparison.page';

export function AnalyticsRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.analyticsBase);

  return (
    <Routes>
      <Route path={PagePath.analyticsOverview} element={<AnalyticsOverviewPage />} />
      <Route path={PagePath.analyticsEngagement} element={<AnalyticsEngagementPage />} />

      <Route
        path={PagePath.analyticsDepartments}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: true },
              ],
            }}
          >
            <DepartmentEntitiesPage />
          </PageGuard>
        }
      />

      <Route
        path={PagePath.analyticsDepartment}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
              ],
            }}
          >
            <DepartmentEntityPage />
          </PageGuard>
        }
      />

      <Route
        path={PagePath.analyticsTeams}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Team, multipleEntities: true },
              ],
            }}
          >
            <TeamEntitiesPage />
          </PageGuard>
        }
      />

      <Route
        path={PagePath.analyticsTeam}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Department, multipleEntities: false },
                { organizationLevelType: OrganizationLevelType.Team, multipleEntities: false },
              ]
            }}
          >
            <TeamEntityPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.analyticsRoles}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
              ],
            }}
          >
            <RolesPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.analyticsRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
              ]
            }}
          >
            <RolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.analyticsComparisonTools}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.Analytics,
              organizationLevels: [
                { organizationLevelType: OrganizationLevelType.Company, multipleEntities: false },
              ]
            }}
          >
            <ComparisonToolsPage />
          </PageGuard>
        }
      />
      < Route path={PagePath.analyticsPeople} element={<PeoplePage />} />
      < Route path={PagePath.analyticsPerson} element={<PersonPage />} />
      < Route path={PagePath.analyticsSkills} element={<SkillsPage />} />
      < Route path={PagePath.analyticsSkillSubType} element={<SkillSubTypePage />} />
      < Route path={PagePath.analyticsSkill} element={<SkillPage />} />
      < Route path={PagePath.analyticsReportTemplates} element={<AnalyticsReportTemplatesPage />} />
      < Route path={PagePath.analyticsReportTemplate} element={<ReportTemplatePage />} />
      < Route path={PagePath.analyticsReportTemplateName} element={<ReportTemplateNamePage />} />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.analyticsBase}${PagePath.analyticsOverview}`} />} />
    </Routes >
  );
}

