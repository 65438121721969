import { Context } from '../..';
import { DisplayAreas } from '../../../enums';
import { setSubDomain } from './company';
import { getAssessmentYearLearning, getAssignedLearning } from './courses';
import { syncCourseContent } from './data-sync';
import { getUser, logDailyLogin } from './user';
import { getUserPermissions } from './permissions';
import { getForcedSkillAssessmentConfiguration } from './learner-assessment';
import { getNotifications } from './notifications';
import { identify } from '../../../library/helpers/segment';

export async function initialiseForeground(context: Context): Promise<void> {
  context.state.isInitialising = true;

  await setSubDomain();

  await getUser(context);
  await getUserPermissions(context);

  await getForcedSkillAssessmentConfiguration(context);

  context.state.isInitialising = false;
}

export async function initialiseBackground(context: Context): Promise<void> {
  await syncCourseContent(context);
  await getAssignedLearning(context);
  await getAssessmentYearLearning(context);
  await getNotifications(context, {
    size: 20,
    activeOnly: true,
    displayArea: DisplayAreas.EntireApplication,
  });
  await logDailyLogin();

  if (!!context.state.user?.learnerSlug) await identify(context.state.user.learnerSlug, context.state.user);
}
