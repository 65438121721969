import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function ProfileSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ paddingTop: 50, paddingBottom: 30 }}>
        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="150" width="150" borderRadius="50%" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={5}>
          <SkeletonLoader height="150" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="350" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="170" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn>
          <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="450" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginLeft: 0, marginRight: 0 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="70" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
