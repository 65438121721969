import React from 'react';
import { SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function ManageRoleSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoader height="145px" />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoader height="295px" />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoader height="580px" />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoader height="580px" />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoader height="85px" />
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
