import React, { useEffect, useState } from 'react';
import { OrganizationLevelType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { People } from '../../../models/view/people';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { PersonCard } from './person-card/person-card';
import { AnalyticsPageHeaderContainer } from '../analytics.styles';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PeopleSearchParams } from '../../../models/overmind/search-params';
import { PeoplePageActionsContainer, PeoplePageFilterActionsContainer, PeoplePageLayoutActionContainer, PeoplePagePagerContainer } from './people.styles';
import { DeveloperConsole, GridIcon, GridItemLayout, GridLayout, ListIcon, ListItemLayout, ListLayout, OrderButton, OrderType, PageHeader, Pager, Searchfield, ToggleButtonGroup, useMatchScreenWidth } from '@keplerco/core';

const pageSize = 9;
const skeletons = Array.from(Array(pageSize)).map((_, i) => i + 1);
const labelMap = new Map([
  [OrderType.Ascending, 'Sort: A-Z'],
  [OrderType.Descending, 'Sort: Z-A'],
  [OrderType.Organic, 'No Sorting'],
]);

const devConsole = new DeveloperConsole('TODO:', ['#E5145F', '#FFFFFF']);

export function PeoplePage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [sortType, setSortType] = useState<OrderType>(OrderType.Ascending);
  const [entity, setEntity] = useState<PermissionsEntity>();
  const [page, setPage] = useState<number>(1);
  const [viewType, setViewType] = useState<string>('Grid');
  const [people, setPeople] = useState<People>();

  useEffect(() => {
    async function getData() {
      setLoading(true);

      if (!entity) return;

      const request: PeopleSearchParams = {
        search: search,
        sortAscending: sortType !== OrderType.Descending,
        page: page,
        pageSize,
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
      };
      if (entity.organizationLevel === OrganizationLevelType.Department) request.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) request.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) request.learnerSlug = entity.entity.slug;
      const response = await actions.analyticsGetPeople(request);
      setPeople(response);

      setLoading(false);
    }

    getData();
  }, [search, sortType, entity, page]);

  useEffect(() => {
    if (isMobile && viewType !== 'Grid') setViewType('Grid');
  }, [isMobile]);

  return (
    <div className="pageWrapper wrapper">
      <AnalyticsPageHeaderContainer>
        <PageHeader
          breadcrumbs={[
            {
              name: 'Analytics',
              url: `${PagePath.analyticsBase}`,
            },
            {
              name: 'People',
              url: `${PagePath.analyticsBase}/${PagePath.analyticsPeople}`,
            },
          ]}
          title="People"
        />
      </AnalyticsPageHeaderContainer>

      <PeoplePageActionsContainer>
        <PeoplePageFilterActionsContainer>
          <Searchfield
            loading={loading && !!search}
            value={search}
            responsive={isMobile}
            onInput={tempSearch => {
              setSearch(tempSearch);
              setPage(1);
            }}
          />

          <OrderButton
            defaultOrderType={sortType}
            labelMap={labelMap}
            variant="small"
            onChange={tempSortType => {
              setSortType(tempSortType);
              setPage(1);
            }}
          />

          <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />
        </PeoplePageFilterActionsContainer>

        <PeoplePageLayoutActionContainer>
          {!isMobile && (
            <ToggleButtonGroup
              defaultButtonLabel={viewType}
              buttons={[
                { label: 'Grid', icon: <GridIcon /> },
                { label: 'List', icon: <ListIcon /> },
              ]}
              onClick={setViewType}
            />
          )}
        </PeoplePageLayoutActionContainer>
      </PeoplePageActionsContainer>

      {loading && (
        <React.Fragment>
          {viewType === 'Grid' ? (
            <GridLayout columnCount={3}>
              {skeletons.map(skeleton => {
                return (
                  <GridItemLayout key={skeleton}>
                    <SkeletonLoader height="160" />
                  </GridItemLayout>
                );
              })}
            </GridLayout>
          ) : (
            <ListLayout>
              {skeletons.map(skeleton => {
                return (
                  <ListItemLayout key={skeleton}>
                    <SkeletonLoader height="110" />
                  </ListItemLayout>
                );
              })}
            </ListLayout>
          )}
        </React.Fragment>
      )}

      {!loading && !people?.people.length && <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={450} title="No people found" />}

      {!loading && !!people?.people.length && (
        <React.Fragment>
          {viewType === 'Grid' ? (
            <GridLayout columnCount={3}>
              {people.people.map(person => (
                <GridItemLayout key={person.slug} onClick={() => devConsole.log('Add interactive prop to <layouts.ListItemLayout> and <layouts.GridItemLayout> so that onClick is not required for hover behaviour.')}>
                  <PersonCard viewType="Grid" companySlug={companyVariables.slug!} name={person.name} slug={person.slug} role={person.jobTitle} department={person.department} isMobile={isMobile} />
                </GridItemLayout>
              ))}
            </GridLayout>
          ) : (
            <ListLayout>
              {people.people.map(person => {
                return (
                  <ListItemLayout key={person.slug} onClick={() => devConsole.log('Add interactive prop to <layouts.ListItemLayout> and <layouts.GridItemLayout> so that onClick is not required for hover behaviour.')}>
                    <PersonCard viewType="List" companySlug={companyVariables.slug!} name={person.name} slug={person.slug} role={person.jobTitle} department={person.department} isMobile={isMobile} />
                  </ListItemLayout>
                );
              })}
            </ListLayout>
          )}

          {people.totalPages > 1 && (
            <PeoplePagePagerContainer>
              <PagerConnector
                pageCount={people.totalPages}
                onPageChange={tempPage => {
                  if (tempPage === page) return;
                  setPage(tempPage);
                }}
              >
                {connector => <Pager {...connector} />}
              </PagerConnector>
            </PeoplePagePagerContainer>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

