import React, { useState } from 'react';
import { Anchor, ListItemLayout, ListLayout } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import styles from './roles.module.css';
import classNames from 'classnames';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { CompanyEntityListResponse } from '../../../models/overmind/company-entity';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { OrganizationLevelType, SortField } from '../../../enums';
import { FuzzySearch } from '../../../components/inputs/fuzzy-search/fuzzy-search';

export function GlobalRolesLayout(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.skills?.organizationLevels);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<CompanyEntityListResponse>();

  async function searchGlobalRoles(search: string) {
    if (!search) return setData(undefined);

    setLoading(true);

    const request: CompanyEntitySearchParams = {
      search,
      sortAscending: true,
      sortField: SortField.Name,
      pageSize: 6,
      page: 1,
      organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      searchGlobally: true,
    };
    const response = await actions.getCompanyRoles(request);
    setData(response);

    setLoading(false);
  }

  return (
    <FuzzySearch
      fullWidthSearch
      noBorders
      hideSubtitle
      loading={loading}
      onFuzzySearch={params => searchGlobalRoles(params.query)}
      emptyState={{ title: 'Search for roles from the ESCO database', badgeUrl: themedAssetUrl('graphics/empty-state-clouds.graphic.svg'), badgeIconWidth: 'full-width' }}
    >
      {!!data?.entities.length && (
        <ListLayout>
          {data.entities.map(role => (
            <ListItemLayout key={role.slug}>
              <div className={classNames(styles.globalRoleCard, 'card')}>
                <h5 className="heading5">{role.name}</h5>

                {!role.slug ? (
                  <p className="caption2">Role cannot be viewed</p>
                ) : (
                  <Anchor onClick={() => keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementGlobalRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug)}`)}>
                    View Role
                  </Anchor>
                )}
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      )}
    </FuzzySearch>
  );
}

