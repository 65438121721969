import React from 'react';

export function ExceedsExpectationsIcon(): JSX.Element {
  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none">
      <g clipPath="url(#clip0_5328_49882)">
        <g opacity="0.4">
          <path
            style={{ mixBlendMode: 'multiply' }}
            d="M29.983 58.4284C46.5416 58.4284 59.965 55.0953 59.965 50.9836C59.965 46.872 46.5416 43.5388 29.983 43.5388C13.4244 43.5388 0.000976562 46.872 0.000976562 50.9836C0.000976562 55.0953 13.4244 58.4284 29.983 58.4284Z"
            fill="url(#paint0_radial_5328_49882)"
          />
        </g>
        <path d="M30.6444 44.0128C39.769 44.0128 47.1658 36.6159 47.1658 27.4914C47.1658 18.3669 39.769 10.97 30.6444 10.97C21.5199 10.97 14.123 18.3669 14.123 27.4914C14.123 36.6159 21.5199 44.0128 30.6444 44.0128Z" fill="url(#paint1_radial_5328_49882)" />
        <path style={{ mixBlendMode: 'multiply' }} d="M30.6444 44.0128C39.769 44.0128 47.1658 36.6159 47.1658 27.4914C47.1658 18.3669 39.769 10.97 30.6444 10.97C21.5199 10.97 14.123 18.3669 14.123 27.4914C14.123 36.6159 21.5199 44.0128 30.6444 44.0128Z" fill="url(#paint2_radial_5328_49882)" />
        <path style={{ mixBlendMode: 'screen' }} d="M30.6444 44.0128C39.769 44.0128 47.1658 36.6159 47.1658 27.4914C47.1658 18.3669 39.769 10.97 30.6444 10.97C21.5199 10.97 14.123 18.3669 14.123 27.4914C14.123 36.6159 21.5199 44.0128 30.6444 44.0128Z" fill="url(#paint3_radial_5328_49882)" />
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M42.3265 39.1741C48.7785 32.7221 48.7785 22.2613 42.3265 15.8093C35.8745 9.35731 25.4137 9.35731 18.9617 15.8093C12.5097 22.2613 12.5097 32.7221 18.9617 39.1741C25.4137 45.6261 35.8745 45.6261 42.3265 39.1741Z"
          fill="url(#paint4_radial_5328_49882)"
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M42.3296 39.1777C48.7836 32.7237 48.7836 22.2597 42.3296 15.8057C35.8755 9.35166 25.4115 9.35166 18.9575 15.8057C12.5035 22.2597 12.5035 32.7237 18.9575 39.1777C25.4115 45.6317 35.8755 45.6317 42.3296 39.1777Z"
          fill="url(#paint5_radial_5328_49882)"
        />
        <ellipse cx="30.4561" cy="19.1472" rx="10" ry="6.17647" fill="url(#paint6_linear_5328_49882)" />
        <g filter="url(#filter0_d_5328_49882)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.363 23.8444L32.363 34.7353C32.363 35.7099 31.5729 36.5 30.5983 36.5C29.6236 36.5 28.8336 35.7099 28.8336 34.7353L28.8336 23.8442L26.1064 26.5714C25.4173 27.2605 24.2999 27.2605 23.6108 26.5714C22.9216 25.8822 22.9216 24.7649 23.6108 24.0757L29.3504 18.3361C30.0395 17.6469 31.1569 17.6469 31.846 18.3361L37.5857 24.0757C38.2748 24.7649 38.2748 25.8822 37.5857 26.5714C36.8965 27.2605 35.7792 27.2605 35.09 26.5714L32.363 23.8444Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_5328_49882" x="23.0938" y="17.8193" width="15.0088" height="18.9307" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.494118 0 0 0 0 0.768627 0 0 0 0 0.254902 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5328_49882" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5328_49882" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_5328_49882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.8723 51.1081) scale(13.1504 3.3254)">
          <stop stopColor="#6AAF47" />
          <stop offset="1" stopColor="#63AB3F" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint1_radial_5328_49882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.9455 24.3233) scale(20.3345 20.3345)">
          <stop stopColor="#CEEAAB" />
          <stop offset="0.05" stopColor="#CBEAA5" />
          <stop offset="0.12" stopColor="#C3EA93" />
          <stop offset="0.2" stopColor="#B5EA75" />
          <stop offset="0.26" stopColor="#A9EA5A" />
          <stop offset="0.39" stopColor="#A3E557" />
          <stop offset="0.58" stopColor="#91D84E" />
          <stop offset="0.8" stopColor="#73C23F" />
          <stop offset="1" stopColor="#51A92E" />
        </radialGradient>
        <radialGradient id="paint2_radial_5328_49882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.0621 35.3373) scale(23.3917 23.3917)">
          <stop stopColor="#86B86D" />
          <stop offset="0.33" stopColor="#B6D4A7" />
          <stop offset="0.78" stopColor="white" />
        </radialGradient>
        <radialGradient id="paint3_radial_5328_49882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.8064 20.013) rotate(144.18) scale(23.3917 23.3917)">
          <stop />
          <stop offset="0.44" />
          <stop offset="0.51" />
          <stop offset="0.61" stopColor="#030303" />
          <stop offset="0.69" stopColor="#0C0C0C" />
          <stop offset="0.76" stopColor="#1C1C1C" />
          <stop offset="0.82" stopColor="#323232" />
          <stop offset="0.87" stopColor="#4E4E4E" />
          <stop offset="0.93" stopColor="#707070" />
          <stop offset="0.96" stopColor="#898989" />
          <stop offset="0.97" stopColor="#8D8D8E" />
          <stop offset="0.98" stopColor="#9A9B9B" />
          <stop offset="0.99" stopColor="#AFB0B2" />
          <stop offset="1" stopColor="#BBBDBF" />
        </radialGradient>
        <radialGradient id="paint4_radial_5328_49882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.4649 32.6738) rotate(8.93059) scale(23.4407)">
          <stop />
          <stop offset="0.71" />
          <stop offset="0.83" />
          <stop offset="0.92" stopColor="#020202" />
          <stop offset="0.95" stopColor="#090909" />
          <stop offset="0.97" stopColor="#151515" />
          <stop offset="0.99" stopColor="#262626" />
          <stop offset="1" stopColor="#363636" />
        </radialGradient>
        <radialGradient id="paint5_radial_5328_49882" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.9011 35.1761) rotate(-100.993) scale(28.1914)">
          <stop />
          <stop offset="0.65" />
          <stop offset="0.76" />
          <stop offset="0.82" stopColor="#060606" />
          <stop offset="0.9" stopColor="#161616" />
          <stop offset="0.98" stopColor="#303030" />
          <stop offset="1" stopColor="#363636" />
        </radialGradient>
        <linearGradient id="paint6_linear_5328_49882" x1="30.4561" y1="12.9707" x2="30.4561" y2="25.3236" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.74" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5328_49882">
          <rect width="60" height="60" fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
}
