import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { AuthenticationStatus as STATUS } from '../../../enums/authentication-status';
import { AccountPageContainer, AccountPageForm } from '../account.styles';
import { ResetPassword } from '../../../models/view/reset-password';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, ConfirmationPasswordValidator, EmailValidator, FormControl, RequiredValidator, Textfield } from '@keplerco/core';
import { PasswordStrength } from '../password-strength/password-strength';
import { ShapesBackgroundWrapper } from '../../../design/layout.styles';

export function ResetPasswordPage(): JSX.Element {
  const actions = useAppActions();
  const { authenticationStatus, passwordErrors } = useAppState<KeplerState>();
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const [fv, setFv] = useState<{ [key: string]: any }>();
  const { control, handleSubmit, getValues, watch } = useForm<any>({ reValidateMode: 'onChange' });

  watch(value => setFv(value));

  return (
    <ShapesBackgroundWrapper>
      <AccountPageContainer>
        <div className="card">
          <AccountPageLoaderLayer path={PagePath.accountResetPassword}>
            <h1 className="heading1">Reset your password</h1>
            <h6 className="subtitle">Enter the email address that you signed up with and confirm your new password</h6>

            <AccountPageForm
              id="resetPassword"
              autoComplete="off"
              onSubmit={handleSubmit(async (value: ResetPassword) => {
                value.code = params.get('code')?.toString();
                actions.startLoader({ path: PagePath.accountActivate, type: FetchType.PageFetching });

                if (authenticationStatus === STATUS.Authenticated) {
                  await actions.setPassword(value);
                  navigate(PagePath.profile);
                } else {
                  await actions.setPasswordAndLogin(value);
                  navigate(PagePath.root);
                }

                actions.stopLoader(PagePath.accountActivate);
              })}
            >
              <FormControl paddingBottom={0} name="email" control={control} rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

              <FormControl
                paddingBottom={0}
                control={control}
                name="password"
                rules={new RequiredValidator('Enter your password')}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <Textfield {...field} haserror={!!fieldState.error} label="Password" type="password" responsive />
                    {!!fv?.password && <PasswordStrength password={fv.password} />}
                  </React.Fragment>
                )}
              />

              <FormControl
                paddingBottom={0}
                control={control}
                name="confirmPassword"
                rules={new ConfirmationPasswordValidator('Enter your confirmation password', getValues('password'))}
                render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Confirm Password" type="password" responsive />}
              />

              <Button fullWidthMobile type="button">
                Update
              </Button>

              {passwordErrors !== undefined &&
                passwordErrors?.length > 0 &&
                passwordErrors.map((error: string, index: number) => {
                  return (
                    <React.Fragment key={'error_' + index}>
                      <div className="formErrorMessage">{error}</div>
                    </React.Fragment>
                  );
                })}
            </AccountPageForm>
          </AccountPageLoaderLayer>
        </div>
      </AccountPageContainer>
    </ShapesBackgroundWrapper>
  );
}
