import { Context } from '../..';
import { AuthenticationStatus } from '../../../enums';
import * as base from '../base';
import { KeplerPointsRequest } from '../../../models/overmind/kepler-points-request';

export async function getTotalLearnerKeplerPoints(context: Context): Promise<number> {
  if (context.state.authenticationStatus !== AuthenticationStatus.Authenticated) return 0;
  const request: base.IRequest = { url: base.url(`keplerpoints`, `get-total-learner-kepler-points`), authenticated: true };
  const response: base.IResponse<number> = await base.request(request);
  return response.data ?? 0;
}

export async function getTotalKeplerPoints(context: Context, keplerPointsRequestModel: KeplerPointsRequest): Promise<number | undefined> {
  const queryParams = new URLSearchParams(keplerPointsRequestModel as any).toString();

  const request: base.IRequest = { url: base.url(`keplerpoints`, `get-kepler-points?${queryParams}`), authenticated: true };
  const response: base.IResponse<number> = await base.request(request);

  return response.data;
}
