import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Questionnaire, QuestionnaireSkill } from '../../../models/questionnaire';
import { useAppActions, useAppState } from '../../../overmind';
import { QuestionnaireWidget } from '../../../widgets';
import { AppPageWrapper } from '../../../design/layout.styles';
import { AnalysisPageHeader } from '../analysis.styles';
import { FetchType } from '../../../enums';
import { ExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/explainer-focus-panel/explainer-focus-panel.layout';
import { QuestionnaireSkeleton } from './questionnaires.skeleton';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PageHeader, Panel, capitalCase } from '@keplerco/core';

export function QuestionnairePage(): JSX.Element {
  const actions = useAppActions();
  const { skillAssessmentConfig } = useAppState();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const { questionnaireSlug } = useParams<any>();
  const [queriedSkill, setQueriedSkill] = useState<QuestionnaireSkill>();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisBehaviouralQuestionnaire, type: FetchType.PageFetching });
      const data = await actions.getQuestionnaires(questionnaireSlug!);
      setQuestionnaire(data);
      actions.stopLoader(PagePath.analysisBehaviouralQuestionnaire);
    }

    if (!questionnaire) getData();
  });

  return (
    <div style={{ minHeight: `calc(90vh - 75px)`, width: '100%' }}>
      <PageLoaderLayer path={PagePath.analysisBehaviouralQuestionnaire} skeletonLoader={<QuestionnaireSkeleton />}>
        <AppPageWrapper className="wrapper">
          <AnalysisPageHeader>
            <PageHeader
              breadcrumbs={[
                { name: 'Analysis', url: PagePath.analysisBase },
                { name: 'Questionnaires', url: `${PagePath.analysisBase}${PagePath.analysisBehavioural}` },
                { name: questionnaire?.skillSubTypeName || '', url: window.location.href },
              ]}
              title={questionnaire?.skillSubTypeName ?? ''}
              subtitle={questionnaire?.skillSubTypeDescription}
              hideContentOnMobile
            />
          </AnalysisPageHeader>

          <br />

          {!!questionnaire && (
            <QuestionnaireWidget
              {...questionnaire}
              onSkillQuery={skill => setQueriedSkill(skill)}
              onCompleteAll={async () => {
                const result = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug!);
                if (result?.assessmentResultReady) {
                  actions.clearSkillAssessmentConfig();
                  navigate(PagePath.yourSkillsBase);
                } else {
                  navigate(`${PagePath.analysisBase}${PagePath.analysisBehavioural}`);
                }
              }}
            />
          )}
        </AppPageWrapper>
      </PageLoaderLayer>

      <Panel open={!!queriedSkill} onClose={() => setQueriedSkill(void 0)}>
        {!!queriedSkill && <ExplainerFocusPanelLayout title={capitalCase(queriedSkill.skillName)} description={queriedSkill.skillDescription ?? ''} onClose={() => setQueriedSkill(void 0)} />}
      </Panel>
    </div>
  );
}
