import React, { PropsWithChildren } from 'react';
import { useAppState } from '../../overmind';
import { extractHighestOrganizationLevel } from '../../library/helpers/permissions/extract-highest-organization-level';
import { Navigate } from 'react-router-dom';
import { Mode, PagePath } from '../navigation.enums';
import { IPageGuardProps } from './guards.models';
import { useMatchScreenWidth } from '@keplerco/core';
import { extractPermission } from '../../library/helpers/permissions/extract-permission';

export function PageGuard(props: PropsWithChildren<IPageGuardProps>): JSX.Element {
  const { children, modeRequired, organizationLevelTypesRequired, desktopRequired, additionalLearningRequired } = props;

  const { user, mode, permissions } = useAppState();

  const isTablet = useMatchScreenWidth('tablet');

  // desktop
  if (!!desktopRequired) {
    if (isTablet) {
      return <Navigate to={PagePath.dashboard} />;
    }
  }

  // mode
  if (!!modeRequired) {
    if (modeRequired === Mode.LearningDashboard) {
      if (mode === Mode.PlatformManagement) {
        return <Navigate to={PagePath.companies} />;
      }
    }

    if (modeRequired === Mode.PlatformManagement) {
      if (!user) {
        return <Navigate to={PagePath.error.replace(':type', 'system').replace(':code', 'api')} />;
      }

      if (!user.isSystemAdmin) {
        return <Navigate to={PagePath.dashboard} />;
      }

      if (mode === Mode.LearningDashboard) {
        return <Navigate to={PagePath.dashboard} />;
      }
    }
  }

  // system admin OR organization level types
  if (!!organizationLevelTypesRequired) {
    if (!user) {
      return <Navigate to={PagePath.error.replace(':type', 'system').replace(':code', 'api')} />;
    }

    if (!user.isSystemAdmin) {
      const permission = extractPermission(permissions, organizationLevelTypesRequired.permissionType);
      if (!permission) {
        return <Navigate to={PagePath.dashboard} />;
      }

      const highestOrganizationLevel = extractHighestOrganizationLevel(permission.organizationLevels);
      if (!highestOrganizationLevel) {
        return <Navigate to={PagePath.dashboard} />;
      }

      const matchingOrganizationLevel = organizationLevelTypesRequired.organizationLevels.find(organizationLevel => organizationLevel.organizationLevelType === highestOrganizationLevel.organizationLevel);
      if (!matchingOrganizationLevel) {
        return <Navigate to={PagePath.dashboard} />;
      }

      if (matchingOrganizationLevel.multipleEntities && highestOrganizationLevel.entities.length < 2) {
        return <Navigate to={PagePath.dashboard} />;
      }
    }

    if (user.isSystemAdmin && mode === Mode.LearningDashboard) {
      return <Navigate to={PagePath.dashboard} />;
    }
  }

  // additional learning
  if (!!additionalLearningRequired) {
    if (!user) {
      return <Navigate to={PagePath.error.replace(':type', 'system').replace(':code', 'api')} />;
    }

    if (!user.allowOffPlatformLearning) {
      return <Navigate to={PagePath.learningJourneyBase} />;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
}

