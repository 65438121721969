import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function TeamsCMSSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="120" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={3} />

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="100vh" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}