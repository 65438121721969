import React, { Fragment, useState } from 'react';
import { PagePath } from '../../../navigation/navigation.enums';
import { Anchor, Button, colourString, PageHeader, SmartSelect, SmartSelectSection, Tooltip } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../overmind';
import { AssessmentsWizardDetailsWidget } from './assessments-wizard-details.widget';
import { AssessmentsWizardPreferencesWidget } from './preferences/assessments-wizard-preferences.widget';
import { AssessmentPreferencesResponse } from '../../../models/overmind/assessment-preferences';
import { AssessmentsWizardPeopleAndSkillsWidget } from './assessments-wizard-people-and-skills.widget';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { AssessmentType } from '../../../enums/assessment-type';
import { FetchType } from '../../../enums';
import { AssessmentsWizardTagsWidget } from './assessments-wizard-tags.widget';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';

enum Steps {
  Details = 'Details',
  Preferences = 'Preferences',
  PeopleAndSkills = 'People and Skills',
  Tags = 'Tags',
}

export interface AssessmentsWizardDetails {
  companySlug: string;
  slug: string | undefined;
  assessmentType: AssessmentType;
  name: string;
  keplerPointsAvailable: number;
}

export interface AssessmentWizardPeople {
  assessmentSlug: string;
  assignedTeamChampionId?: string | undefined;
  userIds: string[];
}

// TODO: onChange decrease index
export function AssessmentsWizardPeerPage(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [assessmentSlug, setAssessmentSlug] = useState<string>();
  const [preferences, setPreferences] = useState<AssessmentPreferencesResponse>({
    forceAssessment: false,
    minScore: 0,
    maxScore: 0,
  });

  const steps = [
    <SmartSelectSection
      key={Steps.Details}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Details}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Name your assessment, and choose how many Kepler Points you would like to award
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardDetailsWidget
        assessmentType={AssessmentType.PeerEndorsement}
        onSaveAndContinue={details => {
          setActiveIndex(1);
          setAssessmentSlug(details.slug);
        }}
      />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.Preferences}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Preferences}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Choose your preferences for your assessment
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardPreferencesWidget assessmentSlug={assessmentSlug} assessmentType={AssessmentType.PeerEndorsement} preferences={preferences} setPreferences={setPreferences} onSaveAndContinue={() => setActiveIndex(2)} />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.PeopleAndSkills}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.PeopleAndSkills}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Select the individuals and assign skills to them for assessment
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardPeopleAndSkillsWidget assessmentSlug={assessmentSlug} preferences={preferences} onSaveAndContinue={() => setActiveIndex(3)} />
    </SmartSelectSection>,
    <SmartSelectSection
      key={Steps.Tags}
      headerContent={
        <Fragment>
          <h5 className="heading5">{Steps.Tags}</h5>
          <p className="body2" style={{ color: colourString('text_1') }}>
            Assign tags to an assessment for analytics
          </p>
        </Fragment>
      }
    >
      <AssessmentsWizardTagsWidget assessmentSlug={assessmentSlug} />
    </SmartSelectSection>,
  ];

  return (
    <PageLoaderLayer path={PagePath.assessmentsWizardPeer}>
      <div className="wrapper stack" style={{ paddingBottom: 30, gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Assessments', url: `${PagePath.assessmentsBase}` },
            { name: 'Wizard', url: `${PagePath.assessmentsBase}${PagePath.assessmentsWizard.replace(':companySlug', companyVariables.slug!)}` },
            { name: 'Peer Assessment', url: `${PagePath.assessmentsBase}${PagePath.assessmentsWizardPeer.replace(':companySlug', companyVariables.slug!)}` },
          ]}
          title="Peer Assessment"
          subtitle="Create an assessment and get skill scores for your learners"
          divider
        />

        <div style={{ width: '100%' }}>
          {/* TODO: fix SmartSelect so this isn't required */}
          <SmartSelect>{steps.filter((_, index) => index <= activeIndex)}</SmartSelect>
        </div>

        <footer className="card" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Anchor arrow reverse onClick={() => keplerNavigate(`${PagePath.assessmentsBase}${PagePath.assessmentsWizard.replace(':companySlug', companyVariables.slug!)}`)}>
            Back
          </Anchor>

          <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
            {activeIndex < steps.length - 1 || !assessmentSlug ? (
              <Tooltip content="Complete all the above steps">
                <Button type="button" disabled>
                  Activate
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="button"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.assessmentsWizardFOP, type: FetchType.Sending });

                  await actions.completeEndorsementSkillAssignment({ assessmentSlug });
                  await actions.activateSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug });

                  keplerNavigate(`${PagePath.assessmentsBase}${PagePath.assessmentsOverview.replace(':companySlug', companyVariables.slug!)}`);
                  actions.stopLoader(PagePath.assessmentsWizardFOP);
                }}
              >
                Activate
              </Button>
            )}
          </div>
        </footer>
      </div>
    </PageLoaderLayer>
  );
}
