import React, { useEffect, useMemo, useState } from 'react';
import { PagePath } from '../../../navigation/navigation.enums';
import { Anchor, PageHeader, Pager, scoreLevelRAGColour, scorePercentageRAGColour, SearchBar, SkillLevel, SkillPercentage, Table, TableColumn, TableRow } from '@keplerco/core';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { OrganizationLevelType, ScoreType } from '../../../enums';
import { ScoreComparison } from '../../../enums/score-comparison';
import { ScoresResponse, EntityScores } from '../../../models/overmind/analytics';
import { ScoresSearchParams, SearchingSearchParams } from '../../../models/overmind/search-params';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { useAppActions, useAppState } from '../../../overmind';
import RankedEntitiesWidget from '../../../widgets/analytics/ranked-entities/ranked-entities.widget';

export function RolesPage() {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const keplerNavigate = useKeplerNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [roleScoresRespoonse, setRoleScoresResponse] = useState<ScoresResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const request: ScoresSearchParams & SearchingSearchParams = {
        sortAscending: true,
        page: currentPage,
        pageSize: 10,
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        search: searchValue,
        scoreType: ScoreType.Role,
        scoreTypeSlugs: undefined,
        scoreComparison: ScoreComparison.RoleExpectedProficiency,
      };
      const response = await actions.analyticsGetScoresByScoreType(request);
      setRoleScoresResponse(response);
      setLoading(false);
    }
    getData();
  }, [currentPage, searchValue]);

  const allEntity = useMemo(
    () => ({
      organizationLevel: OrganizationLevelType.Company,
      entity: { name: 'All', slug: companyVariables.slug! },
    }),
    [companyVariables.slug]
  );

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader
        breadcrumbs={[
          { name: 'Analytics', url: PagePath.analyticsBase },
          { name: 'Roles', url: window.location.pathname },
        ]}
        title="Roles"
        divider
      />

      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 15 }}>
        <h3 className='heading4'>Company roles</h3>
        <SearchBar label="Search" onInput={e => setSearchValue((e.target as HTMLInputElement).value)} />
        {!loading ? (
          <Table
            emptyState={<EmptyState title="There are no role analytics yet" />}
            footerContent={
              <PagerConnector onPageChange={page => setCurrentPage(page)} defaultPageNumber={currentPage} pageCount={roleScoresRespoonse?.totalPages ?? 0}>
                {connector => <Pager {...connector} />}
              </PagerConnector>
            }
          >
            {roleScoresRespoonse?.results.map((entityScore: EntityScores) => {
              const overallScore = entityScore.score;
              const expectedScore = entityScore.comparisonScore;
              return (
                <TableRow id={entityScore.slug} key={entityScore.slug}>
                  <TableColumn id="roleName" label="Role Name">
                    <Anchor
                      hovertype="opacity"
                      textTransform="none"
                      onClick={event => {
                        event.stopPropagation();
                        keplerNavigate(`${PagePath.analyticsBase}${PagePath.analyticsRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', entityScore.slug)}`);
                      }}
                    >
                      {entityScore.name}
                    </Anchor>
                  </TableColumn>
                  <TableColumn id="overallSkillProficiency" label="Overall Skill Proficiency">
                    {companyVariables.useLevels ? (
                      <SkillLevel
                        level={overallScore?.level ?? companyVariables.minLevel}
                        minLevel={companyVariables.minLevel}
                        maxLevel={companyVariables.maxLevel}
                        noLevel={!overallScore}
                        noLevelText="Not captured"
                        dotColour={scoreLevelRAGColour(overallScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                      />
                    ) : (
                      <SkillPercentage percentage={overallScore?.percentage ?? 0} noPercentageText="Not captured" noPercentage={!overallScore} barColour={scorePercentageRAGColour(overallScore?.percentage ?? 0)} />
                    )}
                  </TableColumn>
                  <TableColumn id="expectedSkillProficiency" label="Expected Skill Proficiency">
                    {companyVariables.useLevels ? (
                      <SkillLevel
                        level={expectedScore?.level ?? companyVariables.minLevel}
                        minLevel={companyVariables.minLevel}
                        maxLevel={companyVariables.maxLevel}
                        noLevel={!expectedScore}
                        noLevelText="Not captured"
                        dotColour={scoreLevelRAGColour(expectedScore?.level ?? companyVariables.minLevel, companyVariables.minLevel, companyVariables.maxLevel)}
                      />
                    ) : (
                      <SkillPercentage percentage={expectedScore?.percentage ?? 0} noPercentageText="Not captured" noPercentage={!expectedScore} barColour={scorePercentageRAGColour(expectedScore?.percentage ?? 0)} />
                    )}
                  </TableColumn>
                </TableRow>
              );
            })}
          </Table>
        ) : (
          <SkeletonLoader height="450px" />
        )}
      </div>
      <RankedEntitiesWidget
        title='Role skill ratings'
        rankingLabel='roles'
        byScoreType
        entity={allEntity}
      />
    </div>
  );
}
