import { AssessmentsSearchRequest } from '../../../models/overmind/search-request';
import { ReducerAction } from '../../../models/reducer-state';
import { SkillAssessmentsSearchResponse } from '../../../models/view/skill-assessments-search-response';
import { CascadingPanelConfig, AssessmentsOverviewHeader } from './assessments-overview.models';
import { SortField } from '../../../enums';
import { Employee } from '../../../models/view/employee';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { PagePath } from '../../../navigation/navigation.enums';
import { Breadcrumb } from '@keplerco/core';
import { SkillAssessment } from '../../../models';
import { FocusPanelViewData } from '../../../models/focus-panel.models';

interface AssessmentsOverviewState {
  crumbs: Breadcrumb[];
  request: AssessmentsSearchRequest;
  columnConfiguration: ColumnConfiguration[];

  data?: SkillAssessmentsSearchResponse;
  cascadingPanelsConfig?: CascadingPanelConfig;
  managingColumns?: boolean;
  isArchiveModalOpen?: boolean;
  isCloseModalOpen?: boolean;
  archiveSkillAssessmentData?: SkillAssessment;
  closeSkillAssessmentData?: SkillAssessment;
}

export const initialState: AssessmentsOverviewState = {
  crumbs: [],
  request: { sortAscending: true, sortField: SortField.Name, pageSize: 15 },
  columnConfiguration: [
    { label: 'Skill Assessment Name', key: 'name', required: true },
    { label: 'Date Created', key: 'dateCreated', required: false, selected: true },
    { label: 'Status', key: 'Status', required: false, selected: true },
    { label: 'Assessment Type', key: 'assessmentType', required: false, selected: true },
  ],
};

export enum AssessmentsOverviewActionTypes {
  SetHeader,
  SetRequest,
  SetData,
  SetSkillAssessmentToManage,
  SetCascadingPanelsConfig,
  ManageColumns,
  EditColumnConfiguration,
  SetArchiveConfirmationModalOpen,
  SetCloseConfirmationModalOpen,
}

type AssessmentsOverviewActions =
  | ReducerAction<AssessmentsOverviewActionTypes.SetHeader, { payload: AssessmentsOverviewHeader }>
  | ReducerAction<AssessmentsOverviewActionTypes.SetRequest, { payload: AssessmentsSearchRequest }>
  | ReducerAction<AssessmentsOverviewActionTypes.SetData, { payload: SkillAssessmentsSearchResponse | undefined }>
  | ReducerAction<AssessmentsOverviewActionTypes.SetSkillAssessmentToManage, { payload: FocusPanelViewData<Employee> }>
  | ReducerAction<AssessmentsOverviewActionTypes.SetCascadingPanelsConfig, { payload: CascadingPanelConfig }>
  | ReducerAction<AssessmentsOverviewActionTypes.EditColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<AssessmentsOverviewActionTypes.ManageColumns, { payload: { open: boolean; reset?: boolean } }>
  | ReducerAction<AssessmentsOverviewActionTypes.SetArchiveConfirmationModalOpen, { payload: { open: boolean; data?: SkillAssessment } }>
  | ReducerAction<AssessmentsOverviewActionTypes.SetCloseConfirmationModalOpen, { payload: { open: boolean; data?: SkillAssessment } }>;

export function reducer(state: AssessmentsOverviewState, action: AssessmentsOverviewActions): AssessmentsOverviewState {
  if (action.type === AssessmentsOverviewActionTypes.SetHeader) {
    let crumbs: Breadcrumb[];

    if (action.payload.entityName === 'Create Assessment') {
      crumbs = [
        { name: 'Assessments', url: PagePath.assessmentsBase },
        { name: 'Update Assessment', url: window.location.pathname },
      ];
    } else {
      crumbs = [{ name: 'Assessments', url: window.location.pathname }];
    }

    return {
      ...state,
      crumbs: crumbs,
    };
  }

  if (action.type === AssessmentsOverviewActionTypes.SetRequest) {
    return { ...state, request: action.payload };
  }

  if (action.type === AssessmentsOverviewActionTypes.SetData) {
    return { ...state, data: action.payload };
  }

  if (action.type === AssessmentsOverviewActionTypes.SetCascadingPanelsConfig) {
    return { ...state, cascadingPanelsConfig: action.payload };
  }

  if (action.type === AssessmentsOverviewActionTypes.ManageColumns) {
    if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
    else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
  }

  if (action.type === AssessmentsOverviewActionTypes.EditColumnConfiguration) {
    return { ...state, columnConfiguration: action.payload };
  }

  if (action.type === AssessmentsOverviewActionTypes.SetArchiveConfirmationModalOpen) {
    return { ...state, isArchiveModalOpen: action.payload.open, archiveSkillAssessmentData: action.payload.data };
  }

  if (action.type === AssessmentsOverviewActionTypes.SetCloseConfirmationModalOpen) {
    return { ...state, isCloseModalOpen: action.payload.open, closeSkillAssessmentData: action.payload.data };
  }
  return state;
}
