import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function SkillsReportSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={4}>
          <SkeletonLoader height="100" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={5} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="350" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={2} />

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="45" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={2} />
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

