import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { FetchStatus, FetchType } from '../../../../../enums';
import { KeplerState } from '../../../../../models/kepler-state';
import { useAppState } from '../../../../../overmind';
import { FocusPanelSkeletonLoader } from './focus-panel-skeleton-loader';
import { PagePath } from '../../../../../navigation/navigation.enums';
import styles from './focus-panel-loader-layer.module.css';

export function FocusPanelLoaderLayer(props: PropsWithChildren<{ path: PagePath; zIndex?: number; skeletonLoader?: JSX.Element }>): JSX.Element {
  const { zIndex = 10 } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const { fetchState } = useAppState<KeplerState>();

  const time = useRef<NodeJS.Timeout | undefined>(undefined);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const init = useRef<NodeJS.Timeout | undefined>(undefined);

  function showLoader(container: HTMLDivElement, loader: HTMLDivElement) {
    container.classList.add('loading');
    loader.classList.remove('hidden');
  }

  function hideLoader(container: HTMLDivElement, loader: HTMLDivElement) {
    container.classList.remove('loading');
    loader.classList.add('hidden');
  }

  // If the initial status is inactive, hide the loader after a certain amount of time.
  // Because the "hidden" class is added by default, without this an infinite loader would occur if no action is triggered by the page parent to stop the loader.
  useEffect(() => {
    if (fetchState[props.path].status === FetchStatus.Inactive)
      init.current = setTimeout(() => {
        const container = containerRef.current;
        const loader = loaderRef.current;

        if (!!container && !!loader) hideLoader(container, loader);
      }, 1000);
  }, [fetchState[props.path].status]);

  // If an init timeout exists and the status changes because and action was triggered by the parent component to start the loader, cancel hiding the loader.
  useEffect(() => {
    if (!!init.current && fetchState[props.path].status === FetchStatus.Active) clearTimeout(init.current);
  }, [fetchState[props.path].status]);

  useEffect(() => {
    if (fetchState[props.path].type !== FetchType.DialogFetching) return;

    if (fetchState[props.path].status === FetchStatus.Active) {
      time.current = setTimeout(() => {
        time.current = undefined;
        setForceUpdate(latest => latest + 1);
      }, 1000);

      setForceUpdate(latest => latest + 1);

      const container = containerRef.current;
      const loader = loaderRef.current;

      if (!!container && !!loader) showLoader(container, loader);
    }
  }, [fetchState[props.path]]);

  useEffect(() => {
    if (fetchState[props.path].type !== FetchType.DialogFetching) return;

    if (fetchState[props.path].status === FetchStatus.Inactive && !time.current) {
      const container = containerRef.current;
      const loader = loaderRef.current;

      if (!!container && !!loader) hideLoader(container, loader);
    }
  }, [fetchState[props.path].status, time, forceUpdate]);

  return (
    <div ref={containerRef} className={styles.focusPanelLoaderLayerContainer}>
      <div ref={loaderRef} className={classNames(styles.focusPanelLoaderLayerLoader, { hidden: process.env.NODE_ENV === 'development' })} style={{ zIndex }}>
        {!!props.skeletonLoader ? props.skeletonLoader : <FocusPanelSkeletonLoader />}
      </div>

      {props.children}
    </div>
  );
}
