import styled from 'styled-components';

export const RaterWrapper = styled.div`
  padding: 35px 0;
  border: solid 1px var(--borders);
  border-left: none;
  border-right: none;

  &.fade {
    opacity: 0.45;
  }
`;

export const RaterHeader = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 15px;

  &.disabled {
    opacity: 0.5;
  }
`;

export const RaterModuleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  @media screen and (max-width: 580px) {
    flex-flow: column nowrap;
    align-items: stretch;
  }
`;

export const RaterModuleExtraRatingOutlet = styled.div`
  display: block;

  @media screen and (max-width: 580px) {
    margin-left: auto;
  }
`;

export const RaterModuleExtraRatingCounterWrapper = styled.div`
  text-align: center;

  .caption2 {
    display: block;
    padding-bottom: 5px;
    font-weight: bold;
    color: var(--accent-2);
  }

  @media screen and (max-width: 580px) {
    display: flex;
    align-items: center;
    gap: 25px;

    .caption2 {
      padding-bottom: 0;
    }
  }
`;
