import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function CheckTeamSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={4}>
          <SkeletonLoaderRow style={{ marginBottom: 55 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="20px" width="260px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 15 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="50px" width="585px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 45 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="120px" width="585px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="50px" width="200px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoaderRow style={{ marginBottom: 5 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="30px" width="260px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 15 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="20px" width="260px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 10 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="45px" width="140px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 10 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="45px" width="145px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>

          <SkeletonLoaderRow style={{ marginBottom: 10 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="45px" width="135px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

