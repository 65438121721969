import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { ListItemLayout, ListLayout } from '@keplerco/core';

export function UpdateTagFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="25px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ borderBottom: '1px solid var(--borders)', marginBottom: 30, paddingBottom: 30 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SkeletonLoader height="45px" width="300px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <ListLayout>
            <ListItemLayout>
              <SkeletonLoader height="65px" />
            </ListItemLayout>

            <ListItemLayout>
              <SkeletonLoader height="65px" />
            </ListItemLayout>

            <ListItemLayout>
              <SkeletonLoader height="65px" />
            </ListItemLayout>
          </ListLayout>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <footer className="panelFooter">
        <SkeletonLoader height="25px" width="60px" />
        <SkeletonLoader height="50px" width="125px" />
      </footer>
    </div>
  );
}

