import React, { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IPagerConnectorProps } from './pager-connector.models';

const PAGER_PARAMS_KEY = 'pageNumber';

export function PagerConnector(props: IPagerConnectorProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const hasInitialised = useRef<boolean>(false);

  const pageNumber = useMemo(() => {
    if (!searchParams.get(PAGER_PARAMS_KEY)) setSearchParams({ [PAGER_PARAMS_KEY]: '1' });
    return parseInt(searchParams.get(PAGER_PARAMS_KEY) ?? '1');
  }, [searchParams.get(PAGER_PARAMS_KEY)]);

  useEffect(() => {
    if (typeof props.defaultPageNumber !== 'undefined' && props.defaultPageNumber === 1 && hasInitialised.current) {
      setSearchParams({ [PAGER_PARAMS_KEY]: props.defaultPageNumber!.toString() });
    }
  }, [props.defaultPageNumber]);

  useEffect(() => {
    if (pageNumber !== props.defaultPageNumber) {
      props.onPageChange(pageNumber);
    }

    hasInitialised.current = true;
  }, [pageNumber]);

  return (
    <React.Fragment>
      {props.children({
        pageCount: props.pageCount,
        activePageNumber: pageNumber,
        onPageChange(index) {
          if (pageNumber !== index) {
            setSearchParams({ [PAGER_PARAMS_KEY]: index.toString() });
          }
        },
      })}
    </React.Fragment>
  );
}

