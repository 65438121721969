import React, { useEffect, useState } from 'react';
import { SkeletonLoader } from '../../loading-state/loaders/skeleton-loader/skeleton-loader';
import { useAppActions, useAppState } from '../../../../overmind';
import { OrganizationLevelType } from '../../../../enums';
import { EmptyState } from '../../empty-state/empty-state';
import { themedAssetUrl } from '../../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { SkillScoresRequest, SkillScoresResponse } from '../../../../models/overmind/analytics';
import { ResponseGroup } from '../../../../enums/analytics';
import { SkillScoresSearchParams } from '../../../../models/overmind/search-params';
import styles from '../average.module.css';
import { PagePath } from '../../../../navigation/navigation.enums';
import { IEntityAverageWidgetProps } from '../average.models';
import { Average } from '../average';
import { Pager } from '@keplerco/core';

export function EntityAverageWidget({ entity, path }: IEntityAverageWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { user, dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [companySkillScore, setCompanySkillScore] = useState<SkillScoresResponse | undefined>();
  const [entitySkillScores, setEntitySkillScores] = useState<SkillScoresResponse[] | undefined>();
  const [pagedEntitySkillScores, setPagedEntitySkillScores] = useState<SkillScoresResponse[]>([]);

  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);

  useEffect(() => {
    async function getCompanySkillScore(): Promise<SkillScoresResponse | undefined> {
      if (path !== PagePath.analyticsOverview) return undefined;
      if (entity?.organizationLevel !== OrganizationLevelType.Company && !user?.isSystemAdmin) return undefined;

      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Overall,
        searchParams: {
          startDate: dateRange?.from?.toJSON(),
          endDate: dateRange?.to?.toJSON(),
          organizationLevel: OrganizationLevelType.Company,
          companySlug: companyVariables.slug,
          departmentSlug: undefined,
          teamSlug: undefined,
          learnerSlug: undefined,
          skillSlug: undefined,
          skillSubTypeSlug: undefined,
          includeLearnerCount: false,
          scoreComparison: companyVariables.scoreComparison,
        },
      };
      const response = await actions.analyticsGetSkillScores(request);

      return !!response ? response[0] : undefined;
    }
    async function getEntitySkillScores(): Promise<SkillScoresResponse[] | undefined> {
      if (!entity) return;
      const searchParams: SkillScoresSearchParams = {
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel === OrganizationLevelType.Company ? OrganizationLevelType.Department : entity.organizationLevel === OrganizationLevelType.Department ? OrganizationLevelType.Team : entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: companyVariables.scoreComparison,
      };
      if (entity.organizationLevel === OrganizationLevelType.Department) searchParams.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) searchParams.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) searchParams.learnerSlug = entity.entity.slug;
      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Overall,
        searchParams,
      };
      const response = await actions.analyticsGetSkillScores(request);


      return response;
    }

    async function getData() {
      if (!entity) return;

      setLoading(true);

      const tempCompanySkillScore = await getCompanySkillScore();
      const tempEntitySkillScores = await getEntitySkillScores();

      setCompanySkillScore(tempCompanySkillScore);
      setEntitySkillScores(tempEntitySkillScores);
      setActivePageNumber(1);
      const tempPageSize = !!tempCompanySkillScore ? 2 : 3;

      if (!!tempEntitySkillScores && tempEntitySkillScores.length > tempPageSize) {
        setPageSize(tempPageSize);
        setPagedEntitySkillScores(tempEntitySkillScores.slice(0, tempPageSize));
        setPageCount(Math.ceil(tempEntitySkillScores.length / tempPageSize));
      } else {
        setPageSize(0);
        setPagedEntitySkillScores(tempEntitySkillScores ?? []);
        setPageCount(0);
      }

      setLoading(false);
    }

    getData();
  }, [entity]);

  function onPageChangeHandler(pageNumber: number) {
    const end = pageNumber * pageSize;
    const start = end - pageSize;
    const tempPagedEntitySkillScores = entitySkillScores?.slice(start, end);
    setPagedEntitySkillScores(tempPagedEntitySkillScores ?? []);
    setActivePageNumber(pageNumber);
  }

  if (loading) return <SkeletonLoader height="350px" width="450px" />;

  return (
    <div className={classNames('card', [styles.card])}>
      {!companySkillScore && pagedEntitySkillScores.length === 0 ? (
        <EmptyState title="No averages found" badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} />
      ) : (
        <React.Fragment>
          {!!companySkillScore && (
            <React.Fragment>
              <Average title="Company average" score={companySkillScore.score} path={path} />

              {!!entitySkillScores?.length && <div className={styles.divider} />}
            </React.Fragment>
          )}

          {pagedEntitySkillScores.map(skillScore => (
            <Average key={skillScore.slug} title={skillScore.name} score={skillScore.score} path={path} />
          ))}

          {pagedEntitySkillScores.length < pageSize && (
            <React.Fragment>
              <Average title="Heading" score={undefined} path={path} invisible />

              {pageSize - pagedEntitySkillScores.length > 1 && <Average title="Heading" score={undefined} path={path} invisible />}
            </React.Fragment>
          )}

          {pageCount > 1 && (
            <div className={styles.pagerContainer}>
              <Pager activePageNumber={activePageNumber} pageCount={pageCount} onPageChange={onPageChangeHandler} />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
