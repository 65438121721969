import React from 'react';

export function BelowExpectationsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
      <g clipPath="url(#clip0_5328_49945)">
        <g opacity="0.4">
          <path d="M29.982 58.6784C46.5406 58.6784 59.964 55.3453 59.964 51.2336C59.964 47.122 46.5406 43.7888 29.982 43.7888C13.4234 43.7888 0 47.122 0 51.2336C0 55.3453 13.4234 58.6784 29.982 58.6784Z" fill="url(#paint0_radial_5328_49945)" style={{ mixBlendMode: 'multiply' }} />
        </g>
        <path d="M42.2052 39.5031C48.6545 33.0539 48.6545 22.5975 42.2052 16.1482C35.7559 9.69896 25.2996 9.69896 18.8503 16.1482C12.401 22.5975 12.401 33.0539 18.8503 39.5031C25.2996 45.9524 35.7559 45.9524 42.2052 39.5031Z" fill="url(#paint1_radial_5328_49945)" />
        <path
          style={{ mixBlendMode: 'color-burn' }}
          d="M42.2052 39.5031C48.6545 33.0539 48.6545 22.5975 42.2052 16.1482C35.7559 9.69896 25.2996 9.69896 18.8503 16.1482C12.401 22.5975 12.401 33.0539 18.8503 39.5031C25.2996 45.9524 35.7559 45.9524 42.2052 39.5031Z"
          fill="url(#paint2_radial_5328_49945)"
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M42.2052 39.5031C48.6545 33.0539 48.6545 22.5975 42.2052 16.1482C35.7559 9.69896 25.2996 9.69896 18.8503 16.1482C12.401 22.5975 12.401 33.0539 18.8503 39.5031C25.2996 45.9524 35.7559 45.9524 42.2052 39.5031Z"
          fill="url(#paint3_radial_5328_49945)"
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M42.2052 39.5031C48.6545 33.0539 48.6545 22.5975 42.2052 16.1482C35.7559 9.69896 25.2996 9.69896 18.8503 16.1482C12.401 22.5975 12.401 33.0539 18.8503 39.5031C25.2996 45.9524 35.7559 45.9524 42.2052 39.5031Z"
          fill="url(#paint4_radial_5328_49945)"
        />
        <path
          style={{ mixBlendMode: 'screen' }}
          d="M42.2052 39.5031C48.6545 33.0539 48.6545 22.5975 42.2052 16.1482C35.7559 9.69896 25.2996 9.69896 18.8503 16.1482C12.401 22.5975 12.401 33.0539 18.8503 39.5031C25.2996 45.9524 35.7559 45.9524 42.2052 39.5031Z"
          fill="url(#paint5_radial_5328_49945)"
        />
        <ellipse cx="30.4561" cy="19.1471" rx="10" ry="6.17647" fill="url(#paint6_linear_5328_49945)" />
        <g filter="url(#filter0_d_5328_49945)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.363 30.4749L32.363 19.5839C32.363 18.6093 31.5729 17.8192 30.5983 17.8192C29.6236 17.8192 28.8336 18.6093 28.8336 19.5839L28.8336 30.475L26.1064 27.7478C25.4173 27.0587 24.2999 27.0587 23.6108 27.7478C22.9216 28.437 22.9216 29.5543 23.6108 30.2435L29.3504 35.9831C30.0395 36.6723 31.1569 36.6723 31.846 35.9831L37.5857 30.2435C38.2748 29.5543 38.2748 28.437 37.5857 27.7478C36.8965 27.0587 35.7792 27.0587 35.09 27.7478L32.363 30.4749Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_5328_49945" x="23.0938" y="17.8192" width="15.0088" height="18.9308" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.533333 0 0 0 0 0.109804 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5328_49945" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5328_49945" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_5328_49945" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29.8713 51.3581) rotate(-0.569723) scale(12.5211 3.16623)">
          <stop stopColor="#FF891D" />
          <stop offset="1" stopColor="#FFB320" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint1_radial_5328_49945" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(32.8279 24.6583) scale(20.3254 20.3254)">
          <stop stopColor="#FFE0C4" />
          <stop offset="0.23" stopColor="#FFCA99" />
          <stop offset="0.33" stopColor="#FFBF90" />
          <stop offset="0.48" stopColor="#FFB179" />
          <stop offset="0.67" stopColor="#FFA653" />
          <stop offset="0.89" stopColor="#FF8A1E" />
          <stop offset="1" stopColor="#FF7A00" />
        </radialGradient>
        <radialGradient id="paint2_radial_5328_49945" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.9478 35.6671) scale(23.3807 23.3807)">
          <stop stopColor="#ADAEB0" />
          <stop offset="0.57" stopColor="#E7E8E8" />
          <stop offset="0.78" stopColor="white" />
        </radialGradient>
        <radialGradient id="paint3_radial_5328_49945" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.6922 20.3343) rotate(144.181) scale(23.4472 23.4472)">
          <stop />
          <stop offset="0.44" />
          <stop offset="0.51" />
          <stop offset="0.61" stopColor="#030303" />
          <stop offset="0.69" stopColor="#0C0C0C" />
          <stop offset="0.76" stopColor="#1C1C1C" />
          <stop offset="0.82" stopColor="#323232" />
          <stop offset="0.87" stopColor="#4E4E4E" />
          <stop offset="0.93" stopColor="#707070" />
          <stop offset="0.96" stopColor="#898989" />
          <stop offset="0.97" stopColor="#8D8D8E" />
          <stop offset="0.98" stopColor="#9A9B9B" />
          <stop offset="0.99" stopColor="#AFB0B2" />
          <stop offset="1" stopColor="#BBBDBF" />
        </radialGradient>
        <radialGradient id="paint4_radial_5328_49945" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.7776 35.5045) rotate(-100.993) scale(28.1708 28.1708)">
          <stop />
          <stop offset="0.65" />
          <stop offset="0.76" />
          <stop offset="0.82" stopColor="#060606" />
          <stop offset="0.9" stopColor="#161616" />
          <stop offset="0.98" stopColor="#303030" />
          <stop offset="1" stopColor="#363636" />
        </radialGradient>
        <radialGradient id="paint5_radial_5328_49945" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.3501 33.0067) rotate(8.93059) scale(23.4298 23.4297)">
          <stop />
          <stop offset="0.54" />
          <stop offset="0.62" />
          <stop offset="0.81" stopColor="#020202" />
          <stop offset="0.87" stopColor="#090909" />
          <stop offset="0.92" stopColor="#141414" />
          <stop offset="0.96" stopColor="#252525" />
          <stop offset="0.99" stopColor="#3A3A3B" />
          <stop offset="1" stopColor="#404041" />
        </radialGradient>
        <linearGradient id="paint6_linear_5328_49945" x1="30.4561" y1="12.9706" x2="30.4561" y2="25.3235" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.74" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5328_49945">
          <rect width="60" height="60" fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
}
