/* eslint-disable @typescript-eslint/no-loss-of-precision */
import { Context } from '../..';
import { NotificationsRequest, INotification } from '../../../models/overmind/notifications';
import { Notification, NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import * as base from '../base';

export async function getNotifications(context: Context, payload: NotificationsRequest): Promise<INotification[] | undefined> {
  if (!!context.state.skillAssessmentConfig) return;

  const request: base.IRequest = { url: base.url(`notification`, `get-notifications${base.params(payload)}`) };
  const response: base.IResponse<INotification[]> = await base.request(request);
  response.data
    ?.filter(notification => notification.keplerPointType !== undefined && !context.state.notifications.find(item => item.id === notification.id))
    .forEach(notification => {
      addNotification(context, {
        ...notification,
        priority: notification.notificationPriority,
        type: notification.notificationType,
      });
    });
  return response.data;
}

export async function dismissNotificationApi(_: Context, notificationId: string): Promise<void> {
  const request: base.IRequest = { url: base.url(`notification`, `dismiss-notification`, notificationId), authenticated: true, method: 'POST' };
  base.request(request);
}

export function addNotification({ state }: Context, queueItem: Notification): void {
  state.notifications = [queueItem, ...state.notifications];
}

export async function dismissNotification(context: Context, id: string): Promise<void> {
  context.state.notifications = [
    ...context.state.notifications.map(t => {
      const value = t.id === id.toString() ? false : t.active;

      return {
        ...t,
        active: value,
      };
    }),
  ];

  await dismissNotificationApi(context, id);
}

export function dismissAllNotifications(context: Context): void {
  context.state.notifications.forEach(item => {
    if (item.active) dismissNotification(context, item.id);
  });
}

export function dismissAllNotificationsOfPriority(context: Context, priority: NotificationPriority): void {
  context.state.notifications.forEach(item => {
    if (priority === NotificationPriority.Tile && typeof item.priority === 'undefined') {
      item.priority = NotificationPriority.Tile;
    }

    if (item.active && item.priority === priority) {
      dismissNotification(context, item.id);
    }
  });
}

export function dismissAllNotificationsOfType(context: Context, type: NotificationType): void {
  context.state.notifications.forEach(item => {
    if (item.active && item.type === type) {
      dismissNotification(context, item.id);
    }
  });
}
