import { DropdownItem } from '@keplerco/core';
import { UniqueEntity } from '../../models';

export function generateDropdownItemsFromUniqueEntities(entities: UniqueEntity[] | undefined, onClick?: (value: string) => void): DropdownItem[] {
  const items: DropdownItem[] = [];

  entities?.forEach(entity => {
    if (!items.some(option => option.value === entity.slug)) {
      const item: DropdownItem = { label: entity.name, value: entity.slug! };
      if (!!onClick) item.onClick = () => onClick(entity.slug!);
      items.push(item);
    }
  });

  return items;
}
