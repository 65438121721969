import React from 'react';
import { TagType } from '../../../../enums/tag';
import { TagAssigneeResponse } from '../../../../models/tag';
import { IArchiveTagModalProps } from './archive-tag-modal.models';
import { tagTypeToString } from '../tag-management.cms.helpers';
import { Button, ListItemLayout, ListLayout } from '@keplerco/core';

export default function TagArchiveModal({ tagName, assignees, onArchive }: IArchiveTagModalProps) {
  function generateTagTypeSummaries(assignees: TagAssigneeResponse[]) {
    const counts = {
      [TagType.Department]: 0,
      [TagType.User]: 0,
      [TagType.Team]: 0,
      [TagType.Assessment]: 0,
      [TagType.Lesson]: 0,
    };

    assignees.forEach(assignee => {
      counts[assignee.tagType] += 1;
    });

    return Object.entries(counts)
      .filter(([, count]) => count > 0)
      .map(([tagType, count]) => `${count} ${tagTypeToString(parseInt(tagType))}`);
  }

  return (
    <div className="modalContent" style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
      <h2 className="heading2">Are you sure you want to archive "{tagName}"?</h2>

      <div>
        <h6 className="heading5" style={{ marginBottom: 10 }}>
          This tag is assigned to:
        </h6>

        <ListLayout>
          {generateTagTypeSummaries(assignees).map((tagTypeSummary, index) => (
            <ListItemLayout key={index}>
              <div className="card">
                <span className="caption1">{tagTypeSummary}</span>
              </div>
            </ListItemLayout>
          ))}
        </ListLayout>
      </div>

      <Button type="button" filled onClick={onArchive}>
        Yes, archive
      </Button>
    </div>
  );
}
