import React, { useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { ISkillLayoutProps } from './skill.models';
import { PageHeader } from '@keplerco/core';
import { SkillOverviewWidget } from '../../../widgets/analytics/overview/skill-overview.widget';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';

export function SkillLayout({ skillSlug, skillSubTypeSlug }: ISkillLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [skillName, setSkillName] = useState<string>();

  return (
    <div className="pageWrapper wrapper">
      <PageHeader
        breadcrumbs={[
          {
            name: 'Analytics',
            url: PagePath.analyticsBase,
          },
          {
            name: 'Skills',
            url: `${PagePath.analyticsBase}${PagePath.analyticsSkills.replace(':companySlug', companyVariables.slug!)}`,
          },
          {
            name: `${skillName ?? 'Skill'}`,
            url: window.location.href,
          },
        ]}
        title={skillName ?? 'Skill'}
        type="actions"
      >
        <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

          <Daterange
            defaultValue={dateRangeOutput}
            onDaterangeChange={result => {
              actions.setGlobalDateRange(result);
            }}
          />
        </div>
      </PageHeader>

      <br />

      <SkillOverviewWidget entity={entity} skillSlug={skillSlug} skillSubTypeSlug={skillSubTypeSlug} setSkillName={setSkillName} />
    </div>
  );
}

