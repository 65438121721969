import React, { useState, PropsWithChildren, useRef, useEffect } from 'react';
import { Loader } from '../../loaders/loader/loader';
import { FetchStatus } from '../../../../../enums';
import { KeplerState } from '../../../../../models/kepler-state';
import { useAppState } from '../../../../../overmind';
import { AccountPageLoaderLayerLoader, AccountPageLoaderLayerChildren } from './account-page-loader-layer.styles';
import { PagePath } from '../../../../../navigation/navigation.enums';

export function AccountPageLoaderLayer(props: PropsWithChildren<{ path: PagePath.accountActivate | PagePath.accountVerifyEmail | PagePath.accountForgotPassword | PagePath.accountLogin | PagePath.accountRegister | PagePath.accountResetPassword | PagePath.accountSetPassword }>): JSX.Element {
  const childrenRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const { fetchState } = useAppState<KeplerState>();

  const [status, setStatus] = useState<FetchStatus>(FetchStatus.Inactive);

  const time = useRef<NodeJS.Timeout | undefined>(undefined);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const init = useRef<NodeJS.Timeout | undefined>(undefined);

  function showLoader(content: HTMLDivElement, loader: HTMLDivElement) {
    loader.classList.add('isLoading');
    content.classList.add('isLoading');
    content.style.height = content.getBoundingClientRect().height + 'px';
  }

  function hideLoader(content: HTMLDivElement, loader: HTMLDivElement) {
    content.classList.remove('isLoading');
    content.style.height = '';
    loader.classList.remove('isLoading');
  }

  // If the initial status is inactive, hide the loader after a certain amount of time.
  // Because the "isLoading" class is added by default, without this an infinite loader would occur if no action is triggered by the page parent to stop the loader.
  useEffect(() => {
    if (status === FetchStatus.Inactive)
      init.current = setTimeout(() => {
        const children = childrenRef.current;
        const loader = loaderRef.current;

        if (!!parent && !!children && !!loader) hideLoader(children, loader);
      }, 1000);
  }, []);

  // If an init timeout exists and the status changes because and action was triggered by the parent component to start the loader, cancel hiding the loader.
  useEffect(() => {
    if (!!init.current && status === FetchStatus.Active) clearTimeout(init.current);
  }, [status]);

  useEffect(() => {
    if ((status === undefined || status === FetchStatus.Inactive) && fetchState[props.path].status === FetchStatus.Active) {
      time.current = setTimeout(() => {
        time.current = undefined;
        setForceUpdate(latest => latest + 1);
      }, 1000);
      setForceUpdate(latest => latest + 1);

      const content = childrenRef.current;
      const loader = loaderRef.current;

      if (!!content && !!loader) showLoader(content, loader);
    }

    setStatus(fetchState[props.path].status);
  }, [fetchState[props.path]]);

  useEffect(() => {
    if (status === FetchStatus.Inactive && !time.current) {
      const content = childrenRef.current;
      const loader = loaderRef.current;

      if (!!content && !!loader) hideLoader(content, loader);
    }
  }, [status, time, forceUpdate]);

  return (
    <React.Fragment>
      <AccountPageLoaderLayerChildren ref={childrenRef} className="isLoading">
        {props.children}
      </AccountPageLoaderLayerChildren>

      <AccountPageLoaderLayerLoader ref={loaderRef} className="isLoading">
        <Loader />
      </AccountPageLoaderLayerLoader>
    </React.Fragment>
  );
}
