import { colourString, ThemeColours } from '@keplerco/core';

export function generateTicks(min: number, max: number): number[] {
  const step = max === 100 ? 10 : 1;
  return Array.from({ length: Math.floor((max - min) / step) + 1 }, (_, i) => min + i * step);
}

export function fillColour(index: number, fill?: ThemeColours | ThemeColours[]): string {
  if (!!fill) {
    return Array.isArray(fill) ? colourString(fill[index]) : colourString(fill);
  }

  const graphColourNumber = index > 13 ? index - 13 : index + 1;
  return colourString(`grc_${graphColourNumber}`);
}
