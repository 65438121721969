import { Context } from '../..';
import { DaterangeOutput } from '../../../components/inputs/daterange/daterange.models';

export interface GlobalDateRangeFilter {
  from?: Date | null;
  to?: Date | null;
  hash?: string;
}

export function setGlobalDateRange(context: Context, value: DaterangeOutput): void {
  context.state.dateRangeOutput = value;
}
