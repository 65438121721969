import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function CreateTagFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow style={{ marginBottom: 35 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" width="250px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 10 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="15px" width="250px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 10 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="15px" width="250px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 10 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="15px" width="250px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <footer className="panelFooter">
        <SkeletonLoader height="25px" width="60px" />
        <SkeletonLoader height="50px" width="150px" />
      </footer>
    </div>
  );
}

