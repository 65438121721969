import React, { Fragment } from 'react';
import { LottieAsset } from '../../../../design/lottie/lottie-asset/lottie-asset';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import fireworkConfettiLottie from '../../../../design/lottie/firework_confetti.lottie.json';
import { skillScoreRounding } from '../../../../library/helpers/skill-score-rounding';
import { useNavigate } from 'react-router-dom';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Button, colourString, scorePercentageRAGColour } from '@keplerco/core';

export function SkillScorePostAssessmentUpdatedModalLayout(props: INotificationLayoutProps): JSX.Element {
  const skillScorePercentage = props.amount ?? 0;

  return (
    <Fragment>
      <div className="dialogBackground">
        <LottieAsset loop autoplay data={fireworkConfettiLottie} />
      </div>

      <div className="modalContent">
        <div className="badgeIconContainer">
          <object data={'/badges/badge_JourneyComplete_Comet.svg'} type="image/svg+xml">
            <img src={'/badges/badge_JourneyComplete_Comet.svg'} alt="KP" />
          </object>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center', textAlign: 'center' }}>
          <h2 className="heading2">Stellar performance!</h2>

          <div>
            <h6 className="subtitle">
              You've updated your skill score for <strong style={{ color: 'var(--text)' }}>{props.title}</strong>.
            </h6>

            <h2 className="heading2" style={{ color: colourString(scorePercentageRAGColour(skillScorePercentage)) }}>
              {skillScoreRounding(skillScorePercentage)}%
            </h2>
          </div>

          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            Got It, thanks!
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export function SkillScorePreAssessmentUpdatedModalLayout(props: INotificationLayoutProps): JSX.Element {
  const skillScorePercentage = props.amount ?? 0;
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="dialogBackground">
        <LottieAsset loop autoplay data={fireworkConfettiLottie} />
      </div>

      <div className="modalContent" style={{ textAlign: 'center' }}>
        <div className="badgeIconContainer">
          <object data={'/badges/badge_JourneyComplete_Comet.svg'} type="image/svg+xml">
            <img src={'/badges/badge_JourneyComplete_Comet.svg'} alt="KP" />
          </object>
        </div>

        <h2 className="heading2" style={{ marginBottom: 30 }}>
          Stellar performance!
        </h2>

        <h6 className="subtitle">
          Your Skill Score for <strong style={{ color: 'var(--text)' }}>{props.title}</strong> has been updated
        </h6>

        <h2 className="heading2" style={{ color: colourString(scorePercentageRAGColour(skillScorePercentage)) }}>
          {skillScoreRounding(skillScorePercentage)}%
        </h2>

        <footer style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            CONTINUE TO THE COURSE
          </Button>

          <Anchor
            onClick={() => {
              navigate(PagePath.learningJourneyBase);
              props.onAction();
            }}
          >
            GO BACK TO MY CORE SKILLS MAP
          </Anchor>
        </footer>
      </div>
    </Fragment>
  );
}
