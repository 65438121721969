import { OrganizationLevelType } from '../../../enums';
import { OrganizationLevel } from '../../../models/overmind/permissions';

export function extractHighestOrganizationLevel(organizationLevels: OrganizationLevel[] | undefined): OrganizationLevel | undefined {
  if (!organizationLevels) return undefined;

  let organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Company);
  if (!!organizationLevel) return organizationLevel;

  organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Department);
  if (!!organizationLevel) return organizationLevel;

  organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Team);
  if (!!organizationLevel) return organizationLevel;

  organizationLevel = organizationLevels.find(tempOrganizationLevel => tempOrganizationLevel.organizationLevel === OrganizationLevelType.Learner);
  if (!!organizationLevel) return organizationLevel;

  return undefined;
}
