import { Context } from '../..';
import { ExternalLoginModel } from '../../../models/external-login-model';
import { ForgotPassword } from '../../../models/view/forgot-password';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { handleErrorToErrorPage } from '../functions';
import { LoginInput } from '../../../models/view/login-input';
import { ResetPassword } from '../../../models/view/reset-password';
import { AuthenticationStatus, ErrorSignalTypes } from '../../../enums';
import { LoginResult, User, UserEmail, UserLoginResponse, UserRegistration } from '../../../models/user';
import * as base from '../base';
import { track } from '../../../library/helpers/segment';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import { Mode, PagePath } from '../../../navigation/navigation.enums';
import { DeveloperConsole, deleteCookie } from '@keplerco/core';
import { Profile } from '../../../models/profile';

const CONTROLLER = `user`;
const dconsole = new DeveloperConsole('User', ['#E5145F', '#ffffff']);

// auth
export async function forgotPassword(context: Context, forgotPasswordModel: ForgotPassword): Promise<boolean> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `forgot-password`), authenticated: true, method: 'POST', body: JSON.stringify(forgotPasswordModel) };
  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);
    return false;
  }

  return true;
}

export async function logout(): Promise<void> {
  deleteCookie('kepler.cookie');

  const request: base.IRequest = { url: base.url(CONTROLLER, `logout`), authenticated: true, method: 'POST' };
  await base.request(request);

  window.sessionStorage.clear();

  window.location.href = '/account/login';
  window.location.reload();
}

// TODO
// Pass the user's credentials to the api to verify and authenticate them and the return and set a user token as a cookie. Set's the user's authentication status based on the validity of their credentials
// async function verifyUserCredentials(context: Context, loginInputModel: LoginInput): Promise<void> {
//   context.state.authenticationStatus = AuthenticationStatus.UnAuthenticated;

//   const request: base.IRequest = { url: base.url(CONTROLLER, `login`), authenticated: true, method: 'POST', body: JSON.stringify(loginInputModel) };
//   const response: base.IResponse<any> = await base.request(request);

//   context.state.authenticationStatus = response.error ? AuthenticationStatus.AuthenticationFailed : AuthenticationStatus.Authenticated;
// }

export function getLoginResult(context: Context): LoginResult {
  if (!!context.state.user)
    track('KPLR-USER-LOGIN', {
      userId: context.state.user.learnerSlug,
      companyId: context.state.user.companySlug,
      status: context.state.user.completedOnboarding ? 'ONBOARDING COMPLETE' : 'ONBOARDING INCOMPLETE',
    });

  return {
    authenticationStatus: context.state.authenticationStatus,
    hasCompletedOnboarding: context.state.user?.completedOnboarding ?? false,
    isSystemAdmin: context.state.user?.isSystemAdmin ?? false,
  };
}

export async function externalLogin(_: Context, externalLoginModel: ExternalLoginModel): Promise<void> {
  const form = document.createElement('form');

  form.setAttribute('method', 'POST');
  form.setAttribute('action', base.url(CONTROLLER, `external-login?provider=${externalLoginModel.provider}&kt=${externalLoginModel.kt}&code=${externalLoginModel.code}&returnUrl=${externalLoginModel.returnUrl}`));

  document.body.appendChild(form);
  form.submit();
}

// TODO
// export const login = pipe(verifyUserCredentials, initialiseAuthenticatedState, syncCourseContent, getLoginResult);

function mapErrorMessage(message: string) {
  if (message === 'Invalid token.') {
    return `The email address you have entered does not match the address we sent the activation email to`;
  }

  if (message === 'Please check your email for typos') {
    return `You seem to have entered your email address incorrectly. Please enter the address that your activation email was sent to`;
  }

  return message;
}

export async function setPassword(context: Context, resetPasswordModel: ResetPassword): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `reset-password`), authenticated: true, method: 'POST', body: JSON.stringify(resetPasswordModel) };
  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      id: Date.now().toString(),
      title: response.error.title,
      message: mapErrorMessage(response.error.detail ?? ''),
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      active: true,
    });

    return;
  }

  if (response.data.errors !== undefined && response.data.errors.length > 0) {
    context.state.passwordErrors = response.data.errors;
  } else {
    context.state.authenticationStatus = AuthenticationStatus.Authenticated;
  }
}

// TODO
// export const setPasswordAndSync = pipe(
//   ({ state }: Context, resetPasswordModel: ResetPassword) => {
//     state.authenticationStatus = AuthenticationStatus.UnAuthenticated;
//     return resetPasswordModel;
//   },
//   setPassword,
//   initialiseAuthenticatedState,
//   syncCourseContent,
//   getLoginResult
// );

export async function requestAccountActivation(context: Context, forgotPasswordModel: ForgotPassword): Promise<boolean> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `request-activation`), authenticated: true, method: 'POST', body: JSON.stringify(forgotPasswordModel) };
  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    dconsole.error({ title: 'Whoops, Something Went Wrong', description: 'There was a error activating your account, please try again later.' });
    return false;
  }

  return true;
}

export async function registerUser(context: Context, registration: UserRegistration): Promise<{ message: string } | undefined> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `register`, getSubdomain()), authenticated: true, method: 'post', body: JSON.stringify(registration) };
  const response: base.IResponse<{ message: string }> = await base.request(request);

  console.log(response.error?.type);

  if (response.error?.type.includes('The provided email is already in use.')) {
    context.actions.addNotification({
      id: crypto.randomUUID(),
      toastHTML: {
        __html: `Having trouble signing in? If you've already activated your account, <a href="${window.location.origin}${PagePath.accountBase}${PagePath.accountLogin}">sign in</a>. Or you can <a href="${window.location.origin}${PagePath.accountBase}${PagePath.accountForgotPassword}">reset your password</a>.`,
      },
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      active: true,
    });
  }

  if (response.error?.type.includes('Passwords')) {
    const items = response.error.type.split('.').map(i => i.trim());
    context.state.passwordErrors = items.filter(i => !!i);
  }

  return response.data;
}

export function clearPasswordErrors(context: Context) {
  context.state.passwordErrors = void 0;
}

export async function sendActivationReminders(context: Context, requestModel: { companySlug: string; emails: string[] }) {
  const request: base.IRequest = { url: base.url(CONTROLLER, `send-activation-reminders`, requestModel.companySlug), authenticated: true, method: 'POST', body: JSON.stringify(requestModel.emails) };

  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'Error Sending Activation Reminder(s)',
      message: `There was an issue sending the reminders. Please try again.`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: `activation_reminders_error_${Date.now()}`,
      active: true,
    });
    return;
  }

  context.actions.addNotification({
    title: 'Activation Reminder(s) Sending',
    message: `This may take a while, feel free to keep working while we finish up.`,
    priority: NotificationPriority.Toast,
    type: NotificationType.Success,
    id: `activation_reminders_sending_${Date.now()}`,
    active: true,
  });
}

export async function sendActivationInvitations(context: Context, requestModel: { companySlug: string; emails: string[] }) {
  const request: base.IRequest = {
    url: base.url('user', 'send-activation-invitations', requestModel.companySlug),
    authenticated: true,
    method: 'POST',
    body: JSON.stringify(requestModel.emails),
  };

  const response: base.IResponse<any> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'Error Sending Activation Invitation(s)',
      message: `There was an issue sending the invitations. Please try again.`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: `activation_invitations_error_${Date.now()}`,
      active: true,
    });
    return;
  }

  context.actions.addNotification({
    title: 'Activation Invitation(s) Sending',
    message: `This may take a while, feel free to keep working while we finish up.`,
    priority: NotificationPriority.Toast,
    type: NotificationType.Success,
    id: `activation_invitations_sending_${Date.now()}`,
    active: true,
  });
}

export async function getUser(context: Context, logout: boolean = false): Promise<void> {
  const request: base.IRequest = { url: base.url('user', 'profile'), authenticated: true };
  const response: base.IResponse<User> = await base.request(request);

  if (response.error) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);
    return;
  }

  if (!!response.data) {
    context.state.user = response.data;
    context.state.authenticationStatus = AuthenticationStatus.Authenticated;

    const modeString = window.localStorage.getItem(`${response.data.learnerSlug}-mode`);
    if (!!modeString) {
      context.state.mode = modeString as Mode;
      if (modeString === Mode.PlatformManagement && window.location.pathname.includes(PagePath.companies)) context.state.sideNavVisible = false;
    }

    context.actions.getActiveCompanySlug(response.data);

    return;
  }

  if ((response.data as any)?.message === 'You are not logged in.') {
    context.state.authenticationStatus = logout ? AuthenticationStatus.UnAuthenticated : AuthenticationStatus.AuthenticationFailed;
    return;
  }

  context.state.authenticationStatus = AuthenticationStatus.UnAuthenticated;
}

export async function getProfile(context: Context): Promise<Profile | undefined> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `settings-profile`), authenticated: true };
  const response: base.IResponse<Profile> = await base.request(request);

  if (response.error) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.Api}/${response.error.status ?? 500}`, window.location.pathname);
    return;
  }

  return response.data;
}

export async function saveProfile(context: Context, payload: Profile): Promise<Profile | undefined> {
  const request: base.IRequest = { url: base.url('user', 'settings-profile'), method: 'POST', body: JSON.stringify(payload) };
  const response = await base.request(request);
  // TODO: refactor User and Profile so this isn't needed
  await getUser(context);
  return response.data;
}

export async function removeEmail(context: Context, userEmail: UserEmail): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `remove-email`), authenticated: true, method: 'POST', body: JSON.stringify(userEmail) };
  await base.request(request);
}

export async function changePrimaryEmail(context: Context, email: string): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `change-primary-email`), authenticated: true, method: 'POST', body: JSON.stringify(email) };
  await base.request(request);
}

export async function resendVerification(context: Context, email: string): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `resend-verification`), authenticated: true, method: 'POST', body: JSON.stringify(email) };
  await base.request(request);
}

// refactored
export async function authenticate({ state }: Context): Promise<void> {
  state.isInitialising = true;
  state.authenticationStatus = AuthenticationStatus.Authenticating;

  const request: base.IRequest = { url: base.url(CONTROLLER, `isauthenticated`) };
  const response: base.IResponse<any> = await base.request(request);

  state.authenticationStatus = response.data ? AuthenticationStatus.Authenticated : AuthenticationStatus.UnAuthenticated;
  state.isInitialising = response.data;
}

export async function login({ state }: Context, payload: LoginInput): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `login`), method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<UserLoginResponse> = await base.request(request);

  state.authenticationStatus = response.error ? AuthenticationStatus.AuthenticationFailed : AuthenticationStatus.Authenticated;

  // TODO: we have identify() so do we really need this?
  if (!!state.user) {
    track('KPLR-USER-LOGIN', {
      userId: state.user.learnerSlug,
      companyId: state.user.companySlug,
      status: state.user.completedOnboarding ? 'ONBOARDING COMPLETE' : 'ONBOARDING INCOMPLETE',
    });
  }
}

export async function setPasswordAndLogin({ state, actions }: Context, payload: ResetPassword): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `reset-password`), method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<any> = await base.request(request); // TODO: replace any with correct type

  if (response.error) {
    actions.addNotification({
      id: crypto.randomUUID(),
      title: response.error.title,
      message: mapErrorMessage(response.error.detail ?? ''),
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      active: true,
    });
    return;
  }

  if (!!response.data?.errors?.length) {
    state.passwordErrors = response.data.errors;
    return;
  }

  state.authenticationStatus = AuthenticationStatus.Authenticated;

  // TODO: we have identify() so do we really need this?
  if (!!state.user) {
    track('KPLR-USER-LOGIN', {
      userId: state.user.learnerSlug,
      companyId: state.user.companySlug,
      status: state.user.completedOnboarding ? 'ONBOARDING COMPLETE' : 'ONBOARDING INCOMPLETE',
    });
  }
}

export async function logDailyLogin(): Promise<void> {
  const request: base.IRequest = { url: base.url(CONTROLLER, `log-daily-login`) };
  await base.request(request);
}
