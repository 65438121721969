import { DropdownSelectItem } from '@keplerco/core';
import { AssessmentType } from '../../../enums/assessment-type';
import { CompletionStatus } from '../../../enums';

export const assessmentTypeItems: DropdownSelectItem[] = [
  { label: 'Peer Endorsement', value: AssessmentType.PeerEndorsement.toString() },
  { label: 'Questionnaire', value: AssessmentType.Questionnaire.toString() },
  { label: 'Fields Of Practice', value: AssessmentType.FieldsOfPractice.toString() },
];

export function getBorderColorByCompletionStatus(status: CompletionStatus) {
  switch (status) {
    case CompletionStatus.Completed:
      return 'green';
    case CompletionStatus.InProgress:
      return 'orange';
    case CompletionStatus.NotStarted:
      return 'red';
    default:
      return 'gray';
  }
}

export function formatAssessmentTypeLabel(label: string): string {
  return label.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}
