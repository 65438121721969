import React, { Fragment, useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { Anchor, CheckboxCard, Chip, ChipWrapper, ListItemLayout, ListLayout, search, SearchBar } from '@keplerco/core';
import classNames from 'classnames';
import { AssessmentsWizardDetails } from './assessments-wizard-peer.page';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { CareerPathSkillSet } from '../../../models';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';

interface AssessmentsWizardFieldOfPractice {
  careerPathSlug: string;
  careerPathName: string;
  checked: boolean;

  jobsIds: number[];
  skillSets: CareerPathSkillSet[];
}

export interface AssessmentsWizardFieldsOfPractice extends AssessmentsWizardDetails {
  careerPathSlugs: string[];
}

enum ErrorMessage {
  NoFieldsOfPractice = 'At least one field of practice must be selected',
}

export function AssessmentsWizardFOPWidget({ details, onSaveAndContinue }: { details: AssessmentsWizardDetails | undefined, onSaveAndContinue: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [fieldsOfPractice, setFieldsOfPractice] = useState<AssessmentsWizardFieldOfPractice[]>([]);
  const [searchedFieldsOfPractice, setSearchedFieldsOfPractice] = useState<AssessmentsWizardFieldOfPractice[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  useEffect(() => {
    async function getData() {
      if (!details?.slug) return;

      setLoading(true);

      const assessment = await actions.getSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: details.slug });
      const careerPaths = await actions.getCompanyCareerPaths(companyVariables.slug!) ?? [];

      const nextFieldsOfPractice: AssessmentsWizardFieldOfPractice[] = careerPaths.map(careerPath => ({
        ...careerPath,
        checked: assessment?.careerPathSlugs.includes(careerPath.careerPathSlug) ?? false,
      }));

      setFieldsOfPractice(nextFieldsOfPractice);

      setLoading(false);
    }

    getData();
  }, []);

  useEffect(() => {
    setSearchedFieldsOfPractice(search(fieldsOfPractice, query, 'careerPathName'));
  }, [query, fieldsOfPractice]);

  useEffect(() => {
    setErrorMessage(!fieldsOfPractice.filter(item => item.checked).length ? ErrorMessage.NoFieldsOfPractice : undefined);
  }, [fieldsOfPractice]);

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <SkeletonLoader height="550px" />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SkeletonLoader width="150px" height="25px" />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="card">
        <SearchBar
          label="Search"
          onInput={event => setQuery((event.target as HTMLInputElement).value)}
          responsive
        />

        <div style={{ marginTop: 15, marginBottom: 15, maxHeight: 400, overflowY: 'auto' }}>
          {/* TODO: EntitySelectionList */}
          <ListLayout>
            {searchedFieldsOfPractice.map(fieldOfPractice => (
              <ListItemLayout key={fieldOfPractice.careerPathSlug}>
                <CheckboxCard
                  id={fieldOfPractice.careerPathSlug}
                  checked={fieldOfPractice.checked}
                  onChange={() => {
                    setFieldsOfPractice(currentState => {
                      const nextState: AssessmentsWizardFieldOfPractice[] = structuredClone(currentState);
                      const nextItem = nextState.find(item => item.careerPathSlug === fieldOfPractice.careerPathSlug);
                      if (!!nextItem) nextItem.checked = !nextItem.checked;
                      return nextState;
                    });
                  }}
                >
                  <div className="card" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 5 }}>
                    <label htmlFor={fieldOfPractice.careerPathSlug} className="caption1">{fieldOfPractice.careerPathName}</label>

                    <ChipWrapper className="ChipWrapper" style={{ justifyContent: 'flex-end' }}>
                      {!!fieldOfPractice.skillSets.length && (
                        <Chip label={`${fieldOfPractice.skillSets.length} Skills`} variant="tiny" backgroundColour="grc_10" colour="grc_14" />
                      )}

                      {!!fieldOfPractice.jobsIds?.length && (
                        <Chip label={`${fieldOfPractice.jobsIds.length} Jobs`} variant="tiny" backgroundColour="grc_11" colour="grc_14" />
                      )}

                      {!fieldOfPractice.jobsIds.length && !fieldOfPractice.skillSets.length && (
                        <Chip label="Empty" variant="tiny" backgroundColour="grc_2" colour="grc_14" />
                      )}
                    </ChipWrapper>
                  </div>
                </CheckboxCard>
              </ListItemLayout>
            ))}
          </ListLayout>
        </div>

        <div className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })}>{errorMessage}&nbsp;</div>
      </div>

      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            setDirty(true);

            if (!details?.slug) return;
            if (!!errorMessage) return;

            setLoading(true);

            const response = await actions.postAssessmentFieldsOfPractice({
              ...details,
              careerPathSlugs: fieldsOfPractice.filter(fieldOfPractice => fieldOfPractice.checked).map(fieldOfPractice => fieldOfPractice.careerPathSlug),
            });

            setLoading(false);

            !response
              ? actions.addNotification({
                active: true,
                id: crypto.randomUUID(),
                type: NotificationType.Error,
                priority: NotificationPriority.Toast,
                title: 'Something went wrong saving these fields of practice',
                message: 'Please try again',
                toastIncludeIcon: true,
              })
              : onSaveAndContinue();
          }}
        >
          Save & Continue
        </Anchor>
      </div>
    </Fragment>
  );
}