import React from 'react';
import { NotificationPriority, NotificationType } from './notifications.models';
import { useAppState } from '../overmind';
import { ModalHub } from './modal-hub/modal-hub';
import { TileHub } from './tile-hub/tile-hub';
import { ToastHub } from './toast-hub/toast-hub';
import { NotificationHubContainer } from './notifications.styles';

export function Notifications(): JSX.Element {
  const { notifications } = useAppState();
  const allActive = notifications.filter(q => q.active);
  const allowedTileTypes = [NotificationType.Activity, NotificationType.LoginStreak];
  const tiles = allActive.filter(notification => notification.priority === NotificationPriority.Tile || typeof notification.priority === 'undefined').filter(q => allowedTileTypes.includes(q.type));
  const toasts = allActive.filter(notification => notification.priority === NotificationPriority.Toast);
  const modal = allActive.find(notification => notification.priority === NotificationPriority.Modal);
  return (
    <NotificationHubContainer>
      {!!tiles.length && (
        <TileHub tiles={tiles} />
      )}

      {!!toasts.length && (
        <ToastHub toasts={toasts} />
      )}

      {!!modal && (
        <ModalHub {...modal} />
      )}
    </NotificationHubContainer>
  );
}

