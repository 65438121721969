/* 
- When used in CRUD processes for Permissions, this enum determines which Organization Level the Account can request data for 
- When used in Search Params, this enum specifies which Organization Level the Account is requesting data for (this will be checked against the assigned Organization Level Permission by the BE, to determine whether the Account is allowed to request data for this Organization Level)
*/
export enum OrganizationLevelType {
  Learner = 0,
  Department = 1,
  Company = 2,
  Team = 3,
}
