import React from 'react';

export function MeetsExpectationsIcon(): JSX.Element {
  return (
    <svg width="60" height="61" viewBox="0 0 60 61" fill="none">
      <g clipPath="url(#clip0_5328_49933)">
        <g opacity="0.4">
          <path
            d="M30.5818 56.4462C41.4418 56.4462 50.2456 54.5362 50.2456 52.1801C50.2456 49.8241 41.4418 47.9141 30.5818 47.9141C19.7217 47.9141 10.918 49.8241 10.918 52.1801C10.918 54.5362 19.7217 56.4462 30.5818 56.4462Z"
            fill="url(#paint0_radial_5328_49933)"
            style={{ mixBlendMode: 'multiply' }}
          />
        </g>
        <path d="M30.4351 44.517C39.3853 44.517 46.6408 37.2615 46.6408 28.3114C46.6408 19.3612 39.3853 12.1057 30.4351 12.1057C21.485 12.1057 14.2295 19.3612 14.2295 28.3114C14.2295 37.2615 21.485 44.517 30.4351 44.517Z" fill="url(#paint1_radial_5328_49933)" />
        <path style={{ mixBlendMode: 'multiply' }} d="M30.4351 44.517C39.3853 44.517 46.6408 37.2615 46.6408 28.3114C46.6408 19.3612 39.3853 12.1057 30.4351 12.1057C21.485 12.1057 14.2295 19.3612 14.2295 28.3114C14.2295 37.2615 21.485 44.517 30.4351 44.517Z" fill="url(#paint2_radial_5328_49933)" />
        <path style={{ mixBlendMode: 'screen' }} d="M41.8949 39.7709C48.2236 33.4422 48.2236 23.1813 41.895 16.8526C35.5663 10.5239 25.3054 10.524 18.9767 16.8526C12.648 23.1813 12.648 33.4422 18.9767 39.7709C25.3054 46.0996 35.5662 46.0996 41.8949 39.7709Z" fill="url(#paint3_radial_5328_49933)" />
        <path style={{ mixBlendMode: 'screen' }} d="M41.8949 39.7709C48.2236 33.4422 48.2236 23.1813 41.895 16.8526C35.5663 10.5239 25.3054 10.524 18.9767 16.8526C12.648 23.1813 12.648 33.4422 18.9767 39.7709C25.3054 46.0996 35.5662 46.0996 41.8949 39.7709Z" fill="url(#paint4_radial_5328_49933)" />
        <path style={{ mixBlendMode: 'screen' }} d="M41.8949 39.7709C48.2236 33.4422 48.2236 23.1813 41.895 16.8526C35.5663 10.5239 25.3054 10.524 18.9767 16.8526C12.648 23.1813 12.648 33.4422 18.9767 39.7709C25.3054 46.0996 35.5662 46.0996 41.8949 39.7709Z" fill="url(#paint5_radial_5328_49933)" />
        <ellipse cx="30.4561" cy="19.1471" rx="10" ry="6.17647" fill="url(#paint6_linear_5328_49933)" />
        <g filter="url(#filter0_d_5328_49933)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.5116 36.1722C26.5384 36.2031 26.5665 36.2332 26.5958 36.2626C27.2793 36.946 28.3873 36.946 29.0707 36.2626L39.0958 26.2374C39.7793 25.554 39.7793 24.446 39.0958 23.7626C38.4124 23.0791 37.3044 23.0791 36.621 23.7626L27.8363 32.5472L24.9872 29.6981C24.3038 29.0147 23.1958 29.0147 22.5123 29.6981C21.8289 30.3815 21.8289 31.4896 22.5123 32.173L26.5116 36.1722Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_5328_49933" x="22" y="23.25" width="17.6084" height="13.7751" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0.67451 0 0 0 0 0.988235 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5328_49933" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5328_49933" result="shape" />
        </filter>
        <radialGradient id="paint0_radial_5328_49933" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.5092 52.2515) rotate(-0.314485) scale(12.9968 2.8715)">
          <stop stopColor="#318CF7" />
          <stop offset="1" stopColor="#3080F7" stopOpacity="0" />
        </radialGradient>
        <radialGradient id="paint1_radial_5328_49933" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.4999 24.75) rotate(1.17262) scale(22.1429 22.1429)">
          <stop stopColor="#75CDFF" />
          <stop offset="0.25" stopColor="#85D3FF" />
          <stop offset="0.5" stopColor="#8EE4FF" />
          <stop offset="0.75" stopColor="#53B7FF" />
          <stop offset="1" stopColor="#2E8BF8" />
        </radialGradient>
        <radialGradient id="paint2_radial_5328_49933" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.9597 36.0076) scale(22.9448 22.9448)">
          <stop stopColor="#6DADF9" />
          <stop offset="0.33" stopColor="#A7C9FB" />
          <stop offset="0.78" stopColor="white" />
        </radialGradient>
        <radialGradient id="paint3_radial_5328_49933" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30.5983 20.9579) rotate(144.181) scale(23.0101 23.0101)">
          <stop />
          <stop offset="0.44" />
          <stop offset="0.51" />
          <stop offset="0.61" stopColor="#030303" />
          <stop offset="0.69" stopColor="#0C0C0C" />
          <stop offset="0.76" stopColor="#1C1C1C" />
          <stop offset="0.82" stopColor="#323232" />
          <stop offset="0.87" stopColor="#4E4E4E" />
          <stop offset="0.93" stopColor="#707070" />
          <stop offset="0.96" stopColor="#898989" />
          <stop offset="0.97" stopColor="#8D8D8E" />
          <stop offset="0.98" stopColor="#9A9B9B" />
          <stop offset="0.99" stopColor="#AFB0B2" />
          <stop offset="1" stopColor="#BBBDBF" />
        </radialGradient>
        <radialGradient id="paint4_radial_5328_49933" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.3554 33.3928) rotate(8.93058) scale(22.9929 22.9929)">
          <stop />
          <stop offset="0.71" />
          <stop offset="0.83" />
          <stop offset="0.92" stopColor="#020202" />
          <stop offset="0.95" stopColor="#090909" />
          <stop offset="0.97" stopColor="#151515" />
          <stop offset="0.99" stopColor="#262626" />
          <stop offset="1" stopColor="#363636" />
        </radialGradient>
        <radialGradient id="paint5_radial_5328_49933" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.5331 35.8458) rotate(-100.993) scale(27.6452 27.6453)">
          <stop />
          <stop offset="0.65" />
          <stop offset="0.76" />
          <stop offset="0.82" stopColor="#060606" />
          <stop offset="0.9" stopColor="#161616" />
          <stop offset="0.98" stopColor="#303030" />
          <stop offset="1" stopColor="#363636" />
        </radialGradient>
        <linearGradient id="paint6_linear_5328_49933" x1="30.4561" y1="12.9706" x2="30.4561" y2="25.3235" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.74" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_5328_49933">
          <rect width="60" height="60" fill="white" transform="translate(0 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
}
