import React from 'react';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import styles from './recharts-tooltip.module.css';

export function RechartsTooltip({ active, payload }: TooltipProps<ValueType, NameType>): JSX.Element {
  if (active) {
    return (
      <div className={styles.tooltipWrapper}>
        {payload && payload[0] && (
          <>
            <span key={payload[0].payload.name} className='caption1'>
              {payload[0].payload.name}
            </span>
            {payload.map((item) => {
              return (
                <div key={item.name} className={styles.tooltipText}>
                  <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="4" cy="4" r="4" fill={item.color} />
                  </svg>
                  <span className='caption1'>{`${item.name}: ${item.value ?? 'Not assessed'}`}</span>
                </div>
              );
            })}
          </>
        )
        }
      </div>
    );
  }

  return <></>;
}