import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function SkillLayoutSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="145" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="calc(100vh - 265px)" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
