import styled from 'styled-components';
import { CMSColumn, CMSHeaderRow, CMSRow } from '../../../design/layout.styles';

export const PeopleTableNameCellContentContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  @media screen and (max-width: 580px) {
    flex-flow: row-reverse nowrap;
    gap: 5px;
  }
`;

export const PeopleHeaderMobileActionsRow = styled(CMSRow)`
  gap: 10px;

  ${CMSColumn} {
    flex: 1;
  }
`;

export const PeopleHeaderActionsRow = styled(CMSHeaderRow)`
  grid-template-columns: minmax(135px, 300px) 1fr auto auto auto;

  @media screen and (max-width: 580px) {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;
  }
`;
