import { DaterangeGeneratedRanges } from './daterange.models';

export const ShortMonthList = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`];

export function prettyDate(date: Date): string {
  const d = new Date(date);
  return `${d.getDate()} ${ShortMonthList[d.getMonth()]}`;
}

export function prettyDateBig(date: Date): string {
  const d = new Date(date);
  return `${ShortMonthList[d.getMonth()]} ${d.getFullYear()}`;
}

function getDaysInMonth(date: Date): number {
  // Here January is 1 based
  // Day 0 is the last day in the previous month
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

function calculateWeekStartAndEnd(date: Date): [Date, Date] {
  const start = new Date(date);
  start.setDate(start.getDate() - (start.getDay() - 1));

  const end = new Date(start);
  end.setDate(end.getDate() + 6);

  return [start, end];
}

function calculateMonthStartAndEnd(date: Date): [Date, Date] {
  const maxDays = getDaysInMonth(date);

  const start = new Date(date);
  start.setDate(1);

  const end = new Date(start);
  end.setDate(end.getDate() + (maxDays - 1));

  return [start, end];
}

function calculateYearToDateStartAndEnd(date: Date): [Date, Date] {
  const start = new Date(date);
  start.setMonth(0);
  start.setDate(1);

  const end = new Date(date);
  end.setMonth(date.getMonth() + 1);
  end.setDate(0);

  return [start, end];
}

function calculateCurrentYearStartAndEnd(date: Date): [Date, Date] {
  const start = new Date(date);
  start.setMonth(date.getMonth());
  start.setDate(1);
  start.setFullYear(date.getFullYear() - 1);

  const end = new Date(date);
  end.setMonth(date.getMonth() + 1);
  end.setDate(0);

  return [start, end];
}

export function computeDateRanges(date: Date): DaterangeGeneratedRanges {
  const d = new Date(date);

  const [weekStart, weekEnd] = calculateWeekStartAndEnd(d);
  const [monthStart, monthEnd] = calculateMonthStartAndEnd(d);
  const [yearStart, yearEnd] = calculateYearToDateStartAndEnd(d);
  const [currYearStart, currYearEnd] = calculateCurrentYearStartAndEnd(d);

  return {
    today: { value: prettyDate(d), date: d },
    thisWeek: { start: prettyDate(weekStart), end: prettyDate(weekEnd), endDate: weekEnd, startDate: weekStart },
    thisMonth: { start: prettyDate(monthStart), end: prettyDate(monthEnd), endDate: monthEnd, startDate: monthStart },
    thisYear: { start: prettyDateBig(currYearStart), end: prettyDateBig(currYearEnd), endDate: currYearEnd, startDate: currYearStart },
    yearToDate: { start: prettyDateBig(yearStart), end: prettyDateBig(yearEnd), endDate: yearEnd, startDate: yearStart },
  };
}
