import { AddTwoToneIcon, Anchor, Button, GridIcon, GridItemLayout, GridLayout, KebabMenu, ListIcon, Pager, Searchfield, Table, TableColumn, TableRow, ToggleButtonGroup, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { HoverCard } from '../../../components/cards/hover-card/hover-card';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { OrganizationLevelType, SortField } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppState, useAppActions } from '../../../overmind';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { CompanyEntityListResponse } from '../../../models/overmind/company-entity';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import styles from './roles.module.css';
import { ICompanyRolesLayoutGridProps, ICompanyRolesLayoutListProps, ViewType } from './roles.models';

function CompanyRolesLayoutGridSkeleton(): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  return (
    <React.Fragment>
      {isMobile ? (
        <GridLayout columnCount={3}>
          <GridItemLayout>
            <SkeletonLoader height="145px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="145px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="145px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="145px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="145px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="145px" />
          </GridItemLayout>
        </GridLayout>
      ) : (
        <GridLayout columnCount={3}>
          <GridItemLayout>
            <SkeletonLoader height="250px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="250px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="250px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="250px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="250px" />
          </GridItemLayout>

          <GridItemLayout>
            <SkeletonLoader height="250px" />
          </GridItemLayout>
        </GridLayout>
      )}
    </React.Fragment>
  );
}

function CompanyRolesLayoutGrid({ loading, data }: ICompanyRolesLayoutGridProps): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const { companyVariables } = useAppState();

  return (
    <React.Fragment>
      {loading ? (
        <CompanyRolesLayoutGridSkeleton />
      ) : (
        <GridLayout columnCount={3}>
          {data?.entities.map(role => (
            <GridItemLayout key={role.slug}>
              <HoverCard
                title={role.name}
                description={role.description ?? ''}
                onClickAction={() => keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementCompanyRole.replace(':roleSlug', role!.slug!).replace(':companySlug', companyVariables.slug!)}`)}
              />
            </GridItemLayout>
          ))}
        </GridLayout>
      )}
    </React.Fragment >
  );
}

function CompanyRolesLayoutList({ loading, sortAscending, setRequest, data }: ICompanyRolesLayoutListProps): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const { companyVariables } = useAppState();

  return (
    <React.Fragment>
      {loading ? (
        <SkeletonLoaderRow style={{ marginBottom: 0 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="300px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      ) : (
        <Table
          currentSortBy="role"
          currentSortDirection={sortAscending ? 'Ascending' : 'Descending'}
          onSort={() => setRequest(currentState => ({ ...currentState, sortAscending: !currentState.sortAscending }))}
          standAloneConfigColumn
        >
          {data?.entities.map(role => (
            <TableRow
              key={role.slug}
              id={role.slug}
              configCell={() => (
                <KebabMenu
                  items={[
                    {
                      label: 'View Role',
                      onClick: () => keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementCompanyRole.replace(':roleSlug', role.slug).replace(':companySlug', companyVariables.slug!)}`)
                    },
                    {
                      label: 'Edit Role',
                      onClick: () => keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementEditRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug)}`)
                    },
                  ]}
                />
              )}
            >
              <TableColumn sortable id="role" label="Role">
                <Anchor
                  hovertype="opacity"
                  textTransform="none"
                  onClick={() => keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementCompanyRole.replace(':roleSlug', role.slug).replace(':companySlug', companyVariables.slug!)}`)}
                >
                  {role.name}
                </Anchor>
              </TableColumn>

              <TableColumn id="description" label="Description">
                {role.description}
              </TableColumn>
            </TableRow>
          ))}
        </Table>
      )}
    </React.Fragment>
  );
}

export function CompanyRolesLayout(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { user, permissions, companyVariables } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

  const [activeViewType, setActiveViewType] = useState<ViewType>('Grid');
  const [loading, setLoading] = useState<boolean>(true);
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 6,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  });
  const [data, setData] = useState<CompanyEntityListResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await actions.getCompanyRoles(request);
      setData(response);

      setLoading(false);
    }

    getData();
  }, [request]);

  return (
    <div className={styles.companyRolesContainer}>
      <div className={styles.companyRolesActions}>
        <Searchfield onInput={search => setRequest({ ...request, search, page: 1 })} />

        <div className={styles.companyRolesActionsGroup}>
          {(user?.isSystemAdmin || organizationLevel?.organizationLevel === OrganizationLevelType.Company) && (
            <Button
              fullWidthMobile
              type="button"
              square
              theme="dark"
              onClick={() => keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementCreateRole.replace(':companySlug', companyVariables.slug!)}`)}
            >
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <AddTwoToneIcon />
                Create role
              </div>
            </Button>
          )}

          <ToggleButtonGroup
            buttons={[
              { label: 'Grid', icon: <GridIcon /> },
              { label: 'List', icon: <ListIcon /> },
            ]}
            defaultButtonLabel={activeViewType}
            onClick={buttonLabel => setActiveViewType(buttonLabel as ViewType)}
          />
        </div>
      </div>

      {(loading || !!data?.entities.length) ? (
        <React.Fragment>
          {activeViewType === 'Grid' ? (
            <CompanyRolesLayoutGrid
              loading={loading}
              data={data}
            />
          ) : (
            <CompanyRolesLayoutList
              loading={loading}
              sortAscending={!!request.sortAscending}
              setRequest={setRequest}
              data={data}
            />
          )}

          {(!!data && data.totalPages > 1) && (
            <PagerConnector
              defaultPageNumber={request.page}
              pageCount={data?.totalPages ?? 0}
              onPageChange={page => setRequest(currentState => ({ ...currentState, page }))}
            >
              {connector => (<Pager {...connector} isLoading={loading} />)}
            </PagerConnector>
          )}
        </React.Fragment>
      ) : (
        <EmptyState title="No roles found" />
      )}
    </div>
  );
}
