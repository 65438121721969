import React, { useEffect, useState } from 'react';
import { Anchor, AvatarIcon, Button, Chip, ChipWrapper, DropdownSelect, GridItemLayout, GridLayout, Modal, PageHeader, capitalCase, useMatchScreenWidth } from '@keplerco/core';
import { ConfirmationEndorsementsModalLayout } from '../rating-technical-peer-endorsement-modal/rating-confirm-endorsements-modal.layout';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { EndorsementsTitleWrapper, RatingTechnicalPeerEndorsementCard, RatingTechnicalPeerEndorsementPageAvatar, RatingTechnicalPeerEndorsementPageHeader } from './rating-technical-peer-endorsement-landing.styles';
import { CompletionStatus, FetchType } from '../../../../../enums';
import { filteredColours } from '../../../../../library/consts/filtered-colours';
import { Navigate, NavLink, useNavigate } from 'react-router-dom';
import { PageLoaderLayer } from '../../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { RatingLandingCardsWrapper, RatingPageLayout } from '../../rating.styles';
import { RatingTechnicalPeerEndorsementLandingSkeleton } from './rating-technical-peer-endorsement-landing.skeleton';
import { RoutingMapResult } from '../../../../../models/routing-map';
import { TeamMemberReviewResource } from '../../../../../models/team-member-review-resource';
import { themedAssetUrl } from '../../../../../library/helpers/themed-asset-url';
import { useAppActions, useAppState } from '../../../../../overmind';
import { AssessmentType } from '../../../../../enums/assessment-type';

enum FilterStatus {
  All = 'All',
  Completed = 'Completed',
  NotCompleted = 'NotCompleted',
}

export function RatingTechnicalPeerEndorsementLandingPage(): JSX.Element {
  const navigate = useNavigate();
  const { skillAssessmentConfig } = useAppState();

  // TODO: add custom AnalysisRouter route guard
  if (skillAssessmentConfig?.assessmentType !== AssessmentType.PeerEndorsement) return <Navigate to={PagePath.analysisBase} />;

  const actions = useAppActions();

  const [team, setTeam] = useState<TeamMemberReviewResource[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>(FilterStatus.All);

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisRatingTechnicalPeerEndorsements, type: FetchType.PageFetching });
      const data = await actions.getTeamReviews();
      setTeam(data);
      actions.stopLoader(PagePath.analysisRatingTechnicalPeerEndorsements);
    }
    getData();
  }, []);

  const isMobile = useMatchScreenWidth('mobile');
  const isTablet = useMatchScreenWidth('tablet');
  let columns = 3;
  if (isMobile) columns = 1;
  else if (isTablet) columns = 2;

  const teamMembers = team?.filter(member => member.completionStatus !== CompletionStatus.NoActionRequired).sort((a, b) => a.firstName.localeCompare(b.firstName));

  async function onClickHandler(renderProps: RoutingMapResult) {
    actions.startLoader({ path: PagePath.analysisRatingTechnicalPeerEndorsements, type: FetchType.Sending });

    await actions.submitTeamReviews();
    actions.stopLoader(PagePath.analysisRatingTechnicalPeerEndorsements);
    const result = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug);

    if (result?.selfPeerReviewCompletionStatus === CompletionStatus.Completed && (result.selfReviewCompletionStatus === CompletionStatus.NoActionRequired || result.selfReviewCompletionStatus === CompletionStatus.Completed)) {
      actions.clearSkillAssessmentConfig();
      return;
    }

    return navigate(renderProps.next!);
  }
  function handleFilterChange(status: FilterStatus) {
    setFilterStatus(status);
    setShowModal(false);
  }

  const filteredTeamMembers = teamMembers?.filter(member => {
    if (filterStatus === FilterStatus.All) return true;
    if (filterStatus === FilterStatus.Completed) return member.hasCompletedReview;
    if (filterStatus === FilterStatus.NotCompleted) return !member.hasCompletedReview;
    return false;
  });

  function breadcrumbConfiguration() {
    if (skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired) {
      return [
        {
          name: 'Endorse your team',
          url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
        },
      ];
    }

    return [
      { name: 'Skills analysis', url: PagePath.analysisBase },
      { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
      { name: 'Role-based skills analysis', url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}` },
      {
        name: 'Endorse your team',
        url: `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`,
      },
    ];
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.analysisRatingTechnicalPeerEndorsements} skeletonLoader={<RatingTechnicalPeerEndorsementLandingSkeleton />}>
        <RatingPageLayout className="pageWrapper wrapper">
          <RatingTechnicalPeerEndorsementPageHeader>
            <PageHeader breadcrumbs={breadcrumbConfiguration()} title="Endorse your team" subtitle="Reviewing your team's work fosters collaboration and continuous improvement, helping them grow and building a stronger team." type="actions">
              <div className="headerActions">
                <Button type="button" arrow onClick={() => setShowModal(!showModal)}>
                  Submit
                </Button>
              </div>
            </PageHeader>
          </RatingTechnicalPeerEndorsementPageHeader>

          {!!team && (
            <RatingLandingCardsWrapper>
              <EndorsementsTitleWrapper>
                <h4 className="heading4" style={{ marginRight: 20, marginTop: 10 }}>
                  Endorsements:
                </h4>

                <DropdownSelect
                  label="Filter by"
                  value={filterStatus.toString()}
                  items={[
                    {
                      label: 'All',
                      value: FilterStatus.All,
                      onClick: () => handleFilterChange(FilterStatus.All),
                    },
                    {
                      label: 'Completed',
                      value: FilterStatus.Completed,
                      onClick: () => handleFilterChange(FilterStatus.Completed),
                    },
                    {
                      label: 'Not Started',
                      value: FilterStatus.NotCompleted,
                      onClick: () => handleFilterChange(FilterStatus.NotCompleted),
                    },
                  ]}
                />
              </EndorsementsTitleWrapper>

              {!!filteredTeamMembers?.length ? (
                <GridLayout columnCount={columns}>
                  {filteredTeamMembers.map((member, index) => {
                    return (
                      <GridItemLayout key={member.userId}>
                        <UserNavlinkCard member={member} index={index} />
                      </GridItemLayout>
                    );
                  })}
                </GridLayout>
              ) : (
                <div style={{ padding: '30px 0 0 0' }}>
                  <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={450} subtitle="No team members" />
                </div>
              )}
            </RatingLandingCardsWrapper>
          )}
        </RatingPageLayout>
      </PageLoaderLayer>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <ConfirmationEndorsementsModalLayout
          title={`Have you endorsed everyone that you work closely with?`}
          team={team}
          handleShowCompleted={() => handleFilterChange(FilterStatus.Completed)}
          handleShowNotCompleted={() => handleFilterChange(FilterStatus.NotCompleted)}
          onClickSubmit={() =>
            onClickHandler({
              next: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`,
              previous: `${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`,
            })
          }
        />
      </Modal>
    </React.Fragment>
  );
}

function UserNavlinkCard({ member, index }: { member: TeamMemberReviewResource; index: number }) {
  return (
    <>
      <NavLink to={`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsement.replace(':ratingSlug', member.userSlug)}`} style={{ textDecoration: 'none' }}>
        <RatingTechnicalPeerEndorsementCard className="card">
          {member.hasCompletedReview ? (
            <ChipWrapper className="ratingChipWrapper">
              <Chip label="Completed" variant="tiny" backgroundColour="g" colour="background" />
            </ChipWrapper>
          ) : (
            <ChipWrapper className="ratingChipWrapper">
              <Chip label="Not Started" variant="tiny" backgroundColour="light" />
            </ChipWrapper>
          )}

          <RatingTechnicalPeerEndorsementPageAvatar>
            <React.Fragment>{!!member.profileImageUrl ? <img src={member.profileImageUrl} /> : <AvatarIcon name={member} imageUrl={member.profileImageUrl} backgroundColour={filteredColours[index % filteredColours.length]} fontColour={'grc_14'} />}</React.Fragment>
          </RatingTechnicalPeerEndorsementPageAvatar>

          <h6 className="subtitle">
            {member.firstName} {member.lastName}
          </h6>

          <span className="caption2">&nbsp;{capitalCase(member.jobTitle)}&nbsp;</span>

          {member.hasCompletedReview ? <Anchor>Change Endorsement</Anchor> : <Anchor>Start Endorsement</Anchor>}
        </RatingTechnicalPeerEndorsementCard>
      </NavLink>
    </>
  );
}
