import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { AuthenticationStatus as STATUS } from '../../../enums/authentication-status';
import { LoginAccountPageForgotPassword, AccountPageContainer, AccountPageLineBreak, AccountPageForm, AccountPageNavigationToggler } from '../account.styles';
import { AccountPageButton } from '../account.components';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { FetchType } from '../../../enums';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { PagePath } from '../../../navigation/navigation.enums';
import WeglotLanguageSwitcher from '../../../components/general/weglot/weglot-switcher';
import { Button, EmailValidator, FormControl, RequiredValidator, Textfield } from '@keplerco/core';
import { ShapesBackgroundWrapper } from '../../../design/layout.styles';

export function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');

  const actions = useAppActions();
  const { authenticationStatus } = useAppState<KeplerState>();

  const { control, handleSubmit } = useForm<any>({ reValidateMode: 'onChange', mode: 'onBlur' });

  const [allowCreateAccount, setAllowCreateAccount] = useState<boolean>();
  const [samlUrl, setSamlUrl] = useState<string>();
  const [removeErrorMessage, setRemoveErrorMessage] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      setAllowCreateAccount(await actions.getAllowCreateAccount());
      setSamlUrl(await actions.getSamlUrl(getSubdomain()));
    }

    getData();
  }, []);

  return (
    <>
      <ShapesBackgroundWrapper>
        <AccountPageContainer>
          <div className="card" style={{ overflow: 'hidden' }}>
            <AccountPageLoaderLayer path={PagePath.accountLogin}>
              <AccountPageNavigationToggler className="showDesktop">
                {allowCreateAccount ? (
                  <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                    Not registered? <u>Create an account</u>
                  </span>
                ) : (
                  <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                    Not registered? <u>Activate your account</u>
                  </span>
                )}
              </AccountPageNavigationToggler>

              <h1 className="heading1">Sign In</h1>

              <h6 className="subtitle">Fill in your company email address and your password</h6>

              <AccountPageForm
                id="login"
                autoComplete="off"
                onSubmit={handleSubmit(async value => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  window.scrollTo(0, 0);
                  setRemoveErrorMessage(false);
                  await actions.login(value);
                  actions.stopLoader(PagePath.accountLogin);
                })}
              >
                {authenticationStatus === STATUS.AuthenticationFailed && !removeErrorMessage && <div className="formErrorMessage">Incorrect password or email address</div>}

                <FormControl paddingBottom={0} name="email" control={control} rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

                <FormControl paddingBottom={0} name="password" control={control} rules={new RequiredValidator('Enter your password')} render={({ field, fieldState }) => <Textfield className="Textfield" {...field} haserror={!!fieldState.error} label="Password" type="password" responsive />} />

                <LoginAccountPageForgotPassword>
                  <span
                    className="caption1"
                    onClick={() => {
                      navigate(`${PagePath.accountBase}${PagePath.accountForgotPassword}`);
                    }}
                  >
                    <u>Forgot password?</u>
                  </span>
                </LoginAccountPageForgotPassword>

                <Button fullWidthMobile={true} type="button">
                  Sign In
                </Button>
              </AccountPageForm>

              <AccountPageLineBreak>Or sign in using</AccountPageLineBreak>

              {!!samlUrl && (
                <AccountPageButton
                  buttonType="saml"
                  onClick={async () => {
                    window.location.href = !!returnTo ? `${samlUrl}?returnUrl=${returnTo}` : samlUrl;
                  }}
                />
              )}

              <AccountPageButton
                buttonType="google"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  await actions.externalLogin({ provider: 'Google' });
                  actions.stopLoader(PagePath.accountLogin);
                }}
              />

              <AccountPageButton
                buttonType="microsoft"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  await actions.externalLogin({ provider: 'Microsoft' });
                  actions.stopLoader(PagePath.accountLogin);
                }}
              />

              <AccountPageButton
                buttonType="linkedin"
                onClick={async () => {
                  actions.startLoader({ path: PagePath.accountLogin, type: FetchType.PageFetching });
                  await actions.externalLogin({ provider: 'LinkedIn' });
                  actions.stopLoader(PagePath.accountLogin);
                }}
              />

              <AccountPageNavigationToggler className="showMobile">
                {allowCreateAccount ? (
                  <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                    Not registered? <u>Create an account</u>
                  </span>
                ) : (
                  <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                    Not registered? <u>Activate your account</u>
                  </span>
                )}
              </AccountPageNavigationToggler>
            </AccountPageLoaderLayer>
          </div>
        </AccountPageContainer>
      </ShapesBackgroundWrapper>
      <WeglotLanguageSwitcher position="bottomLeft" />
    </>
  );
}
