import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, KebabMenu, Panel } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../../../overmind';
import { SkillDropdownItem, ICourseMappingCourseSkillConditionProps } from '../course-mapping-course.models';
import { CourseMappingResponseSkill } from '../../../../../models/overmind/learning-management';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { FetchType, OrganizationLevelType, SortField } from '../../../../../enums';
import { createLevelsArray } from '../../../../../library/helpers/create-levels-array';
import { CompanyEntityListItemResponse } from '../../../../../models/overmind/company-entity';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { ExplainerFocusPanelLayout } from '../../../../../widgets/layouts';
import { DisplayList } from '../../../../../components/lists/display-list';
import { ListItem } from '../../../../../components/lists/list-item';

export function CourseMappingCourseSkillCondition({ skills, onClickAddSkill, onClickRemoveSkill, onClickAddLevel, onClickRemoveLevel }: ICourseMappingCourseSkillConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const organizationLevel = extractHighestOrganizationLevel(permissions?.learningManagement?.organizationLevels);
  const defaultRequest: CompanyEntitySearchParams = {
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 99999,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  };

  const [selectedDropdownItems, setSelectedDropdownItems] = useState<string[]>([]);
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [skillToView, setSkillToView] = useState<CourseMappingResponseSkill>();

  useEffect(() => {
    function onClickDropdownItem(skill: CompanyEntityListItemResponse) {
      setSelectedDropdownItems(currentState => {
        const nextState = structuredClone(currentState);
        const index = nextState.indexOf(skill.slug);
        if (index === -1) {
          nextState.push(skill.slug);
          onClickAddSkill(skill);
        } else {
          nextState.splice(index, 1);
          onClickRemoveSkill(skill.slug);
        }
        return nextState;
      });
    }

    async function getDropdownItems() {
      const response = await actions.getCompanySkills(defaultRequest);

      const selectedSkills = skills?.map(skill => skill.slug!) ?? [];
      setSelectedDropdownItems(selectedSkills);

      const items: SkillDropdownItem[] =
        response?.entities.map(skill => ({
          value: skill.slug,
          label: skill.name,
          description: skill.description,
          onClick: () => onClickDropdownItem(skill),
          selected: skills?.some(temp => temp.slug === skill.slug),
        })) ?? [];
      setDropdownItems(items);
    }

    async function getData() {
      actions.startLoader({ path: PagePath.learningManagementCourseMappingCourse, type: FetchType.PageFetching });
      await getDropdownItems();
      actions.stopLoader(PagePath.learningManagementCourseMappingCourse);
    }

    getData();
  }, []);

  useEffect(() => {
    const selectedSkills = skills?.map(skill => skill.slug!) ?? [];
    setSelectedDropdownItems(selectedSkills);
  }, [skills]);

  return (
    <React.Fragment>
      <EntitiesCardWidget title="Skills" description="Courses assigned by skill will only appear once an assessment has been completed.">
        <DisplayList dropdownValue={selectedDropdownItems} dropdownItems={dropdownItems}>
          {skills?.map(skill => {
            return (
              <ListItem key={skill.slug}>
                <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: 'auto 1fr 195px' }}>
                  <KebabMenu
                    items={[
                      {
                        label: 'Remove skill',
                        onClick: () => {
                          onClickRemoveSkill(skill.slug!);
                          setSelectedDropdownItems(currentState => {
                            const nextState = structuredClone(currentState);
                            const index = nextState.indexOf(skill.slug!);
                            index !== -1 ? nextState.push(skill.slug!) : nextState.splice(index, 1);
                            return nextState;
                          });
                        },
                      },
                      {
                        label: 'View skill',
                        onClick: () => setSkillToView(skill),
                      },
                    ]}
                  />

                  <div>
                    <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                      {skill.name}
                    </div>

                    <div className="caption2">{skill.description ?? 'No description provided'}</div>
                  </div>

                  <DropdownMenu
                    label="Expected proficiency"
                    items={createLevelsArray(companyVariables.maxLevel, companyVariables.minLevel).map(level => ({
                      value: level.toString(),
                      label: `Level ${level}`,
                      onClick: async () => {
                        skill.levels?.includes(level) ? onClickRemoveLevel(skill.slug!, level) : onClickAddLevel(skill.slug!, level);
                      },
                    }))}
                    value={skill.levels?.map(level => level.toString())}
                    responsive
                    multiple
                  />
                </div>
              </ListItem>
            );
          })}
        </DisplayList>
      </EntitiesCardWidget>

      <Panel open={!!skillToView} onClose={() => setSkillToView(undefined)}>
        {!!skillToView && <ExplainerFocusPanelLayout supertitle="Learning Management" title={skillToView.name} description={skillToView.description ?? ''} onClose={() => setSkillToView(undefined)} />}
      </Panel>
    </React.Fragment>
  );
}
