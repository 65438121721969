import React from 'react';
import { PagePath } from '../../../navigation/navigation.enums';
import { ActivationStatus } from '../../../enums';
import { ActivationStatusIcon } from './assets/activation-status.icon';
import { Tooltip } from '@keplerco/core';

// header actions
export const filterableColumns = ['Department', 'Team', 'Country', 'Status'];

// tables
export function PersonActivationStatusIcon(props: { status: ActivationStatus }): JSX.Element {
  let statusExplainer = '';
  if (props.status === ActivationStatus.Inactive) statusExplainer = `This user has not activated their account`;
  else if (props.status === ActivationStatus.NotInvited) statusExplainer = `This user has not been invited`;

  if (!statusExplainer) {
    return <ActivationStatusIcon status={props.status} />;
  }

  return (
    <Tooltip content={statusExplainer}>
      <ActivationStatusIcon status={props.status} />
    </Tooltip>
  );
}

export function createDepartmentLink(companySlug: string, departmentId: number | string) {
  const slugged = PagePath.administrationDepartmentPeople.replace(':companySlug', companySlug);
  return `${PagePath.administrationBase}${slugged.replace(':departmentSlug', departmentId?.toString())}`;
}

export function createTeamLink(companySlug: string, teamId: number | string) {
  const slugged = PagePath.administrationTeamPeople.replace(':companySlug', companySlug);
  return `${PagePath.administrationBase}${slugged.replace(':teamSlug', teamId?.toString())}`;
}

