import React, { Fragment } from 'react';

import { SkeletonLoader } from '../../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderRow, SkeletonLoaderColumn } from '../../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';

export function ManagePersonFocusPanelSkeleton(props: { personId: string | undefined }): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow style={{ marginBottom: 50 }}>
        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="90px" width="90px" borderRadius="50%" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={9}>
          <SkeletonLoaderRow style={{ marginBottom: 10 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="35px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn />
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="20px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn />
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div>
        <SkeletonLoaderRow style={{ marginBottom: 10 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height={!!props.personId ? '545px' : '480px'} />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        {!!props.personId && (
          <Fragment>
            <SkeletonLoaderRow style={{ marginBottom: 10 }}>
              <SkeletonLoaderColumn>
                <SkeletonLoader height="75px" />
              </SkeletonLoaderColumn>
            </SkeletonLoaderRow>

            <SkeletonLoaderRow>
              <SkeletonLoaderColumn>
                <SkeletonLoader height="75px" />
              </SkeletonLoaderColumn>
            </SkeletonLoaderRow>
          </Fragment>
        )}
      </div>
    </div>
  );
}
