export function getSubdomain(): string {
  let subdomain = window.location.host.split('.')[0];

  if (/:[0-9]/gi.test(subdomain)) {
    if (process.env.REACT_APP_ASSETS_URL) {
      const temp = process.env.REACT_APP_ASSETS_URL.split('.')[0];
      subdomain = temp.split('://')[1];
    } else subdomain = 'app';
  }

  return subdomain;
}
