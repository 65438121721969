import { Context } from '../..';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import { Mode } from '../../../navigation/navigation.enums';
import { LearningJourneyView } from '../../../enums/learning-journey-view';
import { User } from '../../../models/user';

export function setSideNavVisible(context: Context, payload: boolean) {
  context.state.sideNavVisible = payload;
}

export function setSideNavCollapsed(context: Context, payload: boolean) {
  context.state.sideNavCollapsed = payload;
}

export function setMode(context: Context, payload: Mode) {
  context.state.mode = payload;

  if (!context.state.user) return;
  window.localStorage.setItem(`${context.state.user.learnerSlug}-mode`, payload);

  if (payload === Mode.LearningDashboard) context.state.sideNavVisible = true;
}

export function setLearningJourneyView(context: Context, payload: LearningJourneyView) {
  context.state.learningJourneyView = payload;
}

export function prettyTime(date: Date): string {
  const parsedDate = new Date(date);
  const hour = parsedDate.getHours().toString().padStart(2, '0');
  const minute = parsedDate.getMinutes().toString().padStart(2, '0');
  const seconds = parsedDate.getSeconds().toString().padStart(2, '0');
  return `${hour}:${minute}:${seconds}`;
}

export function setActiveCompanySlug(context: Context, slug: string | undefined) {
  context.state.companyVariables.slug = slug;

  if (!!context.state.user) {
    !slug ? window.localStorage.removeItem(`${context.state.user.learnerSlug}-activeCompanySlug`) : window.localStorage.setItem(`${context.state.user.learnerSlug}-activeCompanySlug`, slug); // TODO: look at using useParams instead
  }
}

export function getActiveCompanySlug(context: Context, user: User) {
  const activeCompanySlug = window.localStorage.getItem(`${user.learnerSlug}-activeCompanySlug`);

  !!activeCompanySlug ? (context.state.companyVariables.slug = activeCompanySlug) : context.actions.setActiveCompanySlug(user.companySlug);
}

export function triggerToastStack(context: Context) {
  for (let index = 0; index < 10; index++) {
    context.actions.addNotification({
      id: index.toString(),
      message: 'This is the notification body. Please read it carefully',
      title: 'This is the notification title',
      type: NotificationType.Error,
      priority: NotificationPriority.Toast,
      active: true,
    });
  }
}
