import React from 'react';
import { YourSkillsModalContent, YourSkillsModalHeader, YourSkillsModalBodyDescription } from './your-skills-modal.styles';
import { sortByUrgencyIndication } from '../skills-report.helpers';
import { GridItemLayout, GridLayout, kebabCase } from '@keplerco/core';
import { ISkillsReportModalProps } from './skills-report-modal.models';
import { EntitySkillScoresCard } from '../../../../components/cards/entity-skill-scores-card/entity-skill-scores-card';
import { PagePath } from '../../../../navigation/navigation.enums';

export function SkillsReportModalLayout({ skillSubType }: ISkillsReportModalProps): JSX.Element {
  return (
    <YourSkillsModalContent className="modalContent">
      <YourSkillsModalHeader style={{ textAlign: 'center' }}>
        <h2 className="heading2" id={kebabCase(`${skillSubType.name}-display-modal`)}>
          {skillSubType.name}
        </h2>
      </YourSkillsModalHeader>

      <YourSkillsModalBodyDescription className="subtitle">Here is your skills score breakdown that demonstrates which skills to focus on first in your personalised learning journey.</YourSkillsModalBodyDescription>

      <GridLayout columnCount={3}>
        {skillSubType.skills
          .sort((a, b) => (!!a.urgencyIndication && !!b.urgencyIndication ? sortByUrgencyIndication(a.urgencyIndication, b.urgencyIndication) : (a.score?.percentage ?? 0) - (b.score?.percentage ?? 0)))
          .map(skill => {
            return (
              <GridItemLayout key={skill.slug}>
                <EntitySkillScoresCard path={PagePath.yourSkillsBase} name={skill.name} overallScore={skill.score} comparisonScore={skill.comparisonScore} urgencyIndication={skill.urgencyIndication} />
              </GridItemLayout>
            );
          })}
      </GridLayout>
    </YourSkillsModalContent>
  );
}
