export enum ScoreComparison {
  None = 'None',
  Company = 'Company',
  Department = 'Department',
  Team = 'Team',
  Role = 'Role',
  FieldOfPractice = 'FieldOfPractice',
  FOPExpectedProficiency = 'FoPExpectedProficiency',
  RoleExpectedProficiency = 'RoleToSkillExpectedProficiency',
}
