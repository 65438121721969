import React, { useEffect, useState } from 'react';
import { IManagePersonCascadingPanelsProps, ManagePersonCascadingPanelIds } from './manage-person-panels.models';
import { FetchType } from '../../../../../enums';
import { Employee } from '../../../../../models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ManagePersonTagsFocusPanel } from './manage-person-tags-panels/manage-person-tags-focus-panel';
import { Permissions } from '../../../../../models/overmind/permissions';
import { TagType } from '../../../../../enums/tag';
import { TagListItem } from '../../../../../models/tag';
import { ManagePersonPermissionsCascadingPanels } from './manage-person-permissions-panels/manage-person-permissions-cascading-panels';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { ManagePersonFocusPanel } from './manage-person-focus-panel/manage-person-focus-panel';
import { CascadingPanel, CascadingPanels, useCascadingPanelsConnector } from '@keplerco/core';

export function ManagePersonCascadingPanels(props: IManagePersonCascadingPanelsProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const { openPanelIds, next, previous, close } = useCascadingPanelsConnector();

  const [person, setPerson] = useState<Employee>();
  const [permissions, setPermissions] = useState<Permissions>();
  const [tags, setTags] = useState<TagListItem[]>();

  async function getPerson(personId: string): Promise<Employee | undefined> {
    const data = await actions.getPerson(personId);
    setPerson(data);
    return data;
  }

  async function getPermissions(personId: string) {
    const data = await actions.getPermissions(personId);
    setPermissions(data);
  }

  async function getTags(personSlug: string | undefined) {
    if (!companyVariables.slug || !personSlug) return;
    const data = await actions.getAssigneeTags({ companySlug: companyVariables.slug, entitySlug: personSlug, tagType: TagType.User });
    setTags(data);
  }

  useEffect(() => {
    next(ManagePersonCascadingPanelIds.Sections);
  }, []);

  useEffect(() => {
    async function getData(): Promise<void> {
      if (!props.personId) return;

      actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });

      const personData = await getPerson(props.personId);
      await getPermissions(props.personId);
      await getTags(personData?.learnerSlug);

      actions.stopLoader(PagePath.administrationPeople);
    }

    getData();
  }, [props.personId]);

  return (
    <CascadingPanels
      openPanelIds={openPanelIds}
      onClosePanel={id => {
        close(id);
        if (id === ManagePersonCascadingPanelIds.Sections || openPanelIds.length <= 1) props.onCancel();
      }}
    >
      <CascadingPanel id={ManagePersonCascadingPanelIds.Sections}>
        <ManagePersonFocusPanel
          person={person}
          permissions={permissions}
          tags={tags}
          onClickSection={section => next(section)}
          onCancel={props.onCancel}
          onSave={async slug => {
            actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
            await getPerson(slug);
            await getPermissions(slug);
            actions.stopLoader(PagePath.administrationPeople);
          }}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonCascadingPanelIds.Permissions}>
        <ManagePersonPermissionsCascadingPanels
          person={person}
          permissions={permissions}
          onSave={async () => {
            if (!person) return;
            actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
            await getPermissions(person.id!);
            actions.stopLoader(PagePath.administrationPeople);
          }}
          onCancel={() => previous()}
        />
      </CascadingPanel>

      <CascadingPanel id={ManagePersonCascadingPanelIds.Tags}>
        <ManagePersonTagsFocusPanel
          setTags={setTags}  
          tags={tags}
          personSlug={person?.learnerSlug}
          onCancel={async () => {
            actions.startLoader({ path: PagePath.administrationPeople, type: FetchType.DialogFetching });
            await getTags(person?.id);
            previous();
            actions.stopLoader(PagePath.administrationPeople);
          }}
        />
      </CascadingPanel>
    </CascadingPanels>
  );
}

