import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { OrganizationLevelType } from '../../enums';
import { PermissionType } from '../../enums/permission-type';
import { PageGuard } from '../guards/page.guard';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { RolesPage } from '../../pages/administration/roles/roles.page';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';
import { GlobalRolePage } from '../../pages/administration/roles/view-role/global-role.page';
import { CompanyRolePage } from '../../pages/administration/roles/view-role/company-role.page';
import { CreateRolePage } from '../../pages/administration/roles/manage-role/create-role.page';
import { EditRolePage } from '../../pages/administration/roles/manage-role/edit-role.page';
import { ImportRolePage } from '../../pages/administration/roles/manage-role/import-role.page';

export function RolesRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.roleManagementBase);

  return (
    <Routes>
      <Route
        path={PagePath.roleManagementRoles}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }]
            }}>
            <RolesPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleManagementCreateRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }]
            }}>
            <CreateRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleManagementEditRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }]
            }}>
            <EditRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleManagementImportRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }]
            }}>
            <ImportRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleManagementCompanyRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }]
            }}>
            <CompanyRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleManagementGlobalRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }]
            }}>
            <GlobalRolePage />
          </PageGuard>
        }
      />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.roleManagementBase}${PagePath.roleManagementRoles}`} />} />
    </Routes>
  );
}