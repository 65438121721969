import React, { Fragment, useState } from 'react';
import { IComparisonToolWidgetProps } from './comparison-tool.models';
import { ChevronIcon, Chip, GridItemLayout, GridLayout, Panel, useMatchScreenWidth } from '@keplerco/core';
import { RoleComparisonIcon } from '../../../pages/analytics/comparison-tools/role-comparison/role-comparison.icon';
import { PeopleComparisonIcon } from '../../../pages/analytics/comparison-tools/people-comparison/people-comparison.icon';
import classNames from 'classnames';
import styles from './comparison-tool.module.css';
import { ComparisonToolPanel } from './comparison-tool.panel';

export function ComparisonToolWidget({ title, primaryCondition, secondaryCondition }: IComparisonToolWidgetProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  const [openPrimaryConditionPanel, setOpenPrimaryConditionPanel] = useState<boolean>(false);
  const [openSecondaryConditionPanel, setOpenSecondaryConditionPanel] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="card">
        <div className="heading4" style={{ marginBottom: 15 }}>
          {title}
        </div>

        <GridLayout columnCount={isMobile ? 1 : 2}>
          <GridItemLayout>
            <button
              className={classNames('card', styles.conditionButton)}
              onClick={() => {
                setOpenPrimaryConditionPanel(true);
                if (!!primaryCondition.onClick) primaryCondition.onClick();
              }}
            >
              <RoleComparisonIcon tone="baby-blue" />
              <div>
                <div className={classNames('heading5', styles.heading)}>
                  {primaryCondition.title}
                  <Chip label={primaryCondition.chipLabel ?? 'Nothing selected'} variant="tiny" backgroundColour={primaryCondition.chipLabel ? 'baby-blue' : 'default'} />
                </div>

                <div className={classNames('caption2', styles.caption)}>{primaryCondition.subtitle}</div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <ChevronIcon tone="primary" size={25} />
              </div>
            </button>
          </GridItemLayout>

          <GridItemLayout>
            <button
              className={classNames('card', styles.conditionButton)}
              disabled={!primaryCondition.chipLabel}
              onClick={() => {
                setOpenSecondaryConditionPanel(true);
                if (!!secondaryCondition.onClick) secondaryCondition.onClick();
              }}
            >
              <PeopleComparisonIcon tone={primaryCondition.chipLabel ? 'grape' : 'default'} />
              <div>
                <div className={classNames('heading5', styles.heading)}>
                  {secondaryCondition.title}
                  <Chip label={secondaryCondition.chipLabel ?? 'Nothing selected'} variant="tiny" backgroundColour={secondaryCondition.chipLabel ? 'grape' : 'default'} />
                </div>

                <div className={classNames('caption2', styles.caption)}>{secondaryCondition.subtitle}</div>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <ChevronIcon tone={primaryCondition.chipLabel ? 'primary' : 'default'} size={25} />
              </div>
            </button>
          </GridItemLayout>
        </GridLayout>
      </div>

      <Panel
        open={openPrimaryConditionPanel}
        onClose={() => {
          setOpenPrimaryConditionPanel(false);
          if (!!primaryCondition.panel.onClose) primaryCondition.panel.onClose();
        }}
      >
        <ComparisonToolPanel
          {...primaryCondition.panel}
          onCancel={() => {
            setOpenPrimaryConditionPanel(false);
            if (!!primaryCondition.panel.onCancel) primaryCondition.panel.onCancel();
          }}
          onAdd={() => {
            setOpenPrimaryConditionPanel(false);
            if (!!primaryCondition.panel.onAdd) primaryCondition.panel.onAdd();
          }}
        />
      </Panel>

      <Panel
        open={openSecondaryConditionPanel}
        onClose={() => {
          setOpenSecondaryConditionPanel(false);
          if (!!secondaryCondition.panel.onClose) secondaryCondition.panel.onClose();
        }}
      >
        <ComparisonToolPanel
          {...secondaryCondition.panel}
          onCancel={() => {
            setOpenSecondaryConditionPanel(false);
            if (!!secondaryCondition.panel.onCancel) secondaryCondition.panel.onCancel();
          }}
          onAdd={() => {
            setOpenSecondaryConditionPanel(false);
            if (!!secondaryCondition.panel.onAdd) secondaryCondition.panel.onAdd();
          }}
        />
      </Panel>
    </Fragment>
  );
}
