import { Context } from '../..';
import * as base from '../base';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import {
  AssignCompanyEntityRequest,
  CompanyEntityAssigneeListResponse,
  CompanyEntityResponse,
  CompanyEntityListResponse,
  RelateCompanyEntityRequest,
  CreateCompanyEntityRequest,
  UpdateCompanyEntityRequest,
  RelateCompanyRoleSkillExpectedLevelRequest,
  ImportCompanyEntityRequest,
} from '../../../models/overmind/company-entity';
import { CompanyEntitySearchParams, CompanyRoleAssigneesSearchParams } from '../../../models/overmind/search-params';

const CONTROLLER = `companyrole`;

/**
 * Search for Roles as a User within the User's Company.
 */
export async function getUserCompanyRoles(context: Context, payload: CompanyEntitySearchParams): Promise<CompanyEntityListResponse | undefined> {
  const url: string = base.url(CONTROLLER, `get-roles${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Roles as a User with Permissions or a User with System Admin within any Company.
 */
export async function getCompanyRoles(context: Context, payload: CompanyEntitySearchParams): Promise<CompanyEntityListResponse | undefined> {
  const url: string = base.url(CONTROLLER, `get-roles`, `${payload.companySlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityListResponse> = await base.request(request);
  return response.data;
}

/**
 * Request a Role as a User from within the User's Company.
 * If the Role is not saved to the User's Company this request will result in a 404.
 */
export async function getUserCompanyRole(context: Context, payload: { roleSlug: string }): Promise<CompanyEntityResponse | undefined> {
  const url: string = base.url('companyrole', 'get-role', payload.roleSlug);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityResponse> = await base.request(request);
  return response.data;
}

/**
 * Request a Role as a User with Permissions or a User with System Admin withing any Company.
 */
export async function getCompanyRole(context: Context, payload: { companySlug: string; roleSlug: string }): Promise<CompanyEntityResponse | undefined> {
  const url: string = base.url('companyrole', 'get-role', payload.companySlug, payload.roleSlug);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityResponse> = await base.request(request);
  return response.data;
}

export async function createCompanyRole(context: Context, payload: CreateCompanyEntityRequest): Promise<string | undefined> {
  const url: string = base.url('companyrole', 'create-role', payload.companySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<string> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error creating this role',
      message: `There was an error creating this role`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: crypto.randomUUID(),
      active: true,
    });
  }

  return response.data;
}

export async function updateCompanyRole(context: Context, body: UpdateCompanyEntityRequest): Promise<void> {
  const url: string = base.url('companyrole', 'update-role', body.companySlug, body.entitySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(body) };
  const response: base.IResponse<void> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      title: 'There was an error updating this role',
      message: `There was an error updating this role`,
      priority: NotificationPriority.Toast,
      type: NotificationType.Error,
      id: crypto.randomUUID(),
      active: true,
    });
  }
}

export async function importCompanyRole(context: Context, payload: ImportCompanyEntityRequest): Promise<base.IResponse<string>> {
  const url: string = base.url(CONTROLLER, `import`, payload.companySlug, payload.entitySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'POST' };
  const response: base.IResponse<string> = await base.request(request);
  return response;
}

export async function assignCompanyRole(context: Context, body: AssignCompanyEntityRequest): Promise<void> {
  const url: string = base.url('companyrole', 'assign-role', context.state.companyVariables.slug!);
  const pulledRoleAssignment: AssignCompanyEntityRequest = { entitySlug: body.entitySlug, assignees: body.assignees };
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(pulledRoleAssignment) };
  await base.request(request);
}

export async function unassignCompanyRole(context: Context, body: AssignCompanyEntityRequest): Promise<void> {
  const url: string = base.url('companyrole', 'unassign-role', context.state.companyVariables.slug!);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(body) };
  await base.request(request);
}

export async function relateCompanyRole(context: Context, payload: RelateCompanyEntityRequest): Promise<void> {
  const url: string = base.url('companyrole', 'relate', `${payload.companySlug}`);
  delete (payload as any).companySlug;
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}

export async function unrelateCompanyRole(context: Context, payload: RelateCompanyEntityRequest): Promise<void> {
  const url: string = base.url('companyrole', 'un-relate', payload.companySlug);
  delete (payload as any).companySlug;
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}

export async function relateCompanyRoleSkillExpectedLevel(context: Context, payload: RelateCompanyRoleSkillExpectedLevelRequest): Promise<void> {
  const companySlug = context.state.companyVariables.slug!;
  const url: string = base.url('companyrole', 'relate', 'expected-level', companySlug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(payload) };
  await base.request(request);
}

/**
 * Search for Roles as a User within the User's Company.
 */
export async function getUserCompanyRoleAssignees(context: Context, payload: CompanyRoleAssigneesSearchParams): Promise<CompanyEntityAssigneeListResponse | undefined> {
  const url: string = base.url(CONTROLLER, `get-role-assignees`, `${payload.roleSlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityAssigneeListResponse> = await base.request(request);
  return response.data;
}

/**
 * Search for Role Assignees as a User with Permissions or a User with System Admin within any Company.
 */
export async function getCompanyRoleAssignees(context: Context, payload: CompanyRoleAssigneesSearchParams): Promise<CompanyEntityAssigneeListResponse | undefined> {
  const url: string = base.url(CONTROLLER, `get-role-assignees`, `${payload.companySlug}/${payload.roleSlug}${base.params(payload)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<CompanyEntityAssigneeListResponse> = await base.request(request);
  return response.data;
}
