import React, { Fragment } from 'react';
import { IPersonDetailsProps } from './person-details.models';
import { AvatarIcon, KeplerPoints, useMatchScreenWidth } from '@keplerco/core';
import styles from './person-details.module.css';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { PDFReport } from '../../../../../components/general/pdf-report/pdf-report';
import { EmailIcon } from '../../../../../design/icons/email.icon';
import classNames from 'classnames';

function generateLastName(firstName: string, lastName: string): string {
  const totalLength = (firstName.length) + (lastName.length);
  return totalLength > 15 ? lastName.charAt(0) : lastName;
}

export function PersonDetails({ firstName, lastName, jobTitle, department, email, path, keplerPoints }: IPersonDetailsProps): JSX.Element {

  const isMobile = useMatchScreenWidth('mobile');

  return (
    <div id="employee-detail-section" className={styles.container}>
      <div className={styles.person}>
        <div className={styles.avatar}>
          <AvatarIcon name={{ firstName, lastName }} />
        </div>

        <div>
          {path === PagePath.dashboard && !isMobile ? (
            <h1 className="heading1">
              {firstName} {generateLastName(firstName ?? '', lastName ?? '')}
            </h1>
          ) : (
            <h1 className="heading1">
              {firstName} {lastName}
            </h1>
          )}

          {path === PagePath.dashboard && (
            <Fragment>
              {!!jobTitle && (
                <div className="caption1" style={{ marginTop: 5, whiteSpace: 'nowrap' }}>{`Job title: ${jobTitle}`}</div>
              )}

              {!!department && (
                <div className="caption1" style={{ whiteSpace: 'nowrap' }}>
                  {`Department: ${department}`}
                </div>
              )}

              {!!email && (
                <div className="caption1" style={{ whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', gap: 3 }}>
                  <EmailIcon />
                  {email}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <div className={styles.actions}>
        {path === PagePath.dashboard && (
          <PDFReport path={PagePath.dashboard} />
        )}

        {!!keplerPoints && (
          <div className={classNames('card', styles.keplerPoints)}>
            {!isMobile && (
              <div className="heading4" style={{ whiteSpace: 'nowrap' }}>Kepler Points: </div>
            )}

            <h2 className="heading3">
              {isMobile && 'Kepler Points: '}<KeplerPoints trimWhiteSpace points={keplerPoints} />
            </h2>
          </div>
        )}
      </div>
    </div>
  );
}

