interface SkillLevelExplanation {
  title: string;
  description: string;
}

const skillLevelExplanations: SkillLevelExplanation[] = [
  {
    title: 'Level 1',
    description: 'Works under close direction. Uses little discretion in attending to enquiries. Is expected to seek guidance in unexpected situations.',
  },
  {
    title: 'Level 2',
    description: 'Works under routine direction. Uses limited discretion in resolving issues or enquiries. Determines when to seek guidance in unexpected situations. Plans own work within short time horizons.',
  },
  {
    title: 'Level 3',
    description:
      'Works under general direction. Receives specific direction, accepts guidance and has work reviewed at agreed milestones. Uses discretion in identifying and responding to complex issues related to own assignments. Determines when issues should be escalated to a higher level. Plans and monitors own work (and that of others where applicable) competently within limited deadlines.',
  },
  {
    title: 'Level 4',
    description:
      'Works under general direction within a clear framework of accountability. Exercises substantial personal responsibility and autonomy. Uses substantial discretion in identifying and responding to complex issues and assignments as they relate to the deliverable/scope of work. Escalates when issues fall outside their framework of accountability. Plans, schedules and monitors work to meet given objectives and processes to time and quality targets.',
  },
  {
    title: 'Level 5',
    description:
      'Works under broad direction. Work is often self-initiated. Is fully responsible for meeting allocated technical and/or group objectives. Analyses, designs, plans, executes and evaluates work to time, cost and quality targets. Establishes milestones and has a significant role in the assignment of tasks and/or responsibilities.',
  },
  {
    title: 'Level 6',
    description: 'Has defined authority and accountability for actions and decisions within a significant area of work, including technical, financial and quality aspects. Establishes organisational objectives and assigns responsibilities.',
  },
  {
    title: 'Level 7',
    description: 'At the highest organisational level, has authority over all aspects of a significant area of work, including policy formation and application. Is fully accountable for actions taken and decisions made, both by self and others to whom responsibilities have been assigned.',
  },
];

export function getSkillLevelExplanations(maxLevel: number): SkillLevelExplanation[] {
  return maxLevel >= skillLevelExplanations.length ? skillLevelExplanations : skillLevelExplanations.slice(0, maxLevel);
}
