import React from 'react';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderContainer, SkeletonLoaderRow, SkeletonLoaderColumn } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';

export function LearnerAssessmentsSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="25" width="160" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="55" width='320' />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn flex={4}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="50" />
        </SkeletonLoaderColumn>
        <SkeletonLoaderColumn flex={3} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="100vh" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
