import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function SkillsSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ marginTop: 25, marginBottom: 10 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="20px" width="220px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 20 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="55px" width="220px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ alignItems: 'center' }}>
        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="30px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="15px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={7} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 35 }}>
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="42px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="42px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="42px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={3} />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="110px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="110px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="110px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="110px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="110px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
