import styled from 'styled-components';

export const CompaniesPageHeaderContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
  border-bottom: 1px solid var(--borders);
  padding-bottom: 30px;
`;

export const CompaniesActionsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 580px) {
    width: 100%;
  }
`;

export const CompaniesActionsContainer = styled.div`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
    gap: 15px;
  }
`;


