import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { AssessmentsOverviewPage } from '../../pages/assessments/assessments-overview/assessments-overview.page';
import { CreateSkillAssessmentsCMSPage } from '../../pages/assessments/create-skills-assessment/create-skill-assessment.cms.layout';
import { SkillAssessmentAssigneesPage } from '../../pages/assessments/skill-assessment-assignees/skill-assessment-assignees.page';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';
import { AssessmentsWizardPage } from '../../pages/assessments/wizard/assessments-wizard.page';
import { AssessmentsWizardPeerPage } from '../../pages/assessments/wizard/assessments-wizard-peer.page';
import { AssessmentsWizardQuestionnairePage } from '../../pages/assessments/wizard/assessments-wizard-questionnaire.page';
import { AssessmentsWizardFOPPage } from '../../pages/assessments/wizard/assessments-wizard-fop.page';

export function AssessmentsRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.assessmentsBase);

  return (
    <Routes>
      {/* TODO: cleanup routing */}
      <Route path={PagePath.assessmentsOverview} element={<AssessmentsOverviewPage />} />
      <Route path={PagePath.assessmentsCreate} element={<CreateSkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentsUpdate} element={<CreateSkillAssessmentsCMSPage />} />
      <Route path={PagePath.assessmentsAssignees} element={<SkillAssessmentAssigneesPage />} />

      <Route path={PagePath.assessmentsWizard} element={<AssessmentsWizardPage />} />
      <Route path={PagePath.assessmentsWizardPeer} element={<AssessmentsWizardPeerPage />} />
      <Route path={PagePath.assessmentsWizardQuestionnaire} element={<AssessmentsWizardQuestionnairePage />} />
      <Route path={PagePath.assessmentsWizardFOP} element={<AssessmentsWizardFOPPage />} />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.assessmentsBase}${PagePath.assessmentsOverview}`} />} />
    </Routes>
  );
}

