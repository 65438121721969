import React, { useEffect, useState } from 'react';
import * as iframePlayerModalStyles from './iframe-player-modal.styles';
import classNames from 'classnames';
import { IFramePlayerModalPlaylistItem, IFramePlayerModalProps } from './iframe-player-modal.models';
import { LockIcon } from '../../../cards/lesson-card/lock.icon';
import { FullscreenModal } from '../fullscreen-modal/fullscreen-modal';
import { generateIFrameUrl } from '../iframe.helpers';
import { IFrame, IFrameLoader, IFrameLoaderContent } from '../iframe.styles';
import { StartIcon } from '../../../../design/icons/start.icon';
import { CompletionStatus } from '../../../../enums';
import { Anchor, LoaderIcon, colourString, useMediaQuery } from '@keplerco/core';
import { DoubleChevronIcon } from '../../../../design/icons/double-chevron.icon';

function generateButtonText(completionStatus: CompletionStatus): string {
  if (completionStatus === CompletionStatus.Completed) return 'Review Lesson'; // TODO: Viewed
  return completionStatus === CompletionStatus.InProgress ? 'Resume' : 'Start';
}

function generateCompletionStatusText(completionStatus: CompletionStatus): string {
  return completionStatus === CompletionStatus.Completed ? 'Completed' : completionStatus === CompletionStatus.InProgress ? 'In progress' : 'Upcoming';
}

export function IFramePlayerModal(props: IFramePlayerModalProps): JSX.Element {
  const { show, superTitle, onClose, iFrameUrl } = props;

  const isTouch = useMediaQuery('screen and (pointer: coarse)');

  const [loading, setLoading] = useState<boolean>(true);
  const [playlistCollapsed, setPlaylistCollapsed] = useState<boolean>(false);
  const [iFrameExpanded, setIFrameExpanded] = useState<boolean>(false);

  const [currentPlaylistItem, setCurrentPlaylistItem] = useState<IFramePlayerModalPlaylistItem>();
  const [nextPlaylistItem, setNextPlaylistItem] = useState<IFramePlayerModalPlaylistItem>();
  const [playlist, setPlaylist] = useState<IFramePlayerModalPlaylistItem[]>([]);

  function setPlaylistItems(playlistItem: IFramePlayerModalPlaylistItem | undefined, playlist: IFramePlayerModalPlaylistItem[]) {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);

    setCurrentPlaylistItem(playlistItem);
    if (!playlistItem) return setNextPlaylistItem(undefined);

    const currentPlaylistItemIndex = playlist.findIndex(temp => temp.lessonUrl === playlistItem.lessonUrl);
    if (currentPlaylistItemIndex === -1) return setNextPlaylistItem(undefined);

    const nextPlaylistItem = playlist[currentPlaylistItemIndex + 1];
    setNextPlaylistItem(nextPlaylistItem?.locked ? undefined : nextPlaylistItem);
  }

  useEffect(() => {
    const defaultPlaylistItem = props.playlist.find(playlistItem => playlistItem.lessonUrl === iFrameUrl);
    setPlaylistItems(defaultPlaylistItem, props.playlist);
    setPlaylist(props.playlist);
  }, [props]);

  return !currentPlaylistItem ? (
    <React.Fragment />
  ) : (
    <FullscreenModal show={show} superTitle={superTitle} title={currentPlaylistItem.name} onClose={onClose} onNext={!nextPlaylistItem ? undefined : () => setPlaylistItems(nextPlaylistItem, playlist)} onReturn={!iFrameExpanded ? undefined : () => setIFrameExpanded(false)}>
      <iframePlayerModalStyles.IFramePlayerContainer>
        {isTouch ? (
          <React.Fragment>
            {iFrameExpanded ? (
              <iframePlayerModalStyles.IFrameLayoutContainer className={classNames({ playlistCollapsed })}>
                <React.Fragment>
                  {loading ? (
                    <IFrameLoader>
                      <IFrameLoaderContent>
                        <LoaderIcon />
                        <h6 className="subtitle">Loading your learning content</h6>
                      </IFrameLoaderContent>
                    </IFrameLoader>
                  ) : (
                    <IFrame src={generateIFrameUrl(currentPlaylistItem.lessonUrl)} frameBorder={0}></IFrame>
                  )}
                </React.Fragment>
              </iframePlayerModalStyles.IFrameLayoutContainer>
            ) : (
              <iframePlayerModalStyles.IFramePlayerThumbnailContainer onClick={() => setIFrameExpanded(true)}>
                {!!currentPlaylistItem.thumbnailUrl && (
                  <iframePlayerModalStyles.IFramePlayerThumbnail>
                    <img src={currentPlaylistItem.thumbnailUrl} alt="Thumbnail" />
                  </iframePlayerModalStyles.IFramePlayerThumbnail>
                )}

                <iframePlayerModalStyles.IFramePlayerThumbnailArrowIconContainer>
                  <StartIcon size={75} tone="contrast-text" />
                </iframePlayerModalStyles.IFramePlayerThumbnailArrowIconContainer>

                <iframePlayerModalStyles.IFramePlayerThumbnailTextContainer>{generateButtonText(currentPlaylistItem.completionStatus)}</iframePlayerModalStyles.IFramePlayerThumbnailTextContainer>
              </iframePlayerModalStyles.IFramePlayerThumbnailContainer>
            )}
          </React.Fragment>
        ) : (
          <iframePlayerModalStyles.IFrameLayoutContainer className={classNames({ playlistCollapsed })}>
            <React.Fragment>
              {loading ? (
                <IFrameLoader>
                  <IFrameLoaderContent>
                    <LoaderIcon />
                    <h6 className="subtitle">Loading your learning content</h6>
                  </IFrameLoaderContent>
                </IFrameLoader>
              ) : (
                <IFrame src={generateIFrameUrl(currentPlaylistItem.lessonUrl)} frameBorder={0}></IFrame>
              )}
            </React.Fragment>
          </iframePlayerModalStyles.IFrameLayoutContainer>
        )}

        {!isTouch && (
          <iframePlayerModalStyles.IFramePlayerPlaylistToggleButton type="button" className={classNames({ playlistCollapsed })} onClick={() => setPlaylistCollapsed(currentState => !currentState)}>
            <DoubleChevronIcon tone="text" size={10} rotation={playlistCollapsed ? 1 : 3} />
          </iframePlayerModalStyles.IFramePlayerPlaylistToggleButton>
        )}

        {(!isTouch || !iFrameExpanded) && (
          <iframePlayerModalStyles.IFramePlayerPlaylist className={classNames({ playlistCollapsed }, 'playlist')}>
            {playlist.map(playlistItem => (
              <iframePlayerModalStyles.IFramePlayerPlaylistItem
                className="card"
                key={playlistItem.name}
                onClick={() =>
                  setPlaylist(currentState => {
                    const nextState: IFramePlayerModalPlaylistItem[] = structuredClone(currentState);
                    const temp = nextState.find(temp => temp.lessonUrl === playlistItem.lessonUrl);
                    if (!!temp) temp.expanded = !temp.expanded;
                    return nextState;
                  })
                }
              >
                <iframePlayerModalStyles.IFramePlayerPlaylistCardHeader>
                  <span className="caption2" style={{ color: colourString('text_1') }}>
                    {playlistItem.type}&nbsp;
                  </span>

                  <span className="caption2" style={{ color: colourString('text_1') }}>
                    {generateCompletionStatusText(playlistItem.completionStatus)}
                  </span>
                </iframePlayerModalStyles.IFramePlayerPlaylistCardHeader>

                <iframePlayerModalStyles.IFramePlayerPlaylistCardName className="heading5">{playlistItem.name}</iframePlayerModalStyles.IFramePlayerPlaylistCardName>

                <iframePlayerModalStyles.IFramePlayerPlaylistCardDescription className="body2">{playlistItem.description}</iframePlayerModalStyles.IFramePlayerPlaylistCardDescription>

                {playlistItem.expanded && (
                  <React.Fragment>
                    {playlistItem.locked ? (
                      <iframePlayerModalStyles.IFramePlayerPlaylistCardFooter>
                        <iframePlayerModalStyles.IFramePlayerPlaylistCardFooterBody className="body2">Locked Lesson</iframePlayerModalStyles.IFramePlayerPlaylistCardFooterBody>
                        <LockIcon tone="default" size={15} />
                      </iframePlayerModalStyles.IFramePlayerPlaylistCardFooter>
                    ) : (
                      <iframePlayerModalStyles.IFramePlayerPlaylistCardFooter>
                        <Anchor
                          onClick={event => {
                            event.stopPropagation();
                            setPlaylistItems(playlistItem, playlist);
                          }}
                        >
                          {generateButtonText(playlistItem.completionStatus)}
                        </Anchor>
                      </iframePlayerModalStyles.IFramePlayerPlaylistCardFooter>
                    )}
                  </React.Fragment>
                )}
              </iframePlayerModalStyles.IFramePlayerPlaylistItem>
            ))}
          </iframePlayerModalStyles.IFramePlayerPlaylist>
        )}
      </iframePlayerModalStyles.IFramePlayerContainer>
    </FullscreenModal>
  );
}

