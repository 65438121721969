import { computeDateRanges } from '../../components/inputs/daterange/daterange.data';
import { DaterangeDateOptionTypes, DaterangeOutput } from '../../components/inputs/daterange/daterange.models';
import { KeplerState } from '../../models/kepler-state';
import { GlobalDateRangeFilter } from '../actions/functions/daterange';

export function derivedDateRange(state: KeplerState): GlobalDateRangeFilter | undefined {
  return deriveDateRange(state.dateRangeOutput);
}

export function deriveDateRange(dateRangeOutput: DaterangeOutput): GlobalDateRangeFilter | undefined {
  if (dateRangeOutput.type === DaterangeDateOptionTypes.CustomRange) {
    const range = dateRangeOutput.daterangeIfApplicable ?? [];

    return {
      from: range[0],
      to: range[1],
      hash: `${range[0]?.toISOString()}-${range[1]?.toISOString()}`,
    };
  }

  const ranges = computeDateRanges(new Date());

  if (dateRangeOutput.type === DaterangeDateOptionTypes.UpToToday) {
    return {
      from: void 0,
      to: ranges.today.date,
      hash: `${ranges.today.date.toISOString()}`,
    };
  } else if (dateRangeOutput.type === DaterangeDateOptionTypes.ThisWeek) {
    return {
      from: ranges.thisWeek.startDate,
      to: ranges.thisWeek.endDate,
      hash: `${ranges.thisWeek.startDate.toISOString()}-${ranges.thisWeek.endDate.toISOString()}`,
    };
  } else if (dateRangeOutput.type === DaterangeDateOptionTypes.ThisMonth) {
    return {
      from: ranges.thisMonth.startDate,
      to: ranges.thisMonth.endDate,
      hash: `${ranges.thisMonth.startDate.toISOString()}-${ranges.thisMonth.endDate.toISOString()}`,
    };
  } else if (dateRangeOutput.type === DaterangeDateOptionTypes.YearToDate) {
    return {
      from: ranges.yearToDate.startDate,
      to: ranges.yearToDate.endDate,
      hash: `${ranges.yearToDate.startDate.toISOString()}-${ranges.yearToDate.endDate.toISOString()}`,
    };
  } else if (dateRangeOutput.type === DaterangeDateOptionTypes.ThisYear) {
    return {
      from: ranges.thisYear.startDate,
      to: ranges.thisYear.endDate,
      hash: `${ranges.thisYear.startDate.toISOString()}-${ranges.thisYear.endDate.toISOString()}`,
    };
  }
}
