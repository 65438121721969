import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function ReportManagementCMSSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="85" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="70" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="70" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="70" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="70" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="70" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
