import React, { useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { DailyActivityWidget, LeaderboardWidget } from '../../../widgets';
import { AnalyticsGridSection, AnalyticsPageHeaderContainer } from '../analytics.styles';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { PageHeader } from '@keplerco/core';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';

export function AnalyticsEngagementPage(): JSX.Element {
  const actions = useAppActions();

  const { dateRangeOutput } = useAppState();
  const [entity, setEntity] = useState<PermissionsEntity>();

  return (
    <div className="pageWrapper wrapper">
      <AnalyticsPageHeaderContainer>
        <PageHeader
          breadcrumbs={[
            { name: 'Analytics', url: PagePath.analyticsBase },
            { name: 'Engagement', url: window.location.pathname },
          ]}
          title="Engagement"
          type="actions"
        >
          <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
            <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

            <Daterange
              defaultValue={dateRangeOutput}
              onDaterangeChange={result => {
                actions.setGlobalDateRange(result);
              }}
            />
          </div>
        </PageHeader>
      </AnalyticsPageHeaderContainer>

      <AnalyticsGridSection>
        <div className="full-span">
          <DailyActivityWidget entity={entity} />
        </div>
      </AnalyticsGridSection>

      <AnalyticsGridSection>
        <div className="full-span">
          <CourseEngagementWidget entity={entity} isLearner={false} />
        </div>
      </AnalyticsGridSection>

      <React.Fragment>
        <AnalyticsGridSection>
          <div className="start-2">
            <LeaderboardWidget entity={entity} />
          </div>
        </AnalyticsGridSection>
      </React.Fragment>
    </div>
  );
}

