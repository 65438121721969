import React, { useEffect, useState } from 'react';
import { SearchList } from '../../../../components/lists/search-list';
import { ListItem } from '../../../../components/lists/list-item';
import { useAppActions, useAppState } from '../../../../overmind';
import { ActivationStatus, SortField } from '../../../../enums';
import { IPeopleComparisonListProps, Person } from './people-comparison.models';
import { PagingSearchParams, SearchingSearchParams } from '../../../../models/overmind/search-params';
import { NotificationPriority, NotificationType } from '../../../../notifications/notifications.models';
import { useMatchScreenWidth } from '@keplerco/core';

export function PeopleComparisonList({ pendingPeopleSlugs, setPendingPeopleSlugs }: IPeopleComparisonListProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchingSearchParams & PagingSearchParams>({
    search: undefined,
    page: 1,
    pageSize: 5,
  });
  const [people, setPeople] = useState<Person[]>();
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await actions.searchPeople({
        companySlug: companyVariables.slug!,
        search: searchParams.search,
        page: searchParams.page,
        pageSize: searchParams.pageSize,
        sortField: SortField.Name,
      });
      setPeople(
        response?.employees.map(employee => ({
          name: `${employee.firstName} ${employee.lastName}`,
          slug: employee.learnerSlug ?? employee.id,
          department: employee.department?.name ?? 'No department',
          email: employee.email,
          archived: employee.activationStatus === ActivationStatus.Archived,
        }))
      );
      setTotalPages(response?.totalPages ?? 0);

      setLoading(false);
    }

    getData();
  }, [searchParams]);

  return (
    <div className="card">
      <SearchList
        loading={loading}
        onInput={search => setSearchParams(currentState => ({ ...currentState, search }))}
        paging={{
          activePageNumber: searchParams.page ?? 1,
          pageCount: totalPages,
          onPageChange: page => setSearchParams(currentState => ({ ...currentState, page })),
        }}
      >
        {people?.map(person => {
          const selected = pendingPeopleSlugs.includes(person.slug);
          return (
            <ListItem
              key={person.slug}
              selected={selected}
              disabled={person.archived}
              onClick={() => {
                if (!selected && pendingPeopleSlugs.length === 5) {
                  actions.addNotification({
                    active: true,
                    id: crypto.randomUUID(),
                    type: NotificationType.Error,
                    priority: NotificationPriority.Toast,
                    toastIncludeIcon: true,
                    title: 'Only 5 people can be selected',
                    message: 'Please deselect a selected person in order to select this person',
                  });
                  return;
                }

                setPendingPeopleSlugs(selected ? pendingPeopleSlugs.filter(slug => slug !== person.slug) : [...pendingPeopleSlugs, person.slug]);
              }}
            >
              <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr' }}>
                <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                  {person.name}
                </div>
                <div className="caption2">{person.department}</div>
                <div className="caption2">{person.email}</div>
              </div>
            </ListItem>
          );
        })}
      </SearchList>
    </div>
  );
}
