import { DataPoint } from '../../../components/charts/recharts.models';
import { EntityScores } from '../../../models/overmind/analytics';
import { Score } from '../../../models/view/score';
import { HeatmapType } from './heatmap.models';

function getAverageLabel(heatmapType: HeatmapType): string {
  if (heatmapType === 'Department') return 'Company average';
  if (heatmapType === 'Team') return 'Department average';
  return 'Team average';
}

function formatScore(score: Score | undefined, useLevels: boolean): number | string {
  return useLevels ? score?.level ?? 'Not assessed' : score?.percentage ?? 'Not assessed';
}

export function transformScores(scores: EntityScores[], useLevels: boolean, heatmapType: HeatmapType, isAverageScore?: boolean): DataPoint[] {
  return scores.map(score => {
    const dataPoint: DataPoint = { [`${heatmapType}`]: isAverageScore ? getAverageLabel(heatmapType) : score.name, slug: score.slug };
    score.scores.forEach(score => {
      dataPoint[score.name] = formatScore(score.score, useLevels);
    });
    return dataPoint;
  });
}

