import React, { useEffect, useState } from 'react';
import { BottomButtonWrapper, ButtonTag, ButtonTagWrapper, ButtonWrapperInner } from '../../../../design/layout.styles';
import { CompletionStatus, FetchType } from '../../../../enums';
import { LearnerCareerPathSkillSet } from '../../../../models/view/learner-career-path-skill-set';
import { NavLink, useNavigate } from 'react-router-dom';
import { RatingLandingCardsWrapper, RatingPageHeader, RatingPageLayout } from '../rating.styles';
import { useAppActions } from '../../../../overmind';
import classNames from 'classnames';
import { RatingCardTick, RatingLandingCardsLayout } from './rating-career-paths.styles';
import { RatingCareerPathsLandingSkeleton } from './rating-career-paths-landing.skeleton';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Button, Chip, ChipWrapper, PageHeader } from '@keplerco/core';
import { getSubdomain } from '../../../../library/helpers/get-subdomain';

export function RatingCareerPathsLandingPage(): JSX.Element {
  const actions = useAppActions();
  const navigate = useNavigate();
  const [careerPaths, setPaths] = useState<LearnerCareerPathSkillSet[]>();

  useEffect(() => {
    actions.startLoader({ path: PagePath.analysisRatingCareerPaths, type: FetchType.PageFetching });

    actions.getLearnerCareerPathSkillSets().then(paths => {
      setPaths(paths);
      actions.stopLoader(PagePath.analysisRatingCareerPaths);
    });
  }, []);

  const allAreComplete = careerPaths?.every(u => u.completionStatus === CompletionStatus.Completed);
  const subdomain = getSubdomain();

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.analysisRatingCareerPaths} skeletonLoader={<RatingCareerPathsLandingSkeleton />}>
        <RatingPageLayout className="wrapper pageWrapper">
          <RatingPageHeader>
            <PageHeader
              breadcrumbs={[
                { name: 'Analysis', url: PagePath.analysisBase },
                { name: subdomain.includes('lpi') ? 'Capability Map' : 'Career Paths', url: `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}` },
              ]}
              title={subdomain.includes('lpi') ? 'Capability Map' : 'Career Paths'}
              subtitle={`This assessment will determine which skills you need to focus on${subdomain.includes('lpi') ? '' : ', and will populate your custom learning journey'}`}
            />
          </RatingPageHeader>

          {!!careerPaths && (
            <RatingLandingCardsWrapper>
              <RatingLandingCardsLayout>
                {careerPaths.map(path => {
                  const done = path.completionStatus === CompletionStatus.Completed;

                  return (
                    <NavLink to={done ? `${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}` : `${PagePath.analysisBase}${PagePath.analysisRatingCareerPath.replace(':ratingSlug', path.skillSetSlug)}`} key={path.skillSetSlug}>
                      <div className={classNames('card', { done })}>
                        {done && (
                          <RatingCardTick>
                            <svg viewBox="0 0 17 13" fill="none">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.0047 1.34621L7.14868 8.13918L4.01208 5.01159C3.38415 4.38517 2.21844 4.38517 1.5034 5.01159C1.33329 5.17261 1.1978 5.36662 1.1052 5.58177C1.0126 5.79692 0.964844 6.02869 0.964844 6.26292C0.964844 6.49715 1.0126 6.72893 1.1052 6.94408C1.1978 7.15923 1.33329 7.35324 1.5034 7.51426L5.89284 11.8947C6.21719 12.2096 6.65151 12.3858 7.10361 12.3858C7.55572 12.3858 7.99003 12.2096 8.31439 11.8947L16.4263 3.80382C16.5964 3.6428 16.7319 3.44878 16.8245 3.23363C16.9171 3.01848 16.9648 2.78671 16.9648 2.55248C16.9648 2.31825 16.9171 2.08648 16.8245 1.87133C16.7319 1.65618 16.5964 1.46216 16.4263 1.30114C15.7548 0.719792 14.6342 0.719792 14.0077 1.34621"
                                fill="var(--contrast-text)"
                              />
                            </svg>
                          </RatingCardTick>
                        )}

                        <ChipWrapper>
                          <Chip label={`${path.skillCount} Skills`} variant="tiny" backgroundColour="grc_2" colour="grc_14" />
                          {done && <Chip label="Done" variant="tiny" backgroundColour="apple" colour="grc_14" />}
                        </ChipWrapper>

                        <h6 className="subtitle">{path.skillSetName}</h6>
                        <p className="body2">
                          {path.skillSetDescription ?? (
                            <React.Fragment>
                              No skill description was found in the api for <strong>{path.skillSetName}</strong>. Don't worry, you can still rate your abilities in this skill set
                            </React.Fragment>
                          )}
                        </p>

                        {!done && <span className="link">Rate your skills</span>}
                      </div>
                    </NavLink>
                  );
                })}
              </RatingLandingCardsLayout>
            </RatingLandingCardsWrapper>
          )}
        </RatingPageLayout>
      </PageLoaderLayer>

      <BottomButtonWrapper>
        <ButtonWrapperInner>
          <Anchor arrow reverse block onClick={() => navigate(PagePath.analysisBase)}>
            Back
          </Anchor>

          <ButtonTagWrapper>
            {!allAreComplete && <ButtonTag>To continue, rate your skills</ButtonTag>}

            <Button
              type="button"
              disabled={!allAreComplete}
              onClick={async () => {
                navigate(PagePath.analysisBase);
              }}
            >
              Continue
            </Button>
          </ButtonTagWrapper>
        </ButtonWrapperInner>
      </BottomButtonWrapper>
    </React.Fragment>
  );
}
