import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function ManagePersonPermissionsFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow style={{ marginBottom: 50 }}>
        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="90px" width="90px" borderRadius="50%" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={9}>
          <SkeletonLoaderRow style={{ marginBottom: 10 }}>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="35px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn />
          </SkeletonLoaderRow>

          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="20px" />
            </SkeletonLoaderColumn>

            <SkeletonLoaderColumn />
          </SkeletonLoaderRow>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div>
        <SkeletonLoaderRow style={{ marginBottom: 10 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="75px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 10 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="75px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 10 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="75px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 10 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="75px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="75px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      </div>
    </div>
  );
}
