import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CompletionStatus, FetchType } from '../../../enums';
import { Seniority } from '../../../enums/seniority';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { CareerPath } from '../../../models/view/career-path';
import { LearnerCareerPath } from '../../../models/view/learner-career-path';
import { useAppActions, useAppState } from '../../../overmind';
import { AnalysisPageHeader, AnalysisPageWrapper } from '../analysis.styles';
import { CareerPathCard, CareerPathCardBody, CareerPathCardButton, CareerPathCardHeader, CareerPathCardIconColumn, CareerPathCardTextColumn, CareerPathRemoveButton, CareerPathIcon } from './career-path.styles';
import { ICareerPathFormValues } from './career-path.models';
import { CareerPathValidator } from './career-path.helpers';
import { CareerPathSkeleton } from './career-path.skeleton';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { AddTwoToneIcon, Anchor, Button, Chip, ChipWrapper, Dropdown, FormControl, FormGroup, IPillButton, PageHeader, PillButtonGroup, RequiredValidator, ThemeColours, capitalCase, colourString } from '@keplerco/core';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { BottomButtonWrapper, ButtonWrapperInner } from '../../../design/layout.styles';

function generateLPIText(subdomain: string): string {
  return subdomain.includes('lpi') ? 'role' : 'career path';
}

export function CareerPathPage(): JSX.Element {
  const navigate = useNavigate();
  const buttonRef = useRef<HTMLDivElement>(null);
  const actions = useAppActions();
  const [formValues, setFormValues] = useState<ICareerPathFormValues[]>([]);
  const [lastButtonRef, setLastButtonRef] = useState<HTMLDivElement | null>(null);

  const { skillAssessmentConfig } = useAppState();

  const [listCareerPaths, setListCareerPaths] = useState<CareerPath[]>();

  const { control, trigger, setValue, reset } = useForm({ mode: 'all', reValidateMode: 'onChange' });

  const subdomain = getSubdomain();

  useEffect(() => {
    if (lastButtonRef) {
      lastButtonRef.scrollIntoView({ behavior: 'smooth' });
    }
  }, [lastButtonRef]);

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisCareerPaths, type: FetchType.PageFetching });

      const skillAssessmentData = await actions.getCareerPaths();
      setListCareerPaths(skillAssessmentData);

      const learnerData = await actions.getLearnerCareerPaths();

      const tempFormValues = learnerData?.map(learnerCareerPath => ({
        id: Date.now() + Math.random(),
        careerPath: skillAssessmentData?.find(temp => temp.careerPathSlug === learnerCareerPath.careerPathSlug),
        seniority: learnerCareerPath.seniorityLevelEnum,
      }));

      setFormValues(tempFormValues!);

      type FormValuesForResetType = {
        [key: string]: any;
      };

      const formValuesForReset: FormValuesForResetType = {};

      tempFormValues?.forEach(item => {
        formValuesForReset[`careerPath${item.id}`] = item.careerPath?.careerPathSlug;
        formValuesForReset[`seniority${item.id}`] = item.seniority;
      });

      reset(formValuesForReset);

      actions.stopLoader(PagePath.analysisCareerPaths);
    }

    getData();
  }, [actions, reset]);

  function onClickAddHandler() {
    for (const formValue of formValues) {
      if (!formValue.careerPath) return trigger();

      if ((formValue.careerPath.includesEntrySkills || formValue.careerPath.includesMiddleSkills || formValue.careerPath.includesSeniorSkills) && formValue.seniority !== 0 && !formValue.seniority) return trigger();

      const nonUniqueCareerPaths = formValues.filter(temp => temp.careerPath === formValue.careerPath);
      if (nonUniqueCareerPaths.length > 1) return trigger();
    }

    setFormValues(latest => [...latest, { id: Date.now() + Math.random(), careerPath: undefined, seniority: undefined }]);

    setLastButtonRef(buttonRef.current);
  }

  function onClickRemoveHandler(index: number) {
    setFormValues(latest => {
      const temp = [...latest];
      temp.splice(index, 1);
      return temp;
    });
  }

  async function onSubmitHandler() {
    for (const formValue of formValues) {
      if (!formValue.careerPath) return trigger();

      if ((formValue.careerPath.includesEntrySkills || formValue.careerPath.includesMiddleSkills || formValue.careerPath.includesSeniorSkills) && formValue.seniority !== 0 && !formValue.seniority) return trigger();

      const nonUniqueCareerPaths = formValues.filter(temp => temp.careerPath?.careerPathName === formValue.careerPath?.careerPathName);
      if (nonUniqueCareerPaths.length > 1) return trigger();
    }

    actions.startLoader({ path: PagePath.analysisCareerPaths, type: FetchType.Sending });

    const learnerCareerPaths: LearnerCareerPath[] = [];

    for (const formValue of formValues) {
      learnerCareerPaths.push({ careerPathSlug: formValue.careerPath?.careerPathSlug, seniorityLevelEnum: formValue.seniority } as LearnerCareerPath);
    }

    await actions.saveLearnerCareerPaths(learnerCareerPaths);

    const result = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug);

    actions.stopLoader(PagePath.analysisRatingCareerPaths);

    if (result?.careerPathSelectionCompletionStatus === CompletionStatus.Completed) {
      navigate(PagePath.analysisBase);
    } else {
      actions.clearSkillAssessmentConfig();
      navigate(PagePath.yourSkillsBase);
    }
  }

  function getFormLabel(index: number): string {
    return index === 0 ? 'primary' : index === 1 ? 'secondary' : 'tertiary';
  }

  function getChipColour(index: number): ThemeColours {
    return index === 0 ? 'primary' : index === 1 ? 'secondary' : 'accent-2';
  }

  function generateCareerPathButtons(index: number, seniority?: Seniority) {
    const buttons: IPillButton[] = [];

    if (formValues[index].careerPath?.includesEntrySkills) buttons.push({ id: Seniority.EntryLevel, label: 'Entry level', active: seniority === Seniority.EntryLevel });

    if (formValues[index].careerPath?.includesMiddleSkills) buttons.push({ id: Seniority.MidManagement, label: 'Mid-management', active: seniority === Seniority.MidManagement });

    if (formValues[index].careerPath?.includesSeniorSkills) buttons.push({ id: Seniority.SeniorManagement, label: 'Senior Management', active: seniority === Seniority.SeniorManagement });

    return buttons;
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.analysisCareerPaths} skeletonLoader={<CareerPathSkeleton />}>
        <AnalysisPageWrapper className="wrapper padBottom">
          <AnalysisPageHeader>
            <PageHeader
              breadcrumbs={[
                { name: 'Analysis', url: PagePath.analysisBase },
                { name: `${capitalCase(generateLPIText(subdomain))}s`, url: `${PagePath.analysisBase}${PagePath.analysisCareerPaths}` },
              ]}
              title={`${capitalCase(generateLPIText(subdomain))}s`}
              subtitle={`Let us know which ${generateLPIText(subdomain)} you are currently working on and any other ${generateLPIText(subdomain)}s that you are considering.`}
            />
          </AnalysisPageHeader>

          {formValues.map((formValue, index) => {
            return (
              <div className="row" key={formValue.id}>
                <div className="column" style={{ maxWidth: '100%' }}>
                  <CareerPathCard className="card">
                    {index > 0 && (
                      <CareerPathRemoveButton>
                        <button onClick={() => onClickRemoveHandler(index)}>
                          <span className="caption2">REMOVE</span>
                        </button>
                      </CareerPathRemoveButton>
                    )}

                    <CareerPathCardIconColumn>
                      <CareerPathIcon>
                        <object data={themedAssetUrl(`icons/path-${index + 1}.icon` + '.svg')} type="image/svg+xml">
                          <img src={themedAssetUrl(`icons/path-${index + 1}.icon` + '.svg')} alt="" />
                        </object>
                      </CareerPathIcon>
                    </CareerPathCardIconColumn>

                    <CareerPathCardTextColumn>
                      <CareerPathCardHeader>
                        <ChipWrapper className="careerPathChipWrapper">
                          <Chip label={capitalCase(`${getFormLabel(index)} ${generateLPIText(subdomain)}`)} variant="tiny" backgroundColour={getChipColour(index)} colour="contrast-text" />
                        </ChipWrapper>
                      </CareerPathCardHeader>

                      <CareerPathCardBody>
                        <div
                          ref={ref => {
                            if (ref) {
                              setLastButtonRef(ref);
                            }
                          }}
                        >
                          <FormGroup label={`What is your ${getFormLabel(index)} ${generateLPIText(subdomain)}?`}>
                            <FormControl
                              name={`careerPath${formValue.id}`}
                              control={control}
                              rules={new CareerPathValidator(`Select a ${generateLPIText(subdomain)}`, formValues)}
                              render={({ field, fieldState }) => {
                                return (
                                  <Dropdown
                                    {...field}
                                    haserror={!!fieldState.error}
                                    variant="large"
                                    name={`careerPath${formValue.id}`}
                                    defaultValue={formValue.careerPath?.careerPathName}
                                    responsive
                                    onInput={({ target }) => {
                                      const selectedValue = (target as HTMLInputElement).value;
                                      const updatedCareerPath = listCareerPaths?.find(temp => temp.careerPathSlug === selectedValue);

                                      setFormValues(currentState => {
                                        return currentState.map(fv => {
                                          if (fv.id === formValue.id) {
                                            return { ...fv, careerPath: updatedCareerPath };
                                          }
                                          return fv;
                                        });
                                      });

                                      setValue(`careerPath${formValue.id}`, selectedValue);
                                    }}
                                  >
                                    {listCareerPaths?.map(listCareerPath => (
                                      <option key={listCareerPath.careerPathSlug} value={listCareerPath.careerPathSlug}>
                                        {listCareerPath.careerPathName}
                                      </option>
                                    ))}
                                  </Dropdown>
                                );
                              }}
                            />
                          </FormGroup>
                        </div>

                        {(formValue.careerPath?.includesEntrySkills || formValue.careerPath?.includesMiddleSkills || formValue.careerPath?.includesSeniorSkills) && (
                          <FormGroup label="What is your current seniority level within this path?">
                            <FormControl
                              name={`seniority${formValue.id}`}
                              control={control}
                              rules={new RequiredValidator('Select your seniority')}
                              render={() => {
                                return (
                                  <PillButtonGroup
                                    buttons={generateCareerPathButtons(index, formValue.seniority)}
                                    layout="fit"
                                    onClick={(buttons: IPillButton[]) => {
                                      if (typeof buttons[0].id === undefined) return;

                                      setFormValues(currentState => {
                                        const newState = structuredClone(currentState);
                                        newState[index].seniority = buttons[0].id;
                                        return newState;
                                      });

                                      setValue(`seniority${formValue.id}`, buttons[0].id);
                                      trigger(`seniority${formValue.id}`);
                                    }}
                                  />
                                );
                              }}
                            />
                          </FormGroup>
                        )}
                      </CareerPathCardBody>
                    </CareerPathCardTextColumn>
                  </CareerPathCard>
                </div>
              </div>
            );
          })}

          {formValues.length < 3 && (
            <div className="row">
              <div className="column">
                <CareerPathCardButton type="button" onClick={onClickAddHandler}>
                  <AddTwoToneIcon primaryTone="default" secondaryTone="default" />
                  <h5 className="heading5" style={{ color: colourString('default') }}>
                    &nbsp;ADD {!!formValues.length ? 'ANOTHER' : 'A'} {generateLPIText(subdomain).toUpperCase()}
                  </h5>
                </CareerPathCardButton>
              </div>
            </div>
          )}

          {formValues.length > 0 && <div style={{ height: 150 }} />}
        </AnalysisPageWrapper>
      </PageLoaderLayer>

      <BottomButtonWrapper>
        <ButtonWrapperInner>
          <Anchor arrow reverse block onClick={() => navigate(PagePath.analysisBase)}>
            Back
          </Anchor>

          <Button type="button" onClick={onSubmitHandler}>
            Submit
          </Button>
        </ButtonWrapperInner>
      </BottomButtonWrapper>
    </React.Fragment>
  );
}
