import React, { Fragment } from 'react';
import styles from './role-fit-content.module.css';
import { HorizontalBarChart } from '../../../../widgets/analytics/ranked-entities/horizontal-bar-chart/horizontal-bar-chart';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { IRoleFitContentProps } from './role-fit-content.models';

export function RoleFitContent({ roleSlug, peopleSlugs, data, isLoading, title, fill }: IRoleFitContentProps): JSX.Element {
  function renderContent() {
    if (!roleSlug) return <EmptyState title="Please select a role" />;

    if (!peopleSlugs.length) return <EmptyState title="Please select at least one person" />;

    if (!data?.length) return <EmptyState />;

    return <HorizontalBarChart dataPoints={data} fill={fill} showLegend />;
  }

  return (
    <div className="card">
      {isLoading ? (
        <div className={styles.loadingState}>
          <object data={'/graphics/loading-state.graphic.svg'} type="image/svg+xml">
            <img src={'graphics/loading-state.graphic.svg'} alt="" />
          </object>

          <span className="heading5">Loading...</span>
        </div>
      ) : (
        <Fragment>
          <h4 className="heading4">{title}</h4>
          {renderContent()}
        </Fragment>
      )}
    </div>
  );
}
