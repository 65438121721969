import styled from 'styled-components';

export const PasswordStrengthWrapper = styled.div`
  padding-top: 5px;
`;

export const PasswordStrengthList = styled.ul`
  --chip-color: var(--primary);
  --chip-fill: var(--chip-color);

  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  li {
    width: 30px;
    height: 5px;
    background: transparent;
    border: solid 1px var(--chip-color);
    border-radius: 5px;
    position: relative;

    &.filled {
      background: var(--chip-fill);
      opacity: 1;
    }

    &:not(.filled)::after {
      content: attr(data-suggestion);
      position: absolute;
      font-size: 12px;
      width: 220px;
      left: 50%;
      margin-left: -110px;
      top: 0;
      transform: translateY(calc(-100% - 5px));
      padding: 4px 7px;
      background: var(--background);
      border-radius: 3px;
      box-shadow: 0px 1px 4px var(--outer-shadow-colour);
      pointer-events: none;
      opacity: 0;
      display: none;
    }

    &:not(.filled):hover::after {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.green {
    --chip-color: var(--apple);
  }

  &.yellow {
    --chip-color: var(--orange);
  }

  &.red {
    --chip-color: var(--berry);
  }
`;

export const PasswordHint = styled.span`
  display: block;
  text-align: right;
  color: var(--text);
  font-size: 12px;
`;

export const PasswordStrengthLabel = styled(PasswordHint)`
  padding-bottom: 5px;
  font-size: 12px;
`;
