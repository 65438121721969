import { SkillAssessment } from '../../../models';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { AssessmentsSearchRequest } from '../../../models/overmind/search-request';

export enum AssessmentsOverviewTabType {
  All = 'All',
  Active = 'Active',
  Draft = 'Draft',
  Closed = 'Closed',
  Archived = 'Archived',
}

export interface AssessmentsOverviewHeader {
  entityName: string | undefined;
  companySlug: string;
}

export interface IAssessmentsOverviewTableProps {
  skillAssessments: SkillAssessment[];
  request: AssessmentsSearchRequest;
  onSortHandler: (column: any) => void;
  onClickCreateHandler: () => void;
  onPageChangeHandler: (page: number) => void;
  onConfigClicked: () => void;
  loadingSkillAssessments: string[];
  columnConfiguration: ColumnConfiguration[];
  totalPages: number;

  onClickManageAssessmentDraftHandler: (data: SkillAssessment | undefined) => void;
  onClickActivateHandler: (data: SkillAssessment | undefined) => void;
  onClickArchiveDraftAssessmentHandler: (data: SkillAssessment | undefined) => void;
  onClickCompleteHandler: (data: SkillAssessment | undefined) => void;
  onClickViewAssigneesHandler: (data: SkillAssessment | undefined) => void;
}

export enum CascadingPanelIds {
  Sections = 'Sections',
  TechnicalSkills = 'Technical Skills',
  CareerPaths = 'Career Paths',
  BehaviouralSkills = 'Behavioural Skills',
  Assignees = 'Assignees',
  Tags = 'Tags',
}

export class CascadingPanelConfig {
  show: boolean = false;
  skillAssessmentToManageSlug?: string;
  assigneesOnly?: boolean;
  defaultOpenPanel?: CascadingPanelIds;

  constructor(init?: Partial<CascadingPanelConfig>) {
    Object.assign(this, init);
  }
}
