import React from 'react';
import { Button, DropdownSelect, EditIcon, PageHeader } from '@keplerco/core';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { useAppState } from '../../../../overmind';
import { ViewRoleSkeleton } from './view-role.skeleton';
import styles from './view-role.module.css';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useKeplerNavigate } from '../../../../navigation/guards/use-kepler-navigate';
import ViewRoleSkillsWidget from './view-role-skills/view-role-skills.widget';
import ViewRolePeopleWidget from './view-role-people/view-role-people.widget';
import { IViewRoleLayoutProps } from './view-role.models';
import { EntitiesCardWidget } from '../../../../components/cards/entities-card.widget';
import { CompanyEntityResponse } from '../../../../models/overmind/company-entity';

function MyCareerHeaderLayout({ role }: { role: CompanyEntityResponse | undefined }) {
  const keplerNavigate = useKeplerNavigate();

  const { user } = useAppState();

  if (!role || !user?.jobRoles.length) {
    return (
      <PageHeader
        title="Role"
        breadcrumbs={[
          { name: `My Career`, url: PagePath.myCareerBase },
          { name: `Role`, url: window.location.pathname },
        ]}
        divider
      />
    );
  }

  if (user.jobRoles.length < 2) {
    return (
      <PageHeader
        title={role.name}
        breadcrumbs={[
          { name: `My Career`, url: PagePath.myCareerBase },
          { name: `${role?.name}`, url: window.location.pathname },
        ]}
        divider
      />
    );
  }

  return (
    <PageHeader
      title={role.name}
      breadcrumbs={[
        { name: `My Career`, url: PagePath.myCareerBase },
        { name: `${role?.name}`, url: window.location.pathname },
      ]}
      type="actions"
      divider
    >
      <div className="headerActions">
        <DropdownSelect
          label="Select role"
          items={
            user?.jobRoles.map(jobRole => ({
              label: jobRole.name,
              value: jobRole.slug,
              onClick: () => keplerNavigate(`${PagePath.myCareerBase}${PagePath.myCareerRole.replace(':roleSlug', jobRole.slug)}`),
            })) ?? []
          }
          value={role.slug}
        />
      </div>
    </PageHeader>
  );
}

function CompanyGlobalRoleHeaderLayout({ role, path }: { role: CompanyEntityResponse | undefined; path: PagePath.roleManagementCompanyRole | PagePath.roleManagementGlobalRole }) {
  const keplerNavigate = useKeplerNavigate();

  const { companyVariables } = useAppState();

  if (!role) {
    return (
      <PageHeader
        title="Role"
        breadcrumbs={[
          { name: `Role Management`, url: PagePath.roleManagementBase },
          { name: `Role`, url: window.location.pathname },
        ]}
        divider
      />
    );
  }

  return (
    <PageHeader
      title={role.name}
      breadcrumbs={[
        { name: `Role Management`, url: PagePath.roleManagementBase },
        { name: `${role?.name}`, url: window.location.pathname },
      ]}
      type="actions"
      divider
    >
      <div className="headerActions">
        <Button
          fullWidthMobile
          type="button"
          square
          theme="dark"
          onClick={() => {
            path === PagePath.roleManagementGlobalRole
              ? keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementImportRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug)}`)
              : keplerNavigate(`${PagePath.roleManagementBase}${PagePath.roleManagementEditRole.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug)}`);
          }}
        >
          <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
            <EditIcon />
            {path === PagePath.roleManagementGlobalRole ? 'Import' : 'Edit'} role
          </div>
        </Button>
      </div>
    </PageHeader>
  );
}

export function ViewRoleLayout({ path, role }: IViewRoleLayoutProps) {
  return (
    <PageLoaderLayer path={path} skeletonLoader={<ViewRoleSkeleton path={path} />}>
      <div className="pageWrapper wrapper">
        {path !== PagePath.myCareerRole ? <CompanyGlobalRoleHeaderLayout role={role} path={path} /> : <MyCareerHeaderLayout role={role} />}

        {!!role && (
          <div className={styles.viewRoleBody}>
            <EntitiesCardWidget title="Role Description" description={role.description} />

            {path !== PagePath.roleManagementGlobalRole && <ViewRolePeopleWidget path={path} role={role} />}

            <ViewRoleSkillsWidget path={path} role={role} />
          </div>
        )}
      </div>
    </PageLoaderLayer>
  );
}
