import React, { useEffect, useReducer, useState } from 'react';
import { CompletionStatus } from '../../../enums/completion-status';
import { FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { SkillsSkeleton } from './skills.skeleton';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import * as store from './skills.store';
import { filterData } from './skills.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { DropdownSelect, ListIcon, ListLayout, MapIcon, OrderButton, OrderType, PageHeader, Panel, Searchfield, ToggleButtonGroup, XIcon, useMatchScreenWidth } from '@keplerco/core';
import { SkillListItem } from './skill-list-item/skill-list-item';
import classNames from 'classnames';
import styles from './skills.module.css';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { LearningJourneyView } from '../../../enums/learning-journey-view';
import { SkillLevelsExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/skill-levels-explainer-focus-panel/skill-levels-explainer-focus-panel.layout';
import { useDataSyncReaction } from '../../../library/hooks/useDataSyncReaction';
import { getSubdomain } from '../../../library/helpers/get-subdomain';

const labelMap = new Map([
  [OrderType.Ascending, 'Low to High'],
  [OrderType.Descending, 'High to Low'],
  [OrderType.Organic, 'Default'],
]);

export function SkillsPage(): JSX.Element {
  const navigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const [reset, setReset] = useState(Date.now());
  const [open, setOpen] = useState<boolean>(false);

  const isMobile = useMatchScreenWidth('mobile');

  const subdomain = getSubdomain();
  const isLPI = subdomain.includes('lpi');

  async function getData(useLoader: boolean = true) {
    if (useLoader) {
      actions.startLoader({ path: PagePath.learningJourneyBase, type: FetchType.PageFetching });
    }
    const data = await actions.getCoursesLearnerSkills();
    dispatch({ type: store.SkillsActionTypes.SetData, payload: data });
    if (useLoader) {
      actions.stopLoader(PagePath.learningJourneyBase);
    }
  }

  useDataSyncReaction(async () => {
    getData(false);
  });

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const payload = filterData(state.data, state.filters);
    dispatch({ type: store.SkillsActionTypes.SetFilteredData, payload });
  }, [state.data, state.filters]);

  function onInputSearch(query: string) {
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { ...state.filters, query } });
  }

  function onChangeSort(type: OrderType) {
    const sortAscending = type === OrderType.Organic ? undefined : type === OrderType.Ascending;
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { ...state.filters, sortAscending } });
  }

  function onClickClearFilters() {
    dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { query: undefined, sortAscending: true, completionStatus: 'All' } });
    setReset(Date.now());
  }

  async function onClickViewType(viewType: LearningJourneyView) {
    actions.startLoader({ path: PagePath.learningJourneyBase, type: FetchType.PageFetching });
    actions.setLearningJourneyView(viewType);
    navigate(viewType === LearningJourneyView.Map ? `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}` : PagePath.learningJourneyBase);
    actions.stopLoader(PagePath.learningJourneyBase);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningJourneyBase} skeletonLoader={<SkillsSkeleton />}>
        <div className={classNames('wrapper stack', [styles.skillsWrapper])}>
          <PageHeader
            breadcrumbs={[
              {
                name: isLPI ? 'Level Up' : 'Learning Journey',
                url: PagePath.learningJourneyBase,
              },
              {
                name: isLPI ? 'My Skills' : 'Learning',
                url: PagePath.learningJourneyBase,
              },
            ]}
            title={subdomain.includes('roche') ? `Core Skills List` : isLPI ? 'My Skills' : `Learning`}
            onClickHelp={companyVariables.useLevels ? () => setOpen(true) : undefined}
          />

          <div className={styles.skillsSyncToggleActions}>
            {!isMobile && (
              <ToggleButtonGroup
                buttons={[
                  { label: LearningJourneyView.Map, icon: <MapIcon /> },
                  { label: LearningJourneyView.List, icon: <ListIcon /> },
                ]}
                defaultButtonLabel={LearningJourneyView.List}
                onClick={buttonLabel => onClickViewType(buttonLabel as LearningJourneyView)}
              />
            )}
          </div>

          <div key={reset} className={styles.skillsFilters}>
            <Searchfield value={state.filters.query} responsive={isMobile} onInput={onInputSearch} />

            <OrderButton defaultOrderType={OrderType.Ascending} labelMap={labelMap} variant="normal" onChange={onChangeSort} />

            <DropdownSelect
              label="Filter by"
              value={typeof state.filters.completionStatus === 'number' ? state.filters.completionStatus.toString() : state.filters.completionStatus}
              items={[
                {
                  value: 'All',
                },
                {
                  value: CompletionStatus.NotStarted.toString(),
                  label: 'Not Started',
                },
                {
                  value: CompletionStatus.InProgress.toString(),
                  label: 'In Progress',
                },
                {
                  value: CompletionStatus.Completed.toString(),
                  label: 'Completed',
                },
              ]}
              onChange={event => dispatch({ type: store.SkillsActionTypes.SetFilters, payload: { ...state.filters, completionStatus: !!event.target.value && event.target.value !== 'All' ? parseInt(event.target.value) : 'All' } })}
            />

            <button className={styles.skillsClearFiltersButton} style={{ visibility: !state.filters.query && state.filters.sortAscending === true && state.filters.completionStatus === 'All' ? 'hidden' : 'visible' }} type="button" onClick={onClickClearFilters}>
              Clear Filters
              <XIcon tone="secondary" />
            </button>
          </div>

          {!state.filteredData.length ? (
            <EmptyState title="No results" badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} />
          ) : (
            <ListLayout>
              {state.filteredData.map(skill => (
                <SkillListItem key={skill.slug} {...skill} path={`${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(`:skillSlug`, skill.slug)}`} />
              ))}
            </ListLayout>
          )}
        </div>
      </PageLoaderLayer>

      {companyVariables.useLevels && (
        <Panel open={open} onClose={() => setOpen(false)}>
          <SkillLevelsExplainerFocusPanelLayout onClose={() => setOpen(false)} />
        </Panel>
      )}
    </React.Fragment>
  );
}
