import React from 'react';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderContainer, SkeletonLoaderRow, SkeletonLoaderColumn } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';

export function CourseMappingCourseSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ marginBottom: 25 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="115px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 15 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="150px" width="270px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 10 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="20px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="50px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn>
          <SkeletonLoader height="50px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="565px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="545px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="545px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="545px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="545px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="40px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={7} />

        <SkeletonLoaderColumn>
          <SkeletonLoader height="40px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

