import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { KeplerState } from '../../../models/kepler-state';
import { AccountPageContainer, AccountPageLineBreak, AccountPageForm, RegisterAccountPageAnchor, AccountPageNavigationToggler } from '../account.styles';
import { AccountPageButton } from '../account.components';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { UserRegistration } from '../../../models/user';
import { FetchType } from '../../../enums';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { PagePath } from '../../../navigation/navigation.enums';
import { RegisterPasswordErrorFlyouts, RegisterPasswordWrapper } from './register.styles';
import WeglotLanguageSwitcher from '../../../components/general/weglot/weglot-switcher';
import { Button, ConfirmationPasswordValidator, EmailValidator, FormControl, RequiredValidator, Textfield } from '@keplerco/core';
import { PasswordStrength } from '../password-strength/password-strength';
import { ShapesBackgroundWrapper } from '../../../design/layout.styles';

export function RegisterPage(): JSX.Element {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const actions = useAppActions();
  const { passwordErrors } = useAppState<KeplerState>();

  const { control, handleSubmit, getValues, watch } = useForm<any>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const [fieldValues, setFieldValues] = useState<{ [key: string]: any }>();
  const [samlUrl, setSamlUrl] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>();

  watch(value => setFieldValues(value));

  useEffect(() => {
    async function getData() {
      const allowCreateAccount = await actions.getAllowCreateAccount();
      if (!allowCreateAccount) navigate(`${PagePath.accountBase}${PagePath.accountLogin}`);
      setSamlUrl(await actions.getSamlUrl(getSubdomain()));
    }

    getData();
  }, []);

  const hasPasswordError = passwordErrors !== undefined && passwordErrors?.length > 0;

  return (
    <>
      <ShapesBackgroundWrapper>
        <AccountPageContainer>
          <div className="card">
            <AccountPageLoaderLayer path={PagePath.accountRegister}>
              <AccountPageNavigationToggler className="showDesktop">
                <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                  Already have an account? <u>Sign In</u>
                </span>
              </AccountPageNavigationToggler>

              <h1 className="heading1">Create an account</h1>

              <AccountPageForm
                id="register"
                autoComplete="off"
                onSubmit={handleSubmit(async (value: UserRegistration) => {
                  actions.startLoader({ path: PagePath.accountRegister, type: FetchType.PageFetching });

                  const result = await actions.registerUser(value);

                  if (result && result.message === 'An email has been sent to your address to confirm your account.') {
                    navigate(`${PagePath.accountBase}${PagePath.accountVerifyEmail}?email=${value.email}`);
                  } else setErrorMessage(result?.message);

                  actions.stopLoader(PagePath.accountRegister);
                })}
              >
                {!!errorMessage && (
                  <div className="field">
                    <div style={{ padding: '0 0 20px 0' }}>
                      <div className="formErrorMessage">{errorMessage}</div>
                    </div>
                  </div>
                )}

                <FormControl paddingBottom={0} control={control} name="email" rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

                <FormControl
                  paddingBottom={0}
                  control={control}
                  name="password"
                  rules={new RequiredValidator('Enter your password')}
                  render={({ field, fieldState }) => (
                    <RegisterPasswordWrapper>
                      <Textfield {...field} haserror={!!fieldState.error || hasPasswordError} label="Password" type="password" responsive />
                      {!!fieldValues?.password && (
                        <PasswordStrength
                          onPercentage={percent => {
                            if (percent >= 100) actions.clearPasswordErrors();
                          }}
                          password={fieldValues.password}
                        />
                      )}

                      {hasPasswordError && (
                        <RegisterPasswordErrorFlyouts className="card">
                          <ul>
                            {passwordErrors.map((error: string, index: number) => {
                              return (
                                <React.Fragment key={'error_' + index}>
                                  <li>
                                    <span>{error}</span>
                                  </li>
                                </React.Fragment>
                              );
                            })}
                          </ul>
                        </RegisterPasswordErrorFlyouts>
                      )}
                    </RegisterPasswordWrapper>
                  )}
                />

                <FormControl
                  paddingBottom={0}
                  control={control}
                  name="confirmPassword"
                  rules={new ConfirmationPasswordValidator('Enter your confirmation password', getValues('password'))}
                  render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error || hasPasswordError} label="Confirm Password" type="password" responsive />}
                />

                <span className="caption1" style={{ textAlign: 'start' }}>
                  By signing up, you agree to our{' '}
                  <RegisterAccountPageAnchor target="_blank" rel="noreferrer" href="https://legal.keplerco.io/documents/terms-of-use/">
                    terms of service
                  </RegisterAccountPageAnchor>{' '}
                  and{' '}
                  <RegisterAccountPageAnchor target="_blank" rel="noreferrer" href="https://legal.keplerco.io/documents/privacy-policy/">
                    privacy policy
                  </RegisterAccountPageAnchor>
                </span>

                <Button chubby filled arrow={false} type="button">
                  Register
                </Button>
              </AccountPageForm>

              <AccountPageLineBreak>Or register using</AccountPageLineBreak>

              {!!samlUrl && <AccountPageButton buttonType="saml" onClick={async () => (window.location.href = `${samlUrl}?returnUrl=${params.get('kt')},,,${params.get('code')}`)} />}

              <AccountPageButton buttonType="google" onClick={() => actions.externalLogin({ provider: 'Google', kt: params.get('kt'), code: params.get('code') })} />

              <AccountPageButton buttonType="microsoft" onClick={() => actions.externalLogin({ provider: 'Microsoft', kt: params.get('kt'), code: params.get('code') })} />

              <AccountPageButton buttonType="linkedin" onClick={() => actions.externalLogin({ provider: 'LinkedIn', kt: params.get('kt'), code: params.get('code') })} />
            </AccountPageLoaderLayer>
          </div>
        </AccountPageContainer>
      </ShapesBackgroundWrapper>

      <WeglotLanguageSwitcher position="bottomLeft" />
    </>
  );
}
