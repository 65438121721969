import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function ManagePersonTagsFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn />
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div className="panelFooter">
        <SkeletonLoader height="25px" width="150px" />
        <SkeletonLoader height="50px" width="150px" />
      </div>
    </div>
  );
}
