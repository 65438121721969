import React, { Fragment } from 'react';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import { LottieAsset } from '../../../../design/lottie/lottie-asset/lottie-asset';
import confettiLottie from '../../../../design/lottie/confetti.lottie.json';
import { Button, KeplerPoints } from '@keplerco/core';

export function CategoryCompletedModalLayout(props: INotificationLayoutProps): JSX.Element {
  return (
    <Fragment>
      <div className="dialogBackground">
        <LottieAsset loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent">
        <div className="badgeIconContainer">
          <object data="/badges/badge_KP.svg" type="image/svg+xml">
            <img src="/badges/badge_KP.svg" alt="" />
          </object>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center', textAlign: 'center' }}>
          <h2 className="heading2">Nice Work!</h2>

          <h6 className="subtitle">
            You've earned a total of
            <h2 className="heading2">
              <KeplerPoints trimWhiteSpace points={props.amount} />
            </h2>
            for completing {props.title ?? 'your course'}
          </h6>

          <Button type={'button'} chubby filled arrow={false} onClick={props.onAction}>
            Thanks!
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
