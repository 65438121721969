import styled from 'styled-components';

export const CareerPathRemoveButton = styled.div`
  grid-area: remove;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    display: block;
    width: 50px;
    height: 50px;
    border: none;
    background: transparent;
    cursor: pointer;
    position: relative;
    border-top-right-radius: 15px;

    span {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 25px;
      height: 3px;
      background: var(--default);
      position: absolute;
      top: 12px;
      margin-left: calc(50px / 2 - 12px);
      border-radius: 2px;
      transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 150ms;
    }

    .caption2 {
      position: absolute;
      bottom: 4px;
      left: 1px;
      color: var(--default);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      &::before,
      &::after {
        background: var(--secondary);
      }

      .caption2 {
        color: var(--secondary);
      }
    }
  }
`;

export const CareerPathCardIconColumn = styled.div`
  grid-area: icon;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CareerPathIcon = styled.div`
  width: 200px;
  height: 200px;
  background-color: var(--background);
  border-radius: 50%;
  border: 1px solid var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;

  object {
    display: block;
  }

  img {
    display: block;
  }
`;

export const CareerPathCardTextColumn = styled.div`
  grid-area: text;
`;

export const CareerPathCardHeader = styled.div`
  position: relative;

  .careerPathChipWrapper {
    position: absolute;
    top: -35px;
    left: 25px;
  }
`;

export const CareerPathCardBody = styled.div`
  margin-left: 25px;

  @media screen and (max-width: 580px) {
    margin-left: 0;
  }
`;

export const CareerPathCardButton = styled.button`
  margin-top: 25px;
  width: 100%;
  height: 82px;
  background: var(--background);
  border: 1px solid var(--borders);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CareerPathCard = styled.div`
  padding: 25px;
  margin-top: 25px;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100px 200px calc(100% - 300px);
  grid-template-areas: 'remove icon text';

  @media screen and (max-width: 580px) {
    grid-template-rows: auto auto;
    grid-template-columns: 100%;
    grid-template-areas: 'text' 'remove';

    ${CareerPathCardIconColumn} {
      display: none;
    }
  }
`;
