import { ThemeColours, colourString } from '@keplerco/core';
import styled from 'styled-components';

export const LevelAchievedModalChip = styled.h6<{ backgroundColour: ThemeColours }>`
  background: ${({ backgroundColour: color }) => colourString(color)};
  width: 130px;
  border-radius: 50px;
  font-weight: bold;
  line-height: 30px;
  margin: auto;
`;

