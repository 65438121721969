import React from 'react';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';

export function ManageTeamFocusPanelSkeleton(props: { hasTeamSlug: boolean }): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>{props.hasTeamSlug && <SkeletonLoader height="45px" width="315px" />}</SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div>
        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        {props.hasTeamSlug && (
          <SkeletonLoaderRow>
            <SkeletonLoaderColumn>
              <SkeletonLoader height="45px" />
            </SkeletonLoaderColumn>
          </SkeletonLoaderRow>
        )}
      </div>

      <footer className="panelFooter">
        <SkeletonLoader height="25px" width="60px" />
        <SkeletonLoader height="50px" width="145px" />
      </footer>
    </div>
  );
}
