import { Anchor, ListItemLayout, ListLayout, RadioButtonCard, search } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { BaseSearchRequest } from '../../../models';
import { useAppActions, useAppState } from '../../../overmind';
import classNames from 'classnames';
import { SearchList } from '../../../components/lists/search-list';
import { ListItem } from '../../../components/lists/list-item';
import { ActivationStatus } from '../../../enums';

type EntityType = 'Company' | 'Department' | 'Team' | 'Person';

const entityTypes: EntityType[] = ['Company', 'Department', 'Team', 'Person'];
const pageSize = 6;

enum ErrorMessage {
  NoEntityType = 'An entity type must be selected',
  NoEntities = 'At least one entity must be selected',
}

export interface AssessmentsWizardTeams {
  assessmentSlug: string;
  teamSlugs: string[];
}

export interface AssessmentsWizardDepartments {
  assessmentSlug: string;
  departmentSlugs: string[];
}

interface AssessmentsWizardEntity {
  slug: string;
  name: string;
  email?: string;
  disabled?: boolean;
}

interface AssessmentsWizardEntities {
  totalPages: number;
  entities: AssessmentsWizardEntity[];
}

export function AssessmentsWizardEntitiesWidget({ assessmentSlug, onSaveAndContinue }: { assessmentSlug: string | undefined; onSaveAndContinue: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [selectedEntityType, setSelectedEntityType] = useState<EntityType>();

  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<BaseSearchRequest>({
    companySlug: companyVariables.slug,
    page: 1,
    pageSize,
    sortAscending: true,
  });
  const [entities, setEntities] = useState<AssessmentsWizardEntities>();
  const [searchedEntities, setSearchedEntities] = useState<AssessmentsWizardEntities>();
  const [selectedEntities, setSelectedEntities] = useState<string[]>();
  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  useEffect(() => {
    async function getDepartments() {
      setLoading(true);
      const response = await actions.getAssessmentsDepartments(companyVariables.slug!);
      const temp: AssessmentsWizardEntities | undefined = !!response
        ? {
          totalPages: response.length ? 1 : 0,
          entities: response.map(department => ({
            slug: department.slug,
            name: department.name,
          })),
        }
        : undefined;
      setEntities(temp);
      setSearchedEntities(temp);
      setLoading(false);
    }

    async function getTeams() {
      setLoading(true);
      const response = await actions.getAssessmentsTeams(companyVariables.slug!);
      const temp: AssessmentsWizardEntities | undefined = !!response
        ? {
          totalPages: response.length ? 1 : 0,
          entities: response.map(team => ({
            slug: team.slug!,
            name: team.teamName,
          })),
        }
        : undefined;
      setEntities(temp);
      setSearchedEntities(temp);
      setLoading(false);
    }

    async function getPeople() {
      setLoading(true);
      const response = await actions.searchPeople(request);
      const temp: AssessmentsWizardEntities | undefined = !!response
        ? {
          totalPages: response.totalPages,
          entities: response.employees.map(employee => ({
            slug: employee.id,
            name: `${employee.firstName} ${employee.lastName}`,
            email: employee.email,
            disabled: employee.activationStatus === ActivationStatus.Archived,
          })),
        }
        : undefined;
      setEntities(temp);
      setSearchedEntities(temp);
      setLoading(false);
    }

    if (selectedEntityType === 'Department') getDepartments();
    if (selectedEntityType === 'Team') getTeams();
    if (selectedEntityType === 'Person') getPeople();
  }, [request, selectedEntityType]);

  useEffect(() => {
    if (!selectedEntityType) return setErrorMessage(ErrorMessage.NoEntityType);
    if (selectedEntityType !== 'Company' && !selectedEntities?.length) return setErrorMessage(ErrorMessage.NoEntities);
    setErrorMessage(undefined);
  }, [selectedEntityType, selectedEntities]);

  return (
    <Fragment>
      <div style={{ marginBottom: 15 }}>
        <ListLayout wrap>
          {entityTypes.map(entity => (
            <ListItemLayout key={entity}>
              <RadioButtonCard
                id={entity}
                checked={selectedEntityType === entity}
                onClick={() => {
                  if (selectedEntityType === entity) return;
                  setSelectedEntities([]);
                  setSelectedEntityType(entity);
                }}
              >
                <div className="card">
                  <label className="toggleCardLabel" htmlFor={entity}>
                    {entity}
                  </label>
                </div>
              </RadioButtonCard>
            </ListItemLayout>
          ))}
        </ListLayout>
      </div>

      {!!selectedEntityType && selectedEntityType !== 'Company' && (
        <div className="card" style={{ marginBottom: 15 }}>
          <SearchList
            loading={loading}
            onInput={query => {
              if (selectedEntityType === 'Person') {
                setRequest(currentState => ({ ...currentState, search: query, page: 1 }));
                return;
              }

              if (!entities) return;
              const temp = search(entities.entities, query, 'name');
              setSearchedEntities({
                totalPages: temp.length ? 1 : 0,
                entities: temp,
              });
            }}
            paging={{
              activePageNumber: request.page ?? 1,
              pageCount: entities?.totalPages ?? 0,
              onPageChange: page => setRequest(currentState => ({ ...currentState, page })),
            }}
          >
            {searchedEntities?.entities.map(entity => {
              const selected = selectedEntities?.includes(entity.slug!);
              return (
                <ListItem
                  key={entity.slug}
                  selected={!!selected}
                  disabled={entity.disabled}
                  onClick={() => {
                    if (selected) {
                      setSelectedEntities(currentState => {
                        if (!currentState) return;
                        let nextState: string[] = structuredClone(currentState);
                        nextState = nextState.filter(selectedEntity => selectedEntity !== entity.slug);
                        return nextState;
                      });
                    } else {
                      setSelectedEntities(currentState => {
                        if (!currentState) return;
                        const nextState = structuredClone(currentState);
                        nextState.push(entity.slug!);
                        return nextState;
                      });
                    }
                  }}
                >
                  {selectedEntityType === 'Person' ? (
                    <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: '1fr 1fr' }}>
                      <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                        {entity.name}
                      </div>

                      <div className="caption2">{entity.email}</div>
                    </div>
                  ) : (
                    <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                      {entity.name}
                    </div>
                  )}
                </ListItem>
              );
            })}
          </SearchList>
        </div>
      )}

      <div className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })}>{errorMessage}&nbsp;</div>

      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            setDirty(true);

            if (!assessmentSlug) return;
            if (!!errorMessage) return;

            setLoading(true);

            switch (selectedEntityType) {
              case 'Company': {
                await actions.postAssessmentCompany(assessmentSlug);
                break;
              }

              case 'Department': {
                await actions.postAssessmentDepartments({
                  assessmentSlug,
                  departmentSlugs: selectedEntities!,
                });
                break;
              }

              case 'Team': {
                await actions.postAssessmentTeams({
                  assessmentSlug,
                  teamSlugs: selectedEntities!,
                });
                break;
              }

              case 'Person': {
                await actions.postAssessmentPeople({
                  assessmentSlug,
                  userIds: selectedEntities!,
                });
                break;
              }

              default: {
                break;
              }
            }

            setLoading(false);

            onSaveAndContinue();
          }}
        >
          Save & Continue
        </Anchor>
      </div>
    </Fragment>
  );
}
