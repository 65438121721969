import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { ListLayout, ListItemLayout } from '@keplerco/core';

export function ReflectionFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" width="500px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div>
        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="225px" />
          </SkeletonLoaderColumn>

          <SkeletonLoaderColumn>
            <SkeletonLoader height="225px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="30px" width="500px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <ListLayout wrap>
          <ListItemLayout>
            <SkeletonLoader height="40px" width="130px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="40px" width="130px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="40px" width="115px" />
          </ListItemLayout>

          <ListItemLayout>
            <SkeletonLoader height="40px" width="145px" />
          </ListItemLayout>
        </ListLayout>

        <SkeletonLoaderRow style={{ marginTop: 30, marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="30px" width="500px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="125px" width="100%" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="30px" width="500px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ gap: 5 }}>
          <SkeletonLoaderColumn flex={1}>
            <SkeletonLoader height="20px" width="20px" borderRadius="50%" />
          </SkeletonLoaderColumn>

          <SkeletonLoaderColumn flex={1}>
            <SkeletonLoader height="20px" />
          </SkeletonLoaderColumn>

          <SkeletonLoaderColumn flex={1}>
            <SkeletonLoader height="20px" width="20px" borderRadius="50%" />
          </SkeletonLoaderColumn>

          <SkeletonLoaderColumn flex={1}>
            <SkeletonLoader height="20px" />
          </SkeletonLoaderColumn>

          <SkeletonLoaderColumn flex={19} />
        </SkeletonLoaderRow>
      </div>

      <footer className="panelFooter">
        <SkeletonLoader height="25px" width="60px" />
        <SkeletonLoader height="50px" width="230px" />
      </footer>
    </div>
  );
}

