import styled from 'styled-components';


export const RatingConfirmationModalCards = styled.div`
  width: 40%;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
`;

export const RatingConfirmationModalCardWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RatingConfirmationModalCard = styled.div`
  width: 40%;
  padding: 20px;
  border-radius: 10px;
  background-color:var(--borders_2);
  border: 1.5px solid var(--borders_1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;
