import React, { useRef } from 'react';
import Lottie, { LottieRef } from 'lottie-react';
import classNames from 'classnames';
import { ILottieAssetProps } from './lottie-asset.models';

export function LottieAsset(props: ILottieAssetProps): JSX.Element {
  const { data, loop = false, autoplay = true } = props;

  const icon: LottieRef | undefined = useRef() as any;

  return <Lottie className={classNames(`lottie-output`, props.className)} autoplay={autoplay} loop={loop} animationData={data} lottieRef={icon} />;
}

