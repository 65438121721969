import React, { Fragment } from 'react';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import confettiLottie from '../../../../design/lottie/confetti.lottie.json';
import { LottieAsset } from '../../../../design/lottie/lottie-asset/lottie-asset';
import { SkillGapReportBreakdownList, SkillGapReportBreakdownListItem } from './skills-gap-report-modal.styles';
import { Button, KeplerPoints } from '@keplerco/core';

export function SkillsGapReportModalLayout(props: INotificationLayoutProps): JSX.Element {
  return (
    <Fragment>
      <div className="dialogBackground">
        <LottieAsset loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent">
        <div className="badgeIconContainer">
          <object data="/badges/badge_Astronaut.svg" type="image/svg+xml">
            <img src="/badges/badge_Astronaut.svg" alt="Astronaut" />
          </object>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center', textAlign: 'center' }}>
          <h2 className="heading2">Stellar Job!</h2>

          <h6 className="subtitle">
            You've earned a total of
            <h3 className="heading3">
              <KeplerPoints trimWhiteSpace points={Math.round(props.amount ?? 0)} />
            </h3>
            for completing your skills assessment.
          </h6>

          {props.awardBreakdowns && (
            <SkillGapReportBreakdownList className="card-list">
              {props.awardBreakdowns?.map(award => {
                return (
                  <SkillGapReportBreakdownListItem key={award.description}>
                    <div className="card">
                      <small>{award.description}</small>

                      <h4 className="heading4">
                        +<KeplerPoints points={Math.round(award.amount)} />
                      </h4>
                    </div>
                  </SkillGapReportBreakdownListItem>
                );
              })}
            </SkillGapReportBreakdownList>
          )}

          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            Great, Thanks!
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
