import React, { Fragment, PropsWithChildren } from 'react';
import { EmptyState } from '../general/empty-state/empty-state';
import { ListItemLayout, ListLayout } from '@keplerco/core';

export function ListChildren({ children, emptyState }: PropsWithChildren<{ emptyState: boolean }>): JSX.Element {
  const childrenArray = !children ? [] : !Array.isArray(children) ? [children] : children.map(child => ({ ...child, uuid: crypto.randomUUID() }));

  if (!childrenArray.length) {
    return emptyState ? <EmptyState /> : <Fragment />;
  }

  return (
    <ListLayout>
      {childrenArray.length > 1 ? (
        <Fragment>
          {childrenArray.map(child => (
            <ListItemLayout key={child.uuid}>{child}</ListItemLayout>
          ))}
        </Fragment>
      ) : (
        <ListItemLayout>{childrenArray[0]}</ListItemLayout>
      )}
    </ListLayout>
  );
}
