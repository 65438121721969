import styled, { css } from 'styled-components';

const FullscreenModalBar = css`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--cards);
`;

// header
export const FullscreenModalHeaderTitle = styled.h6`
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FullscreenModalHeaderTitleGroup = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 15px 0px;
`;

export const FullscreenModalHeaderGroup = styled.div`
  display: flex;
`;

export const FullscreenModalHeader = styled.header`
  ${FullscreenModalBar}
  border-bottom: solid 1px var(--borders);
`;

// content
export const FullscreenModalContent = styled.section`
  position: relative;
  width: 100%;
`;

// footer
export const FullscreenModalFooterLogo = styled.img`
  height: 100%;
  width: auto;
`;

export const FullscreenModalFooterLogoGroup = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const FullscreenModalFooterButton = styled.button`
  border: 1px solid var(--primary);
  height: 100%;
  width: 320px;
  background-color: var(--primary);
  color: var(--contrast-text);
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  @media screen and (pointer: coarse) {
    width: 100%;
  }
`;

export const FullscreenModalFooter = styled.footer`
  ${FullscreenModalBar}
  border-top: solid 1px var(--borders);
  padding-left: 15px;

  @media screen and (pointer: coarse) {
    padding-left: 0px;
  }
`;

// dialog
export const FullscreenModalDialog = styled.dialog`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 24px;
  height: 90vh;
  width: 90vw;
  background: var(--background);
  overflow: hidden;
  opacity: 1;
  cursor: default;

  ::backdrop {
    background-color: hsla(0, 0%, 0%, 0.8); // TODO: try var() again in the future, not supported at the moment
    backdrop-filter: blur(3px);
    cursor: pointer;
  }

  // animation
  &[open] {
    animation: FullscreenModalFadeIn 500ms ease forwards;

    ::backdrop {
      animation: FullscreenModalFadeIn 500ms ease forwards;
    }
  }

  @keyframes FullscreenModalFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  will-change: height, width, border-radius;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 90ms;

  &.isFullscreen {
    height: 100%;
    width: 100%;
    border-radius: 0px;
  }
`;

