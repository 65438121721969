import styled from 'styled-components';

// LOGO - START
export const TopNavLogo = styled.img`
  height: 100%;
  width: auto;
`;

export const TopNavLogoContainer = styled.a`
  height: 100%;
  width: fit-content;

  display: flex;
  align-items: center;

  overflow: hidden;
`;
// LOGO - END

export const TopNavContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TopNavContainer = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 90px;
  border-bottom: solid 1px var(--background);
  padding: 20px;

  background-color: var(--background);

  will-change: border-bottom, background-color;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

  &.hasScrolled {
    border-bottom-color: var(--borders);
    background-color: var(--cards);
  }

  #hubspot-bot {
    position: fixed;
    top: 80px;
    right: 20px;

    #hubspot-conversations-inline-iframe {
      width: 300px;
      height: 500px;
      border: none;
    }
  }
`;
