import styled from 'styled-components';
import { CMSColumn, CMSRow } from '../../../design/layout.styles';

export const AssessmentsOverviewActions = styled(CMSRow)`
  justify-content: space-between;
  gap: 25px;
  align-items: flex-end;

  @media screen and (max-width: 750px) {
    display: block;

    ${CMSColumn} {
      width: 100%;
    }
  }
`;