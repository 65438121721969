import { ScoreComparison } from '../../enums/score-comparison';

export function comparisonScoreTitle(scoreComparison: ScoreComparison): string {
  if (scoreComparison === ScoreComparison.FOPExpectedProficiency || scoreComparison === ScoreComparison.RoleExpectedProficiency) {
    return 'Expected proficiency';
  }

  if (scoreComparison === ScoreComparison.FieldOfPractice) {
    return 'Role Average';
  }

  return `${scoreComparison} average`;
}
