import { DeveloperConsole } from '@keplerco/core';

const developerConsole = new DeveloperConsole('Segment', ['#E5145F', '#FFFFFF']);

const isDemoCompany = process.env.REACT_APP_ASSETS_URL?.includes('demo');
const isProdEnvironment = process.env.NODE_ENV === 'production';
const isProdEndpoint = process.env.REACT_APP_KEPLER_API_ENDPOINT?.includes('keplerco.io');
const isProdLocation = window.location.href.includes('keplerco.io');

export function track(eventName: string, properties?: IAnalyticsProperties) {
  if (isDemoCompany || (isProdEnvironment && isProdEndpoint && isProdLocation)) {
    window.analytics.track(eventName, properties);
  } else {
    developerConsole.info(`Track event skipped`);
  }
}

export function identify(userId: string, properties?: IAnalyticsProperties) {
  if (isDemoCompany || (isProdEnvironment && isProdEndpoint && isProdLocation)) {
    window.analytics.identify(userId, properties);
  } else {
    developerConsole.info(`Identify event skipped`);
  }
}

export function page(category?: string, name?: string, properties?: IAnalyticsProperties) {
  if (isDemoCompany || (isProdEnvironment && isProdEndpoint && isProdLocation)) {
    window.analytics.page(category, name, properties);
  } else {
    developerConsole.info(`Page event skipped: ${name}`);
  }
}
