import React, { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../overmind';
import { CollapsibleCardRow, CollapsibleContainer, SkillLayoutEmptyState } from './skill.styles';
import { CompletionStatus, DisplayAreas, FetchType } from '../../../enums';
import { ReflectionFocusPanelLayout } from '../reflections/reflection-focus-panel.layout';
import * as store from './skill.store';
import { QuestionnaireWidget } from '../../../widgets';
import { CaptureAdditionalLearningCard } from '../../../components/cards/capture-additional-learning-card/capture-additional-learning-card';
import { AdditionalLearningCard } from '../../../components/cards/additional-learning-card/additional-learning-card';
import { AdditionalLearningFocusPanelLayout } from '../additional-learning/additional-learning-focus-panel/additional-learning-focus-panel.layout';
import { AdditionalLearningViewModel } from '../additional-learning/additional-learning.models';
import { ConfirmationModalLayout } from '../../../widgets/layouts/modal-layouts/confirmation-modal/confirmation-modal.layout';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { ILessonCardFooterProps, ILessonCardProps } from '../../../components/cards/lesson-card/lesson-card.models';
import { QuestionSetType } from '../../../enums/question-set-type';
import { track } from '../../../library/helpers/segment';
import { PreAssessmentIcon } from './pre-assessment.icon';
import { PostAssessmentIcon } from './post-assessment.icon';
import { ReflectIcon } from './reflect.icon';
import { SkillLayoutSkeleton } from './skill.skeleton';
import { clampText } from './skill.helpers';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { ActionCard, CascadingPanel, CascadingPanels, ChevronIcon, Collapsible, CollapsibleStep, KeplerPoints, Modal, PageHeader, Panel, randomColours, useCascadingPanelsConnector } from '@keplerco/core';
import { ISkillLayoutProps } from './skill.models';
import { LearningJourneyView } from '../../../enums/learning-journey-view';
import { useDataSyncReaction } from '../../../library/hooks/useDataSyncReaction';
import { LessonCard } from '../../../components/cards/lesson-card/lesson-card';

enum CascadingPanelIds {
  AdditionalLearning = 'AdditionalLearning',
  Reflection = 'Reflection',
}

export function SkillLayout(props: ISkillLayoutProps): JSX.Element {
  const navigate = useNavigate();

  const { user, fetchState, learningJourneyView } = useAppState();
  const actions = useAppActions();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const { openPanelIds, next, close, closeAll } = useCascadingPanelsConnector();

  async function getPageData(useLoader: boolean = true) {
    if (!props.slug) return;

    if (useLoader) actions.startLoader({ path: props.path, type: FetchType.PageFetching });

    const [journey, reflectionList, preAssessment, postAssessment] = await Promise.all([
      props.path === PagePath.learningJourneyYearSkill || props.path === PagePath.learningJourneyAssignedSkill ? actions.getAssignedLearningJourney(props.slug) : actions.getLearningJourney(props.slug),
      actions.getReflectionList(props.slug),
      actions.getQuestionSetSkillQuestionnaire({ skillSlug: props.slug, questionSetType: QuestionSetType.PreCourseAssessment }),
      actions.getQuestionSetSkillQuestionnaire({ skillSlug: props.slug, questionSetType: QuestionSetType.PostCourseAssessment }),
    ]);

    if (typeof journey === 'undefined') {
      navigate(`/error/api/404`);
      return;
    }

    dispatch({ type: store.SkillLayoutActionTypes.SetData, payload: { parentPath: props.parentPath, path: props.path, journey, reflectionList, preAssessment, postAssessment } });

    if (!!reflectionList?.reflectionId) dispatch({ type: store.SkillLayoutActionTypes.SetReflectionId, payload: reflectionList.reflectionId });

    if (useLoader) actions.stopLoader(props.path);
  }

  useDataSyncReaction(async () => {
    getPageData(false);
  });

  useEffect(() => {
    getPageData();
  }, [props.slug]);

  useEffect(() => {
    async function getData() {
      if (!user?.allowOffPlatformLearning) return;
      const additionalLearningList = await actions.getAdditionalLearningList({ searchTerm: '', skillSlug: props.slug });
      dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearningList, payload: additionalLearningList });
    }

    getData();
  }, [user?.allowOffPlatformLearning]);

  // additional learning
  function onClickDeleteAdditionalLearningHandler(additionalLearning: UniqueEntity) {
    dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearning, payload: additionalLearning });
    dispatch({ type: store.SkillLayoutActionTypes.SetShowConfirmationModal, payload: true });
  }

  function onClickCancelConfirmationHandler() {
    dispatch({ type: store.SkillLayoutActionTypes.SetShowConfirmationModal, payload: false });
    setTimeout(() => dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearning, payload: undefined }), 500);
  }

  async function onClickSubmitConfirmationHandler() {
    if (!state.selectedAdditionalLearning?.slug) return;

    actions.startLoader({ path: props.path, type: FetchType.Sending });

    await actions.deleteAdditionalLearning(state.selectedAdditionalLearning.slug);

    const additionalLearningList = await actions.getAdditionalLearningList({ searchTerm: '', skillSlug: props.slug });
    dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearningList, payload: additionalLearningList });

    dispatch({ type: store.SkillLayoutActionTypes.SetShowConfirmationModal, payload: false });
    dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearning, payload: undefined });

    actions.stopLoader(props.path);
  }

  function onClickEditAdditionalLearningHandler(additionalLearning: UniqueEntity) {
    dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearning, payload: additionalLearning });
    next('additional');
  }

  function onCancelAdditionalLearningHandler() {
    closeAll();
    setTimeout(() => dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearning, payload: undefined }), 500);
  }

  async function onSubmitAdditionalLearningHandler(formData: AdditionalLearningViewModel, reflect?: boolean) {
    actions.startLoader({ path: props.path, type: FetchType.Sending });

    const additionalLearning = await actions.saveAdditionalLearning(formData);
    dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearning, payload: undefined });

    if (!additionalLearning) {
      closeAll();
      actions.stopLoader(props.path);
      return;
    }

    const additionalLearningList = await actions.getAdditionalLearningList({ searchTerm: '', skillSlug: props.slug });
    dispatch({ type: store.SkillLayoutActionTypes.SetAdditionalLearningList, payload: additionalLearningList });

    if (reflect) {
      dispatch({ type: store.SkillLayoutActionTypes.SetReflectionId, payload: additionalLearning.reflectionId });
      next(CascadingPanelIds.Reflection);
      actions.stopLoader(props.path);
      return;
    }

    const reflectionList = await actions.getReflectionList(props.slug!);
    dispatch({ type: store.SkillLayoutActionTypes.SetReflectionList, payload: reflectionList });
    closeAll();
    actions.stopLoader(props.path);
  }

  // reflection
  function onCancelReflectionHandler() {
    dispatch({ type: store.SkillLayoutActionTypes.SetReflectionId, payload: state.reflectionList?.reflectionId });
    closeAll();
  }

  async function onSubmitReflectionHandler() {
    if (!props.slug) return closeAll();

    actions.startLoader({ path: props.path, type: FetchType.PageFetching });

    const reflectionList = await actions.getReflectionList(props.slug);
    dispatch({ type: store.SkillLayoutActionTypes.SetReflectionList, payload: reflectionList });

    dispatch({ type: store.SkillLayoutActionTypes.SetReflectionId, payload: reflectionList?.reflectionId });
    closeAll();

    actions.stopLoader(props.path);
  }

  const [shouldDisplay, setShouldDisplay] = React.useState<boolean | undefined>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldDisplay(state.courseLessons && fetchState[PagePath.learningJourneySkill].type !== FetchType.PageFetching && state.courseLessons?.length > 0);
    }, 1000);

    return () => clearTimeout(timer);
  }, [state.courseLessons, fetchState]);

  return (
    <React.Fragment>
      <PageLoaderLayer path={props.path} skeletonLoader={<SkillLayoutSkeleton />}>
        <div className="wrapper stack">
          <PageHeader
            breadcrumbs={[
              {
                name: 'Learning Journey',
                url: learningJourneyView === LearningJourneyView.List ? PagePath.learningJourneyBase : `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}`,
              },
              {
                name: state.pageTitle,
                url: window.location.pathname,
              },
            ]}
            title={state.pageTitle}
            subtitle={state.pageDescription}
          />

          {/* assessment */}
          {!!state.preAssessment && (
            <ActionCard
              icon={<PreAssessmentIcon />}
              title="Before you start"
              description={`Let's get a clearer picture of your skills in ${state.pageTitle} `}
              action={{
                type: `anchor`,
                text: `Take the quiz`,
                onClick: () => {
                  if (!!state.preAssessment) dispatch({ type: store.SkillLayoutActionTypes.SetQuestionnaire, payload: { questionnaire: state.preAssessment, type: QuestionSetType.PreCourseAssessment } });
                },
              }}
            />
          )}

          {!!state.postAssessment && !state.preAssessment && !!state.courseWithVariation && (
            <React.Fragment>
              <ActionCard
                icon={<PostAssessmentIcon />}
                title="It's time to update your score"
                description={`You have completed your ${state.pageTitle} learning journey. Let's see what you've learned.`}
                action={{
                  type: `anchor`,
                  text: `Take the quiz`,
                  onClick: () => {
                    if (!!state.postAssessment) dispatch({ type: store.SkillLayoutActionTypes.SetQuestionnaire, payload: { questionnaire: state.postAssessment, type: QuestionSetType.PostCourseAssessment } });
                  },
                }}
              />
            </React.Fragment>
          )}

          {/* reflection */}
          {user?.allowReflections && !!state.reflectionList && !!state.reflectionList.reflectionId && !!state.reflectionList.smallReflections.length && (
            <React.Fragment>
              <ActionCard
                icon={<ReflectIcon />}
                title="Reflect on your learning"
                description={`You recently completed '${clampText(state.reflectionList.lessonName, 50)}'. To finalise, please capture a rating.`}
                action={{
                  type: `anchor`,
                  text: `Reflect now`,
                  onClick: () => next(CascadingPanelIds.Reflection),
                }}
              />
            </React.Fragment>
          )}

          {state.courseLessons && shouldDisplay && (
            <CollapsibleContainer>
              <Collapsible defaultOpen={[state.courseLessons[0]?.name]} collapseStyle={state.courseLessons.length > 1 ? 'free' : 'none'}>
                {state.courseLessons?.map((step, index) => {
                  const lessons: ILessonCardProps[] = step.lessons.map((lesson, index) => {
                    let disabled = !!state.preAssessment;
                    if (step.lessonOrderLocked && index > 0) disabled = step.lessons[index - 1].completionStatus !== CompletionStatus.Completed;

                    const footer: ILessonCardFooterProps | undefined = lesson.completionStatus !== CompletionStatus.Completed ? { borderTop: true, backgroundColour: 'primary', text: 'Start', icon: <ChevronIcon size={15} tone="contrast-text" />, colour: 'contrast-text' } : undefined;

                    return {
                      thumbnailUrl: lesson.image,
                      keplerPoints: lesson.completionStatus === CompletionStatus.Completed ? lesson.keplerPoints : lesson.keplerPointsAvailable,
                      name: lesson.name,
                      description: lesson.description,
                      lessonUrl: lesson.url,
                      playerType: lesson.playerType,
                      disabled,
                      completed: lesson.completionStatus === CompletionStatus.Completed,
                      footer,
                      courseSlug: lesson.courseSlug,
                      path: props.path,
                    };
                  });

                  return (
                    <CollapsibleStep
                      key={step.name}
                      id={step.name}
                      colour={randomColours[index % randomColours.length]}
                      title={step.name}
                      tagContent={() => {
                        if (!lessons.length) return <React.Fragment />;

                        return (
                          <React.Fragment>
                            <h5 className="heading5">
                              {step.keplerPoints}/<KeplerPoints trimWhiteSpace points={step.keplerPointsAvailable} />
                            </h5>
                          </React.Fragment>
                        );
                      }}
                    >
                      <div className="card">
                        <CollapsibleCardRow>
                          {lessons.map(lesson => (
                            <LessonCard key={lesson.name} {...lesson} />
                          ))}
                        </CollapsibleCardRow>
                      </div>
                    </CollapsibleStep>
                  );
                })}
              </Collapsible>
            </CollapsibleContainer>
          )}

          {/* additional learning */}
          {!!state.additionalLearningList && user?.allowOffPlatformLearning && (
            <CollapsibleContainer>
              <Collapsible collapseStyle="none">
                <CollapsibleStep
                  id="Additional learning"
                  title="Additional learning"
                  tagContent={() => (
                    <h5 className="heading5">
                      <KeplerPoints trimWhiteSpace points={state.additionalLearningList?.reduce((keplerPointsEarned, lesson) => keplerPointsEarned + lesson.keplerPoints, 0)} />
                    </h5>
                  )}
                >
                  <div className="card">
                    <CollapsibleCardRow>
                      <CaptureAdditionalLearningCard onClick={() => next(CascadingPanelIds.AdditionalLearning)} />

                      {state.additionalLearningList.map(additionalLearning => (
                        <AdditionalLearningCard key={additionalLearning.name} additionalLearning={additionalLearning} onClickDelete={onClickDeleteAdditionalLearningHandler} onClickEdit={onClickEditAdditionalLearningHandler} />
                      ))}
                    </CollapsibleCardRow>
                  </div>
                </CollapsibleStep>
              </Collapsible>
            </CollapsibleContainer>
          )}

          {!state.additionalLearningList?.length && !state.courseLessons?.length && !!user?.allowOffPlatformLearning && (
            <SkillLayoutEmptyState>
              <EmptyState
                badgeIconWidth={450}
                badgeUrl={themedAssetUrl('graphics/empty-state-add.graphic.svg')}
                title="No learning activities have been assigned yet"
                subtitle="Have you completed any in-person sessions, webinars, talks or learning from places outside of this platform?"
                buttons={[
                  {
                    text: 'Capture Additional Learning',
                    onClick: () => next(CascadingPanelIds.AdditionalLearning),
                  },
                ]}
              />
            </SkillLayoutEmptyState>
          )}

          {!state.courseLessons?.length && !user?.allowOffPlatformLearning && (
            <SkillLayoutEmptyState>
              <EmptyState
                title="No courses available"
                subtitle={`There are no courses assigned to you under ${state.pageTitle}`}
                buttons={[
                  {
                    text: 'Return to Core Map',
                    onClick: () => navigate(PagePath.learningJourneyBase),
                  },
                ]}
              />
            </SkillLayoutEmptyState>
          )}
        </div>
      </PageLoaderLayer>

      {/* variation */}
      {/* <FocusPanel zIndex={103} open={!!state.variationQuestionnaire} variation="questionnaire" onClose={() => dispatch({ type: store.SkillLayoutActionTypes.SetQuestionnaire, payload: undefined })}> */}
      <Panel open={!!state.variationQuestionnaire} onClose={() => dispatch({ type: store.SkillLayoutActionTypes.SetQuestionnaire, payload: undefined })}>
        {!!state.variationQuestionnaire && (
          <QuestionnaireWidget
            {...state.variationQuestionnaire.questionnaire}
            variation="questionnaire"
            questionnaireType={state.variationQuestionnaire.type}
            onCompleteAll={async () => {
              if (state.variationQuestionnaire?.type === QuestionSetType.PreCourseAssessment) {
                track(`PreCourseAssessment: ${props.slug}`);
                dispatch({ type: store.SkillLayoutActionTypes.ClearPreAssessment });
                actions.completeQuestionSet({ questionnaireSlug: state.variationQuestionnaire.questionnaire.slug!, questionSetType: QuestionSetType.PreCourseAssessment, skillSlug: props.slug! });
              } else if (state.variationQuestionnaire?.type === QuestionSetType.PostCourseAssessment) {
                track(`PostCourseAssessment: ${props.slug}`);
                dispatch({ type: store.SkillLayoutActionTypes.ClearPostAssessment });
              }
              await Promise.all([
                actions.getNotifications({
                  size: 20,
                  activeOnly: true,
                  displayArea: DisplayAreas.EntireApplication,
                }),
                actions.syncCourseContent(),
              ]);
            }}
          />
        )}
      </Panel>

      {/* additional learning & reflection */}
      {/* TODO: unify additional learning and reflection panel combinations into a component */}
      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={id => {
          switch (id as CascadingPanelIds) {
            case CascadingPanelIds.AdditionalLearning: {
              onCancelAdditionalLearningHandler();
              break;
            }
            case CascadingPanelIds.Reflection: {
              onCancelReflectionHandler();
              break;
            }
          }

          close(id);
        }}
      >
        <CascadingPanel id={CascadingPanelIds.AdditionalLearning}>
          <AdditionalLearningFocusPanelLayout slug={state.selectedAdditionalLearning?.slug} skillSlug={props.slug} path={props.path} onCancel={onCancelAdditionalLearningHandler} onSubmit={onSubmitAdditionalLearningHandler} />
        </CascadingPanel>

        <CascadingPanel id={CascadingPanelIds.Reflection}>
          <ReflectionFocusPanelLayout reflectionId={state.selectedReflectionId} path={props.path} onCancel={onCancelReflectionHandler} onSubmit={onSubmitReflectionHandler} />
        </CascadingPanel>
      </CascadingPanels>

      <Modal open={state.showConfirmationModal} onClose={onClickCancelConfirmationHandler}>
        <ConfirmationModalLayout
          title={`You've selected to delete ${state.selectedAdditionalLearning?.name}`}
          subtitle="Are you sure you want to continue?"
          submitButtonText="Yes, delete"
          onClickSubmit={onClickSubmitConfirmationHandler}
          cancelButtonText="No, cancel"
          onClickCancel={onClickCancelConfirmationHandler}
        />
      </Modal>
    </React.Fragment>
  );
}
