import React, { useEffect, useReducer } from 'react';
import { EmptyState } from '../../components/general/empty-state/empty-state';
import { FetchStatus, FetchType } from '../../enums';
import { useAppActions, useAppState } from '../../overmind';
import { useNavigate } from 'react-router-dom';
import * as store from './companies.store';
import { PagerConnector } from '../../components/general/pager-connector/pager-connector';
import { CompaniesSkeleton } from './companies.skeleton';
import { CompaniesActionsContainer, CompaniesActionsGroup, CompaniesPageHeaderContainer } from './companies.styles';
import { Mode, PagePath } from '../../navigation/navigation.enums';
import { BaseSearchRequest } from '../../models';
import { PageLoaderLayer } from '../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { AddTwoToneIcon, Anchor, Button, ChevronIcon, ListItemLayout, ListLayout, PageHeader, Pager, Panel, Searchfield, colourString, useMatchScreenWidth } from '@keplerco/core';
import { ManageCompanyFocusPanelLayout } from './manage-company-focus-panel/manage-company-focus-panel.layout';

export function CompaniesPage(): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();
  const { fetchState, user } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');
  const isTablet = useMatchScreenWidth('tablet');

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  async function getData(request: BaseSearchRequest) {
    // Fetch the data for the page from the api. If data
    // is not returned from the api, cancel the rest of
    // the fetch operation.
    const data = await actions.searchCompanies(request);

    // Populate the data into the page state so that a
    // re-render can be triggered in the view
    dispatch({ type: store.CompaniesActionTypes.SetData, payload: data });

    actions.stopLoader(PagePath.companies);
  }

  useEffect(() => {
    actions.setSideNavVisible(false);

    async function initPage() {
      actions.startLoader({ path: PagePath.companies, type: FetchType.PageFetching });

      actions.setActiveCompanySlug(user?.companySlug);

      await getData(state.request);
    }

    initPage();

    return () => actions.setSideNavVisible(true);
  }, []);

  useEffect(() => {
    if (!state.data) return;
    getData(state.request);
  }, [state.request]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.companies, type: FetchType.Custom });
    dispatch({ type: store.CompaniesActionTypes.SetRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  function onClickPagerHandler(page: number) {
    if (page === state.request?.page) return;

    actions.startLoader({ path: PagePath.companies, type: FetchType.PageFetching });
    dispatch({ type: store.CompaniesActionTypes.SetRequest, payload: { ...state.request, page } });
  }

  function onClickCompanyHandler(companySlug: string) {
    actions.setActiveCompanySlug(companySlug);
    navigate(`${PagePath.administrationBase}${PagePath.administrationPeople.replace(':companySlug', companySlug)}`);
  }

  function onClickCreateHandler() {
    dispatch({ type: store.CompaniesActionTypes.SetOpenCreateCompanyFocusPanel, payload: true });
  }

  function onClickCancelHandler() {
    dispatch({ type: store.CompaniesActionTypes.SetOpenCreateCompanyFocusPanel, payload: false });
  }

  function onClickSubmitHandler() {
    actions.startLoader({ path: PagePath.companies, type: FetchType.Custom });
    dispatch({ type: store.CompaniesActionTypes.SetOpenCreateCompanyFocusPanel, payload: false });
    getData(state.request);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.companies} skeletonLoader={<CompaniesSkeleton isMobile={isMobile} isTablet={isTablet} />}>
        <div className="wrapper stack">
          <CompaniesPageHeaderContainer>
            <PageHeader title={!!user ? `Hi ${user?.preferredName ?? user?.firstName}, what do you want to do today?` : `Hi, what do you want to do today?`}>
              <h6 className="subtitle" style={{ color: colourString('text_1') }}>
                Select a company to manage below or <Anchor onClick={() => actions.setMode(Mode.LearningDashboard)}>click here</Anchor> to view your personalised learning dashboard
              </h6>
            </PageHeader>
          </CompaniesPageHeaderContainer>

          {isMobile ? (
            <CompaniesActionsContainer>
              <CompaniesActionsGroup>
                <h3 className="heading3">Companies</h3>

                <Button type="button" square theme="dark" onClick={onClickCreateHandler}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <AddTwoToneIcon />
                    New Company
                  </div>
                </Button>
              </CompaniesActionsGroup>

              <Searchfield loading={fetchState[PagePath.companies].status === FetchStatus.Active && fetchState[PagePath.companies].type === FetchType.Custom} responsive onInput={onInputHandler} />
            </CompaniesActionsContainer>
          ) : isTablet ? (
            <CompaniesActionsContainer>
              <h3 className="heading3">Companies</h3>

              <CompaniesActionsGroup>
                <Searchfield loading={fetchState[PagePath.companies].status === FetchStatus.Active && fetchState[PagePath.companies].type === FetchType.Custom} onInput={onInputHandler} />

                <Button type="button" square theme="dark" onClick={onClickCreateHandler}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <AddTwoToneIcon />
                    New Company
                  </div>
                </Button>
              </CompaniesActionsGroup>
            </CompaniesActionsContainer>
          ) : (
            <CompaniesActionsContainer>
              <h3 className="heading3">Companies</h3>

              <CompaniesActionsGroup>
                <Searchfield loading={fetchState[PagePath.companies].status === FetchStatus.Active && fetchState[PagePath.companies].type === FetchType.Custom} onInput={onInputHandler} />

                <Button type="button" square theme="dark" onClick={onClickCreateHandler}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <AddTwoToneIcon />
                    New Company Profile
                  </div>
                </Button>
              </CompaniesActionsGroup>
            </CompaniesActionsContainer>
          )}

          {!!state.data && state.data.totalPages > 0 && (
            <React.Fragment>
              <ListLayout>
                {state.data.companies.map(company => (
                  <ListItemLayout key={company.companySlug} onClick={() => onClickCompanyHandler(company.companySlug)}>
                    <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr auto`, alignItems: 'center', gap: 15 }}>
                      <h5 className="heading5">{company.companyName}</h5>
                      <ChevronIcon tone="primary" />
                    </div>
                  </ListItemLayout>
                ))}
              </ListLayout>

              <br />

              <PagerConnector pageCount={state.data.totalPages} onPageChange={onClickPagerHandler} defaultPageNumber={state.request.page}>
                {connector => {
                  return <Pager {...connector} />;
                }}
              </PagerConnector>
            </React.Fragment>
          )}

          {!!state.data && state.data.companies.length === 0 && <EmptyState buttons={[{ text: 'New Company Profile', onClick: onClickCreateHandler }]} />}
        </div>
      </PageLoaderLayer>

      {/* TODO: company settings page */}
      <Panel open={state.openCreateCompanyFocusPanel} onClose={onClickCancelHandler}>
        {state.openCreateCompanyFocusPanel && <ManageCompanyFocusPanelLayout onClickCancel={onClickCancelHandler} onClickSubmit={onClickSubmitHandler} />}
      </Panel>
    </React.Fragment>
  );
}
