// departmentsReducer.ts

import { DepartmentListItem, DepartmentSearchResponse } from '../../../models';
import { DepartmentCMSPageDataPopulation } from './departments.cms.model';
import { ReducerAction } from '../../../models/reducer-state';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { PagePath } from '../../../navigation/navigation.enums';
import { DepartmentsSearchRequest } from '../../../models/overmind/search-request';
import { FocusPanelViewDataResettable } from '../../../models/focus-panel.models';
import { Breadcrumb, IPagerProps } from '@keplerco/core';

export interface DepartmentsState {
  departments?: DepartmentSearchResponse;
  request?: DepartmentsSearchRequest;
  // Add other state properties as needed

  crumbs: Breadcrumb[];
  pageTitle: string;

  paging?: Partial<IPagerProps>;
  pageSize: number;

  departmentToManage?: { show: boolean; data?: DepartmentListItem };
  archiveDepartment?: DepartmentListItem | undefined;

  managingColumns?: boolean;
  columnConfiguration: ColumnConfiguration[];
}
export enum DepartmentsActionTypes {
  SetRequest,
  UpdateRequest,
  PopulateData,
  SetDepartmentToManage,
  SetArchiveDepartment,
  SetDepartmentToEdit,
  ManageColumns,
  EditColumnConfiguration,
  // Add other action types as needed
}

type DepartmentCMSActions =
  | ReducerAction<DepartmentsActionTypes.SetRequest, { payload: DepartmentsSearchRequest }>
  | ReducerAction<DepartmentsActionTypes.UpdateRequest, { payload: Partial<DepartmentsSearchRequest> }>
  | ReducerAction<DepartmentsActionTypes.PopulateData, { payload: Partial<DepartmentCMSPageDataPopulation> }>
  | ReducerAction<DepartmentsActionTypes.SetDepartmentToManage, { payload: FocusPanelViewDataResettable<DepartmentListItem> }>
  | ReducerAction<DepartmentsActionTypes.SetArchiveDepartment, { payload: DepartmentListItem | undefined }>
  | ReducerAction<DepartmentsActionTypes.EditColumnConfiguration, { payload: ColumnConfiguration[] }>
  | ReducerAction<DepartmentsActionTypes.ManageColumns, { payload: { open: boolean; reset?: boolean } }>;

export const initialState: DepartmentsState = {
  pageTitle: 'Departments',
  crumbs: [],
  pageSize: 15,
  columnConfiguration: [
    { label: 'Department', key: 'department', required: true, selected: true },
    { label: 'Teams', key: 'teams', required: false, selected: true },
    { label: 'People', key: 'people', required: false, selected: true },
    { label: 'Date added', key: 'dateCreated', required: false, selected: true },
  ],
};
// Initialize other state properties as needed

export function departmentsReducer(state: DepartmentsState = initialState, action: DepartmentCMSActions): DepartmentsState {
  if (action.type === DepartmentsActionTypes.SetRequest) {
    return { ...state, request: action.payload, departments: void 0 };
  }
  if (action.type === DepartmentsActionTypes.PopulateData) {
    if (!state.request) return state;

    return {
      ...state,
      departments: action.payload.data,
      crumbs: [
        { name: 'Administration', url: PagePath.administrationBase },
        { name: 'Departments', url: window.location.pathname },
      ],
      pageTitle: 'Departments',
      paging: {
        pageCount: action.payload.data?.totalPages ?? Math.ceil(action.payload.data!.totalCount / state.pageSize),
      },
    };
  }
  if (action.type === DepartmentsActionTypes.UpdateRequest) {
    return { ...state, request: action.payload };
  }

  if (action.type === DepartmentsActionTypes.SetDepartmentToManage) {
    const newState = { ...state, departmentToManage: { show: !!action.payload.show, data: action.payload.data } };
    if (action.payload.reset) newState.request = void 0;

    return newState;
  }

  if (action.type === DepartmentsActionTypes.SetArchiveDepartment) {
    return { ...state, archiveDepartment: action.payload };
  }

  if (action.type === DepartmentsActionTypes.ManageColumns) {
    if (!action.payload.reset) return { ...state, managingColumns: action.payload.open };
    else if (action.payload.reset) return { ...state, managingColumns: action.payload.open, columnConfiguration: [...initialState.columnConfiguration] };
  }

  if (action.type === DepartmentsActionTypes.EditColumnConfiguration) {
    return { ...state, columnConfiguration: action.payload };
  }

  return state;
}
