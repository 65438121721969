import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FuzzySearchParams, IFuzzySearchProps } from './fuzzy-search.models';
import { FuzzySearchEmptyStateContainer, FuzzySearchHeader, FuzzySearchHeaderColumn, FuzzySearchResultsContainer, FuzzySearchWrapper } from './fuzzy-search.styles';
import { EmptyState } from '../../general/empty-state/empty-state';
import { IEmptyStateProps } from '../../general/empty-state/empty-state.models';
import classNames from 'classnames';
import { useIsInViewport } from '../../../library/hooks/useIsInViewport';
import { IPillButton, PillButtonGroup, safeCallback, Searchfield } from '@keplerco/core';

export function FuzzySearch(props: PropsWithChildren<IFuzzySearchProps>): JSX.Element {
  const searchParamsRef = useRef<FuzzySearchParams>(!!props.defaultValue ? { ...props.defaultValue } : { query: '' });
  const errorRef = useRef<HTMLDivElement>(null);

  const [pillButtons] = useState<IPillButton[]>(!!props.filterOptions ? ['All', ...props.filterOptions].map((filterOption, index) => ({ slug: filterOption, label: filterOption, active: index === 0 })) : []);
  const [emptyState, setEmptyState] = useState<IEmptyStateProps>();

  const inViewport = useIsInViewport(errorRef);

  useEffect(() => {
    const initialEmptyState = {
      subtitle: `Nothing left matches this search... try searching for something else`,
      noBadgeIcon: true,
    };

    const subtitleShouldBe = !searchParamsRef.current.query ? `You haven't searched for anything... try typing something in` : props.emptyState?.subtitle ?? initialEmptyState.subtitle;

    const emptyState = {
      ...initialEmptyState,
      ...props.emptyState,
      subtitle: props.hideSubtitle ? void 0 : subtitleShouldBe,
      badgeUrl: props.emptyState?.badgeUrl,
      noBadgeIcon: !props.emptyState?.badgeUrl,
    };

    setEmptyState(emptyState);
  }, [searchParamsRef.current.query]);

  useEffect(() => {
    if (!!props.errorMessage && !inViewport) setTimeout(() => errorRef.current?.scrollIntoView());
  }, [props.errorMessage]);

  function onInputHandler(value: string) {
    searchParamsRef.current.query = value;
    props.onFuzzySearch(searchParamsRef.current);
  }

  function onClickHandler(buttons: IPillButton[]) {
    searchParamsRef.current.filterOption = buttons[0].label;
    safeCallback(props.onFuzzySearch, searchParamsRef.current);
  }

  return (
    <FuzzySearchWrapper className={classNames({ hasError: !!props.errorMessage })}>
      <FuzzySearchHeader className={classNames({ boxedOutput: props.boxedResults, noBorders: props.noBorders })}>
        <FuzzySearchHeaderColumn style={{ width: !!props.filterOptions ? 'unset' : '100%' }}>
          {!props.disabled && <Searchfield label={props.label} placeholder="Start typing..." loading={props.loading} variant={props.variant ?? 'standard'} responsive={props.variant === 'large' || props.fullWidthSearch} onInput={onInputHandler} />}
        </FuzzySearchHeaderColumn>

        {!!props.filterOptions && (
          <FuzzySearchHeaderColumn>
            <PillButtonGroup buttons={pillButtons} square backgroundColour="borders" onClick={onClickHandler} />
          </FuzzySearchHeaderColumn>
        )}
      </FuzzySearchHeader>

      {/* results */}
      {(Array.isArray(props.children) ? (props.children as Array<any>)?.filter(i => !!i).length > 0 : !!props.children) && (
        <FuzzySearchResultsContainer className={classNames({ boxedOutput: props.boxedResults, noBorders: props.noBorders, hasError: !!props.errorMessage })}>{props.children}</FuzzySearchResultsContainer>
      )}

      {/* no results */}
      {(!props.children || (Array.isArray(props.children) && (props.children as Array<any>)?.filter(i => !!i).length === 0)) && !props.disableEmptyState && (
        <FuzzySearchEmptyStateContainer className={classNames({ noBorders: props.noBorders })}>
          <EmptyState {...emptyState} />
        </FuzzySearchEmptyStateContainer>
      )}

      {/* error */}
      <div className="fieldErrorMessage" ref={errorRef} style={{ marginTop: 5, visibility: !!props.errorMessage ? 'visible' : 'hidden' }}>
        {props.errorMessage}
      </div>
    </FuzzySearchWrapper>
  );
}

