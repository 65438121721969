import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function ReportManagementSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow style={{ marginBottom: 35 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" width="250px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div>
        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      </div>
    </div>
  );
}
