import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styles from './lists.module.css';
import { IListItemProps } from './lists.models';

export function ListItem({ selected, disabled, onClick, children }: PropsWithChildren<IListItemProps>): JSX.Element {
  return !!onClick ? (
    <button className={classNames('card', styles.button, { [styles.selected]: selected })} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  ) : (
    <div className={classNames('card', styles.card, { [styles.selected]: selected, [styles.disabled]: disabled })}>{children}</div>
  );
}
