import React from 'react';
import { PagePath } from '../../../navigation/navigation.enums';
import { ActionCard, ListItemLayout, ListLayout, PageHeader } from '@keplerco/core';
import { useAppState } from '../../../overmind';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { PeerIcon } from './peer.icon';
import { QuestionnaireIcon } from './questionnaire.icon';
import { FOPIcon } from './fop.icon';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';

export function AssessmentsWizardPage(): JSX.Element {
  const { companyVariables } = useAppState();

  const keplerNavigate = useKeplerNavigate();

  return (
    <PageLoaderLayer path={PagePath.assessmentsWizard}>
      <div className="wrapper stack" style={{ paddingBottom: 30, gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Assessments', url: `${PagePath.assessmentsBase}` },
            { name: 'Wizard', url: `${PagePath.assessmentsBase}${PagePath.assessmentsWizard.replace(':companySlug', companyVariables.slug!)}` }
          ]}
          title="Wizard"
          subtitle="Select from Peer Assessment, Questionnaire-Based Assessment, or Fields of Practice Assessment to accurately evaluate skill levels"
          divider
        />

        <ListLayout gap={30}>
          <ListItemLayout>
            <ActionCard
              icon={<PeerIcon />}
              title="Peer Assessment"
              description="Allow a group of people to endorse each other’s skills. Ideal for teams that share similar skill sets, and work in collaboration with one another. This assessment includes the ability for an employee to be assessed by their line manager."
              action={{
                type: 'button',
                text: 'Create',
                onClick: () => keplerNavigate(`${PagePath.assessmentsBase}${PagePath.assessmentsWizardPeer.replace(':companySlug', companyVariables.slug!)}`),
              }}
            />
          </ListItemLayout>

          <ListItemLayout>
            <ActionCard
              icon={<QuestionnaireIcon />}
              title="Questionnaire Based Assessment"
              description="Answer scenario based questions in a multiple choice format. Ideal for assessing behavioural competencies. Select from a list of assessment questionnaires focused on a variety of essential skill sets."
              action={{
                type: 'button',
                text: 'Create',
                onClick: () => keplerNavigate(`${PagePath.assessmentsBase}${PagePath.assessmentsWizardQuestionnaire.replace(':companySlug', companyVariables.slug!)}`),
              }}
            />
          </ListItemLayout>

          <ListItemLayout>
            <ActionCard
              icon={<FOPIcon />}
              title="Fields of Practice Assessment"
              description="Allows people to compare their skills against regulatory expectations. Ideal for facilitating CPD assessments that align with regulatory requirements. Ask your customer success manager about adding assessments for new regulatory frameworks."
              action={{
                type: 'button',
                text: 'Create',
                onClick: () => keplerNavigate(`${PagePath.assessmentsBase}${PagePath.assessmentsWizardFOP.replace(':companySlug', companyVariables.slug!)}`),
              }}
            />
          </ListItemLayout>
        </ListLayout>
      </div>
    </PageLoaderLayer>
  );
}