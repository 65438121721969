import React, { Fragment, PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './toast-hub.module.css';
import { CloseButton } from '@keplerco/core';
import { NotificationType } from '../notifications.models';
import classNames from 'classnames';

export function Toast({ open, onClose, type, children }: PropsWithChildren<{ open: boolean; onClose: () => void; type: NotificationType; }>): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);

  function openDialog() {
    const dialog = dialogRef.current;
    if (!dialog) return;
    dialog.scrollTo(0, 0);
    dialog.show();
  }

  function closeDialog() {
    const dialog = dialogRef.current;
    if (!dialog) return;
    dialog.close();
  }

  useEffect(() => {
    open ? openDialog() : closeDialog();
  }, [open]);

  return (
    <Fragment>
      {open && createPortal(
        <dialog ref={dialogRef} className={classNames(styles.dialog, styles[NotificationType[type]])}>
          <CloseButton onClick={onClose} />
          {children}
        </dialog>,
        document.body
      )}
    </Fragment>
  );
}