import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { AnalysisPageGuard } from '../guards/analysis-page.guard';
import { AssessmentType } from '../../enums/assessment-type';
import { AnalysisLandingPage } from '../../pages/analysis/landing/analysis-landing/analysis-landing.page';
import { CheckTeamPage } from '../../pages/analysis/peer-endorsements/check-team/check-team.page';
import { SkillFrequencyPage } from '../../pages/analysis/peer-endorsements/skill-frequency/skill-frequency.page';
import { RatingTechnicalSelfAssessmentPage } from '../../pages/analysis/rating/rating-technical/rating-technical-self-assessment.page';
import { CompletedPage } from '../../pages/analysis/landing/completed/completed.page';
import { QuestionnairesPage } from '../../pages/analysis/questionnaires/questionnaires.page';
import { QuestionnairePage } from '../../pages/analysis/questionnaires/questionnaire.page';
import { TechnicalLandingPage } from '../../pages/analysis/landing/technical-landing/technical-landing.page';
import { CareerPathPage } from '../../pages/analysis/career-path/career-path.page';
import { RatingTechnicalPeerEndorsementInterstitialPage } from '../../pages/analysis/rating/rating-technical/rating-technical-peer-endorsement-interstitial/rating-technical-peer-endorsement-interstitial.page';
import { RatingTechnicalPeerEndorsementLandingPage } from '../../pages/analysis/rating/rating-technical/rating-technical-peer-endorsement-landing/rating-technical-peer-endorsement-landing.page';
import { RatingTechnicalPeerEndorsementPage } from '../../pages/analysis/rating/rating-technical/rating-technical-peer-endorsement.page';
import { RatingCareerPathsLandingPage } from '../../pages/analysis/rating/rating-career-paths/rating-career-paths-landing.page';
import { RatingCareerPathsPage } from '../../pages/analysis/rating/rating-career-paths/rating-career-paths.page';
import { InterimReportDownloadsPage } from '../../pages/analysis/landing/interim-reports-download/interim-reports-download.page';
import { ErrorRedirect } from './routers.helpers';

export function AnalysisRouter(): JSX.Element {
  return (
    <Routes>
      {/* Analysis Landing Page */}
      <Route path={PagePath.root} element={<AnalysisLandingPage />} />

      {/* Manager-specific pages -- todo: check these are ever used */}
      <Route path={PagePath.analysisInterimDownload} element={<InterimReportDownloadsPage />} />
      <Route path={PagePath.analysisTechnical} element={<TechnicalLandingPage />} />
      <Route path={PagePath.analysisTechnicalCheckTeam} element={<CheckTeamPage />} />

      {/* Peer Endorsement Assessment Routes */}
      <Route
        path={PagePath.analysisRatingTechnical}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.PeerEndorsement}>
            <RatingTechnicalPeerEndorsementInterstitialPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisRatingTechnicalSelfAssessment}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.PeerEndorsement}>
            <RatingTechnicalSelfAssessmentPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisRatingTechnicalPeerEndorsements}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.PeerEndorsement}>
            <RatingTechnicalPeerEndorsementLandingPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisRatingTechnicalPeerEndorsement}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.PeerEndorsement}>
            <RatingTechnicalPeerEndorsementPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisRatingTechnicalCompleted}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.PeerEndorsement}>
            <CompletedPage />
          </AnalysisPageGuard>
        }
      />

      {/* Skill Frequency Page */}
      <Route
        path={PagePath.analysisTechnicalSkillFrequency}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.PeerEndorsement}>
            <SkillFrequencyPage />
          </AnalysisPageGuard>
        }
      />

      {/* Fields of Practice (FOP) Assessment Routes */}
      <Route
        path={PagePath.analysisCareerPaths}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.FieldsOfPractice}>
            <CareerPathPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisRatingCareerPaths}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.FieldsOfPractice}>
            <RatingCareerPathsLandingPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisRatingCareerPath}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.FieldsOfPractice}>
            <RatingCareerPathsPage />
          </AnalysisPageGuard>
        }
      />

      {/* Questionnaire Assessment Routes */}
      <Route
        path={PagePath.analysisBehavioural}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.Questionnaire}>
            <QuestionnairesPage />
          </AnalysisPageGuard>
        }
      />
      <Route
        path={PagePath.analysisBehaviouralQuestionnaire}
        element={
          <AnalysisPageGuard assessmentTypeRequired={AssessmentType.Questionnaire}>
            <QuestionnairePage />
          </AnalysisPageGuard>
        }
      />

      {/* Catch-all Route */}
      <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
    </Routes>
  );
}
