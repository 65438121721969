import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { FetchType } from '../../../enums';
import { AccountPageInstruction, AccountPageContainer, AccountPageForm, AccountPageNavigationToggler } from '../account.styles';
import { ResetPassword } from '../../../models/view/reset-password';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { Button, EmailValidator, FormControl, Textfield } from '@keplerco/core';
import { ShapesBackgroundWrapper } from '../../../design/layout.styles';

export function ForgotPasswordPage(): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();

  const { control, handleSubmit } = useForm({ reValidateMode: 'onChange' });

  const [allowCreateAccount, setAllowCreateAccount] = useState<boolean>();
  const [sent, setSent] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      const response = await actions.getAllowCreateAccount();
      setAllowCreateAccount(response);
    }

    getData();
  }, []);

  return (
    <ShapesBackgroundWrapper>
      <AccountPageContainer>
        <div className="card">
          <AccountPageLoaderLayer path={PagePath.accountForgotPassword}>
            <AccountPageNavigationToggler className="showDesktop">
              {allowCreateAccount ? (
                <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                  Not registered? <u>Create an account</u>
                </span>
              ) : (
                <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                  Not registered? <u>Activate your account</u>
                </span>
              )}
            </AccountPageNavigationToggler>

            <h1 className="heading1">Forgot your password?</h1>

            {!sent && (
              <React.Fragment>
                <div style={{ height: 5 }} />

                <h5 className="heading5">It happens... to the best of us.</h5>

                <div style={{ height: 30 }} />

                <h6 className="subtitle" style={{ margin: 'auto', maxWidth: 490 }}>
                  Enter your email address, and we'll send you an email with a link to reset your password.
                </h6>

                <AccountPageForm
                  id="forgotPassword"
                  autoComplete="off"
                  onSubmit={handleSubmit(async fieldValues => {
                    actions.startLoader({ path: PagePath.accountForgotPassword, type: FetchType.PageFetching });
                    setSent(await actions.forgotPassword(fieldValues as ResetPassword));
                    actions.stopLoader(PagePath.accountForgotPassword);
                  })}
                >
                  <FormControl paddingBottom={0} name="email" control={control} rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} autoFocus haserror={!!fieldState.error} label="Email" responsive />} />

                  <Button type="button" fullWidthMobile>
                    Send Reset Instructions
                  </Button>

                  <AccountPageNavigationToggler className="showMobile bottom">
                    {allowCreateAccount ? (
                      <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountRegister}`)}>
                        Not registered? <u>Create an account</u>
                      </span>
                    ) : (
                      <span className="caption1" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountActivate}`)}>
                        Not registered? <u>Activate your account</u>
                      </span>
                    )}
                  </AccountPageNavigationToggler>
                </AccountPageForm>
              </React.Fragment>
            )}

            {!!sent && (
              <AccountPageInstruction>
                <div style={{ height: 5 }} />

                <h5 className="heading5">Thanks!</h5>

                <div style={{ height: 30 }} />

                <h6 className="subtitle">We've sent you an email with a link to reset your password.</h6>
                <br />
                <h6 className="subtitle">If you don't find it, check your spam.</h6>
              </AccountPageInstruction>
            )}
          </AccountPageLoaderLayer>
        </div>
      </AccountPageContainer>
    </ShapesBackgroundWrapper>
  );
}
