import { useEffect, useMemo, useState } from 'react';

// TODO: replace with hook from Core
export function useIsInViewport(ref: React.RefObject<HTMLElement>) {
  const [isInViewport, setIsInViewport] = useState(false);

  const observer = useMemo(() => {
    return new IntersectionObserver(([entry]) => setIsInViewport(entry.isIntersecting));
  }, []);

  useEffect(() => {
    if (!ref.current) return;

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref, observer]);

  return isInViewport;
}
