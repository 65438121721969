import React, { PropsWithChildren, useState } from 'react';
import { LessonCardHeader, LessonCardBody, LessonCardFooter, LessonCardWrapperAnchor, LessonCardWrapperBlock } from './lesson-card.styles';
import { ILessonCardProps } from './lesson-card.models';
import classNames from 'classnames';
import { useAppActions } from '../../../overmind';
import { IFramePlayerModal } from '../../general/iframe/iframe-player-modal/iframe-player-modal';
import { LockIcon } from './lock.icon';
import { ThumbnailGraphic } from '../../../design/graphics/thumbnail.graphic';
import { PlayerType } from '../../../enums/player-type';
import { IFramePlayerModalPlaylistItem } from '../../general/iframe/iframe-player-modal/iframe-player-modal.models';
import { CompletionStatus, FetchType } from '../../../enums';
import { KeplerPoints, colourString } from '@keplerco/core';
import { IFrameModal } from '../../general/iframe/iframe-modal/iframe-modal';

function LessonCardComponent(props: PropsWithChildren<ILessonCardProps>): JSX.Element {
  const actions = useAppActions();

  const [superTitle, setSuperTitle] = useState<string>();
  const [playlist, setPlaylist] = useState<IFramePlayerModalPlaylistItem[]>([]);
  const [showIFrameModal, setShowIFrameModal] = useState<boolean>(false);

  async function getCourse() {
    const tempPlaylist: IFramePlayerModalPlaylistItem[] = [];

    if (!props.courseSlug) {
      setSuperTitle(undefined);
      setPlaylist(tempPlaylist);
      return;
    }

    const course = await actions.getContinueLearningCourse(props.courseSlug);
    setSuperTitle(course?.name);

    if (props.playerType === PlayerType.IFramePlayer) {
      course?.lessons.forEach((lesson, index) => {
        tempPlaylist.push({
          type: lesson.type,
          completionStatus: lesson.completionStatus,
          name: lesson.name,
          description: lesson.description,
          thumbnailUrl: lesson.image,
          lessonUrl: lesson.url,
          locked: course.lessonOrderLocked && index > 0 ? course.lessons[index - 1].completionStatus !== CompletionStatus.Completed : false,
          expanded: lesson.completionStatus !== CompletionStatus.Completed,
        });
      });
      setPlaylist(tempPlaylist);
    }
  }

  async function onOpenHandler() {
    if (props.disabled || showIFrameModal) return;

    actions.startLoader({ path: props.path, type: FetchType.Sending });

    await getCourse();
    setShowIFrameModal(true);

    actions.stopLoader(props.path);
  }

  async function onCloseHandler() {
    setShowIFrameModal(false);
    await actions.syncCourseContent();
  }

  function LessonCardInner() {
    return (
      <React.Fragment>
        <LessonCardHeader>{!!props.thumbnailUrl ? <img src={props.thumbnailUrl} alt="" draggable="false" /> : <ThumbnailGraphic />}</LessonCardHeader>

        <LessonCardBody>
          <span className="caption2">
            <KeplerPoints trimWhiteSpace iconHeight="0.7em" points={props.keplerPoints} />
            &nbsp;{props.completed ? 'Earned' : 'Available'}
          </span>

          <h6 className="subtitle">{props.name}</h6>

          <p className="body2">{props.description}</p>

          {props.completed && (
            <span className="caption1">
              Done &nbsp;
              <svg fill="none" viewBox="0 0 23 23" className="planet-marker">
                <circle cx="11.6572" cy="11.0098" r="10.5" fill="var(--secondary)" stroke="var(--secondary)" />
                <path
                  fill="var(--cards)"
                  fillRule="evenodd"
                  d="M16.494 5.48537 9.63794 12.2783 6.50133 9.15075c-.62792-.62642-1.79363-.62642-2.50868 0-.1701.16102-.3056.35503-.39819.57018-.0926.21515-.14036.44697-.14036.68117 0 .2342.04776.466.14036.6811.09259.2152.22809.4092.39819.5702l4.38944 4.3804c.32436.315.75867.4912 1.21078.4912.45213 0 .88643-.1762 1.21073-.4912l8.1119-8.09082c.1702-.16102.3056-.35504.3982-.57019.0926-.21515.1404-.44692.1404-.68115 0-.23423-.0478-.466-.1404-.68115-.0926-.21515-.228-.40917-.3982-.57019-.6714-.58135-1.7921-.58135-2.4185.04507"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          )}
        </LessonCardBody>

        {!!props.footer && (
          <React.Fragment>
            {!props.disabled ? (
              <LessonCardFooter className={classNames({ borderTop: props.footer.borderTop })} backgroundColour={props.footer.backgroundColour ?? 'cards'} colour={props.footer.colour ?? 'text'}>
                <span className="caption1">{props.footer.text}&nbsp;</span>
                {props.footer.icon}
              </LessonCardFooter>
            ) : (
              <React.Fragment>
                <LessonCardFooter className={classNames({ borderTop: props.footer.borderTop })} backgroundColour={props.disabled ? 'default' : props.footer.backgroundColour ?? 'cards'} colour={props.disabled ? 'black' : props.footer.colour ?? 'contrast-text'}>
                  <span className="caption1" style={{ color: colourString(props.disabled ? 'black' : props.footer.colour ?? 'contrast-text') }}>
                    Lesson locked&nbsp;
                  </span>
                  <LockIcon tone={props.footer.colour ?? 'contrast-text'} size={15} />
                </LessonCardFooter>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {props.playerType === PlayerType.IFrame && <IFrameModal show={showIFrameModal} superTitle={superTitle} title={props.name} onClose={onCloseHandler} iFrameUrl={props.lessonUrl} />}

        {props.playerType === PlayerType.IFramePlayer && <IFramePlayerModal show={showIFrameModal} superTitle={superTitle} onClose={onCloseHandler} iFrameUrl={props.lessonUrl} playlist={playlist} />}
      </React.Fragment>
    );
  }

  if (props.disabled)
    return (
      <LessonCardWrapperBlock className={classNames({ disabled: true, footer: !!props.footer })}>
        <LessonCardInner />
      </LessonCardWrapperBlock>
    );

  if (props.playerType !== PlayerType.NewWindow)
    return (
      <LessonCardWrapperBlock className={classNames({ footer: !!props.footer })} onClick={onOpenHandler}>
        <LessonCardInner />
      </LessonCardWrapperBlock>
    );

  return (
    <LessonCardWrapperAnchor href={props.lessonUrl} className={classNames({ footer: !!props.footer })} target="_blank" rel="noopener noreferrer">
      <LessonCardInner />
    </LessonCardWrapperAnchor>
  );
}

export const LessonCard = React.memo(LessonCardComponent, (previous, next) => {
  if (previous.completed !== next.completed || previous.lessonUrl !== next.lessonUrl || previous.disabled !== next.disabled) return false;
  return true;
});
