import React, { useEffect, useState } from 'react';
import { IFrameModalContainer } from './iframe-modal.styles';
import { IFrameModalProps } from './iframe-modal.models';
import { IFrame, IFrameLoader, IFrameLoaderContent } from '../iframe.styles';
import { FullscreenModal } from '../../../general/iframe/fullscreen-modal/fullscreen-modal';
import { generateIFrameUrl } from '../iframe.helpers';
import { LoaderIcon } from '@keplerco/core';

export function IFrameModal(props: IFrameModalProps): JSX.Element {
  const { show, superTitle, title, onClose, iFrameUrl } = props;

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <FullscreenModal show={show} superTitle={superTitle} title={title} onClose={onClose}>
      <React.Fragment>
        <IFrameModalContainer>
          {loading ? (
            <IFrameLoader>
              <IFrameLoaderContent>
                <LoaderIcon />
                <h6 className="subtitle">Loading your learning content</h6>
              </IFrameLoaderContent>
            </IFrameLoader>
          ) : (
            <IFrame src={generateIFrameUrl(iFrameUrl)} frameBorder={0}></IFrame>
          )}
        </IFrameModalContainer>
      </React.Fragment>
    </FullscreenModal>
  );
}

