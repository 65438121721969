import React from 'react';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderColumn, SkeletonLoaderRow } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { AdditionalLearningGridCard, AdditionalLearningGridCardLayout } from './additional-learning-focus-panel.styles';

export function AdditionalLearningFocusPanelSkeleton(): JSX.Element {
  return (
    <div className="panelContent">
      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" width="500px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <div>
        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="15px" width="250px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 25 }}>
          <SkeletonLoaderColumn>
            <AdditionalLearningGridCardLayout>
              <AdditionalLearningGridCard>
                <SkeletonLoader height="165px" />
              </AdditionalLearningGridCard>

              <AdditionalLearningGridCard>
                <SkeletonLoader height="165px" />
              </AdditionalLearningGridCard>

              <AdditionalLearningGridCard>
                <SkeletonLoader height="165px" />
              </AdditionalLearningGridCard>

              <AdditionalLearningGridCard>
                <SkeletonLoader height="165px" />
              </AdditionalLearningGridCard>

              <AdditionalLearningGridCard>
                <SkeletonLoader height="165px" />
              </AdditionalLearningGridCard>

              <AdditionalLearningGridCard>
                <SkeletonLoader height="165px" />
              </AdditionalLearningGridCard>
            </AdditionalLearningGridCardLayout>
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="15px" width="250px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>

          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="15px" width="250px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" width="330px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" width="330px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow style={{ marginBottom: 15 }}>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="15px" width="250px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>

        <SkeletonLoaderRow>
          <SkeletonLoaderColumn>
            <SkeletonLoader height="45px" width="330px" />
          </SkeletonLoaderColumn>
        </SkeletonLoaderRow>
      </div>

      <footer className="panelFooter">
        <SkeletonLoader height="25px" width="60px" />
        <SkeletonLoader height="50px" width="145px" />
      </footer>
    </div>
  );
}

