import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { CourseMappingPage } from '../../pages/learning-management/course-mapping/course-mapping.page';
import { CourseMappingCoursePage } from '../../pages/learning-management/course-mapping/course-mapping-course/course-mapping-course.page';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';

export function LearningManagementRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.learningManagementBase);

  return (
    <Routes>
      <Route path={PagePath.learningManagementCourseMapping} element={<CourseMappingPage />} />
      <Route path={PagePath.learningManagementCourseMappingCourse} element={<CourseMappingCoursePage />} />
      {/* <Route path={PagePath.learningManagementMappedCourses} element={<MappedCoursesPage />} /> */}

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMapping}`} />} />
    </Routes>
  );
}

