import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { TagType } from '../../../enums/tag';
import { TagListItem } from '../../../models/tag';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { BaseSearchRequest } from '../../../models';
import { SortField } from '../../../enums';
import { TagInput } from '../../../components/inputs/tags/tag-input';

export function AssessmentsWizardTagsWidget({ assessmentSlug }: { assessmentSlug: string | undefined }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<TagListItem[]>();

  useEffect(() => {
    async function getData() {
      if (!assessmentSlug) return;

      setLoading(true);

      const response = await actions.getAssigneeTags({ companySlug: companyVariables.slug!, entitySlug: assessmentSlug, tagType: TagType.Assessment });
      setTags(response ?? []);

      setLoading(false);
    }

    getData();
  }, []);

  if (loading) {
    return (
      <div style={{ marginBottom: 20 }}>
        <SkeletonLoader height="40px" />
      </div>
    );
  }

  return (
    <TagInput
      initialTags={tags}
      searchTags={async search => {
        const request: BaseSearchRequest = {
          companySlug: companyVariables.slug!,
          search,
          sortAscending: true,
          sortField: SortField.Name,
          page: 1,
          pageSize: 15,
        };
        const response = await actions.searchTags(request);
        return response?.tags ?? [];
      }}
      onCreateTag={async tagName => {
        if (!assessmentSlug) return;
        actions.addAssignees({ companySlug: companyVariables.slug!, tagName, assignees: [{ entitySlug: assessmentSlug, tagType: TagType.Assessment }] });
      }}
      onRemoveTag={async tagName => {
        if (!assessmentSlug) return;
        actions.removeAssignees({ companySlug: companyVariables.slug!, tagName, assignees: [{ entitySlug: assessmentSlug, tagType: TagType.Assessment }] });
      }}
    />
  );
}
