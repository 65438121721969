import React, { PropsWithChildren } from 'react';
import { useAppState } from '../../overmind';
import { Navigate } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { IAnalysisPageGuardProps } from './guards.models';

export function AnalysisPageGuard(props: PropsWithChildren<IAnalysisPageGuardProps>): JSX.Element {
  const { assessmentTypeRequired, children } = props;

  const { skillAssessmentConfig } = useAppState();

  if (assessmentTypeRequired && skillAssessmentConfig?.assessmentType !== assessmentTypeRequired) {
    return <Navigate to={PagePath.analysisBase} />;
  }

  return <>{children}</>;
}
