import React from 'react';
import { SkeletonLoader } from '../../loaders/skeleton-loader/skeleton-loader';

export function FocusPanelSkeletonLoader(): JSX.Element {
  return (
    <div className="panelContent">
      <header>
        <SkeletonLoader height="100px" />
      </header>

      <SkeletonLoader height="calc(100vh - 310px)" />

      <footer className="panelFooter">
        <SkeletonLoader height="50px" width="150px" />
        <SkeletonLoader height="50px" width="150px" />
      </footer>
    </div>
  );
}
