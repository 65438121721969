import styled from 'styled-components';

export const ColumnConfiguratorWidgetListCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const ColumnConfiguratorWidgetListCardLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .caption2 {
    white-space: nowrap;
  }
`;