import { KeplerPointType } from '../../enums';
import { getSubdomain } from '../../library/helpers/get-subdomain';
import { Notification } from '../notifications.models';

/**
 * Returns whether a modal should be suppressed based on the type and current subdomain.
 * @param {Notification} item - The item to check suppression conditions for.
 * @returns {boolean} True if the modal should be suppressed, otherwise false.
 */
export function suppressModal(item: Notification) {
  const subdomain = getSubdomain();
  const isLPI = subdomain.includes('lpi');
  const isRoche = subdomain.includes('roche');
  if (isLPI) {
    return true;
  }
  if (isRoche) {
    return item.keplerPointType !== KeplerPointType.SkillScorePostAssessmentUpdated && item.keplerPointType !== KeplerPointType.SkillScorePreAssessmentUpdated && item.keplerPointType !== KeplerPointType.VariationQuestionReady;
  }
}
