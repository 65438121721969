import React, { useEffect, useState } from 'react';
import { PersonLayout } from '../analytics/people/person/person.layout';
import { useAppActions, useAppState } from '../../overmind';
import { Lesson } from '../../models';
import { PagePath } from '../../navigation/navigation.enums';
import { Profile } from '../../models/profile';
import { useDataSyncReaction } from '../../library/hooks/useDataSyncReaction';

export function DashboardPage(): JSX.Element {
  const actions = useAppActions();
  const { user } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [lessons, setLessons] = useState<Lesson[] | undefined>();
  const [profile, setProfile] = useState<Profile>();
  const [keplerPoints, setKeplerPoints] = useState<number>(0);

  useDataSyncReaction(async () => setKeplerPoints(await actions.getTotalLearnerKeplerPoints()));

  useEffect(() => {
    async function getData() {
      setLoading(true);
      setLessons(await actions.getContinueLearningLessons());
      setProfile(await actions.getProfile());
      setLoading(false);
    }

    getData();
  }, []);

  return (
    <PersonLayout loading={loading} path={PagePath.dashboard} slug={!user ? 'myself' : user.learnerSlug} firstName={profile?.firstName} lastName={profile?.lastName} keplerPoints={keplerPoints} jobTitle={profile?.jobTitle} department={profile?.department} email={profile?.email} lessons={lessons} />
  );
}
