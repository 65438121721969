import React from 'react';
import { SkeletonLoaderColumn, SkeletonLoaderContainer, SkeletonLoaderRow } from '../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';
import { SkeletonLoader } from '../../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { GridLayout, GridItemLayout } from '@keplerco/core';

export function RatingTechnicalPeerEndorsementLandingSkeleton(): JSX.Element {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ alignItems: 'flex-end', paddingBottom: 40 }}>
        <SkeletonLoaderColumn flex={2}>
          <SkeletonLoader height="150px" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SkeletonLoader height="50px" width="140px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow style={{ marginBottom: 10 }}>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="45px" width="175px" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <GridLayout>
            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>

            <GridItemLayout>
              <SkeletonLoader height="285px" />
            </GridItemLayout>
          </GridLayout>
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}
