import React, { useReducer, useState } from 'react';
import { FetchStatus, FetchType } from '../../enums';
import { IQuestionnaireProps } from './questionnaire.models';
import { KeplerState } from '../../models/kepler-state';
import { MultipleChoiceQuestion } from './multiple-choice-question/multiple-choice-question';
import { QuestionnaireQuestion, QuestionnaireSkill } from '../../models/questionnaire';
import { useAppActions, useAppState } from '../../overmind';
import * as questionnaireStyles from './questionnaire.styles';
import classNames from 'classnames';
import { QuestionSetType } from '../../enums/question-set-type';
import { PagePath } from '../../navigation/navigation.enums';
import { NotificationPriority, NotificationType } from '../../notifications/notifications.models';
import { Button, HelpIcon, IconButton, ProgressBar, safeCallback } from '@keplerco/core';

export function sumAllSubQuestions(questionSections: QuestionnaireSkill[]): [number, number, number] {
  const totalCount = questionSections.length;
  const completeCount = questionSections.filter(i => i.isComplete).length;
  const incompleteSections = totalCount - completeCount;
  return [completeCount, totalCount, incompleteSections];
}

function firstUnfinishedOrLast(questionSections: QuestionnaireSkill[]): number {
  return Math.max(questionSections.findIndex(i => !i.isComplete) ?? questionSections.length - 1, 0);
}

function setQuestionSelectionStatus(question: QuestionnaireQuestion, index: number, value?: boolean) {
  const current = question.answers[index];
  const newValue = { ...current, selected: value ?? !current.selected };
  question.answers[index] = newValue;

  return newValue;
}

export function QuestionnaireWidget(questionnaire: IQuestionnaireProps): JSX.Element {
  const [_, forceUpdate] = useReducer(num => num + 1, 0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const actions = useAppActions();
  const [completeCount, totalCount, incompleteSections] = sumAllSubQuestions(questionnaire.quesionnaireSkills);
  const firstUnfinished = firstUnfinishedOrLast(questionnaire.quesionnaireSkills);
  const [activeQuestionSection, setActiveQuestionSection] = useState<number>(firstUnfinished);
  const section = questionnaire.quesionnaireSkills[activeQuestionSection];

  let jumpIndex = section.questions.findIndex(i => i.answers.every(t => !t.selected));
  if (jumpIndex === -1) jumpIndex = section.questions.length - 1;

  const [activeSubSection, setActiveSubSection] = useState<number>(jumpIndex);
  const subSection = section.questions[activeSubSection];
  const { fetchState } = useAppState<KeplerState>();

  function NextButtonPartial({ onClick, active, loading }: { onClick: any; active: boolean; loading: boolean }) {
    let text = 'Save & Continue';

    if (activeSubSection >= section.questions.length - 1) {
      if (incompleteSections === 1) {
        text = 'Complete';
      }
    }

    return (
      <Button type="button" filled onClick={onClick} arrow={false} disabled={!active} isLoading={loading}>
        {text}
      </Button>
    );
  }

  return (
    <questionnaireStyles.QuestionnaireWrapper className={questionnaire.variation}>
      <questionnaireStyles.QuestionnaireSidenav id="questionnaire-sidenav">
        <questionnaireStyles.QuestionnaireSidenavList>
          {questionnaire.quesionnaireSkills.map((skillType, index) => {
            return (
              <questionnaireStyles.QuestionnaireSidenavListItem
                onClick={() => {
                  if (!skillType.isComplete && index !== activeQuestionSection) {
                    setActiveQuestionSection(index);
                    setActiveSubSection(0);
                  }
                }}
                className={classNames({ activeSection: index === activeQuestionSection, done: skillType.isComplete })}
                key={skillType.skillId}
              >
                {skillType.skillName}

                <svg fill="none" viewBox="0 0 23 23">
                  <circle cx="11.6572" cy="11.0098" r="10.5" fill="var(--secondary)" stroke="var(--secondary)" />
                  <path
                    fill="var(--background)"
                    fillRule="evenodd"
                    d="M16.494 5.48537 9.63794 12.2783 6.50133 9.15075c-.62792-.62642-1.79363-.62642-2.50868 0-.1701.16102-.3056.35503-.39819.57018-.0926.21515-.14036.44697-.14036.68117 0 .2342.04776.466.14036.6811.09259.2152.22809.4092.39819.5702l4.38944 4.3804c.32436.315.75867.4912 1.21078.4912.45213 0 .88643-.1762 1.21073-.4912l8.1119-8.09082c.1702-.16102.3056-.35504.3982-.57019.0926-.21515.1404-.44692.1404-.68115 0-.23423-.0478-.466-.1404-.68115-.0926-.21515-.228-.40917-.3982-.57019-.6714-.58135-1.7921-.58135-2.4185.04507"
                    clipRule="evenodd"
                  />
                </svg>
              </questionnaireStyles.QuestionnaireSidenavListItem>
            );
          })}
        </questionnaireStyles.QuestionnaireSidenavList>

        {questionnaire.variation !== 'questionnaire' && (
          <questionnaireStyles.QuestionnaireSidenavProgressWrapper>
            <ProgressBar round theme="primary" fontColour="text" value={completeCount + 1} max={totalCount} />
          </questionnaireStyles.QuestionnaireSidenavProgressWrapper>
        )}
      </questionnaireStyles.QuestionnaireSidenav>

      <questionnaireStyles.QuestionnaireContent>
        <questionnaireStyles.QuestionnaireQuestionListWrapper>
          <questionnaireStyles.QuestionnaireQuestionListTitle id="questionnaire-skillname">
            <h3 className="heading3">{section.skillName}</h3>

            {!!questionnaire.onSkillQuery && (
              <IconButton
                iconType="fill"
                hoverTone="accent-3"
                onClick={() => {
                  safeCallback(questionnaire.onSkillQuery, section);
                }}
              >
                <HelpIcon tone="link-text" />
              </IconButton>
            )}
          </questionnaireStyles.QuestionnaireQuestionListTitle>

          {section.questions.map(question => {
            return (
              <questionnaireStyles.QuestionnaireQuestionListItem key={question.id} className={classNames('block', { active: question.id === subSection.id })}>
                <MultipleChoiceQuestion
                  question={question.description}
                  answers={question.answers}
                  isSaving={isLoading}
                  onSelection={async (sel, clearSelection) => {
                    const index = question.answers.findIndex(i => i.id === sel.id);
                    const newValue = setQuestionSelectionStatus(question, index, true);

                    setIsLoading(true);
                    forceUpdate(); // TODO: See if this can be removed

                    try {
                      if (questionnaire.variation === 'questionnaire') {
                        await actions.answerQuestionSetQuestion({ questionSetType: questionnaire.questionnaireType ?? QuestionSetType.PostCourseAssessment, questionAnswers: { questionnaireSlug: questionnaire.slug, answerId: sel.id } });
                      } else {
                        await actions.answerQuestion({ questionnaireSlug: questionnaire.slug, answerId: sel.id });
                      }

                      if (process.env.NODE_ENV !== 'production') console.log('Question answer saved', { renderId: _, ...newValue });
                    } catch (error) {
                      setQuestionSelectionStatus(question, index, false);
                      clearSelection();

                      actions.addNotification({
                        title: 'Error saving answer',
                        message: `We couldn't save your answer. Please check your network connect. If this problem persists, please contact support`,
                        active: true,
                        id: `failure_${sel.id}`,
                        type: NotificationType.Error,
                        priority: NotificationPriority.Toast,
                      });
                    }

                    setIsLoading(false);
                  }}
                />
              </questionnaireStyles.QuestionnaireQuestionListItem>
            );
          })}
        </questionnaireStyles.QuestionnaireQuestionListWrapper>

        <questionnaireStyles.QuestionnaireFooter>
          <NextButtonPartial
            loading={fetchState[PagePath.analysisBehaviouralQuestionnaire].status === FetchStatus.Active}
            active={subSection.answers.some(i => i.selected) && !isLoading}
            onClick={async () => {
              // FIXME: Remove this when @kepler/core v3.0.34 is installed
              const active = subSection.answers.some(i => i.selected) && !isLoading;
              if (!active) return;

              if (activeSubSection < section.questions.length - 1) setActiveSubSection(activeSubSection + 1);
              else if (fetchState[PagePath.analysisBehaviouralQuestionnaire].status === FetchStatus.Inactive) {
                actions.startLoader({ path: PagePath.analysisBehaviouralQuestionnaire, type: FetchType.Custom });

                if (questionnaire.variation !== 'questionnaire') {
                  await actions.completeQuestionnaireSection({ questionnaireSlug: questionnaire.slug, answerId: section.skillId });
                }

                if (incompleteSections > 1) {
                  setActiveSubSection(0);

                  const nextIndex = questionnaire.quesionnaireSkills.findIndex((r, i) => !r.isComplete && i > activeQuestionSection);
                  const undoneIndex = questionnaire.quesionnaireSkills.findIndex(r => !r.isComplete);

                  setActiveQuestionSection(nextIndex === -1 ? undoneIndex : nextIndex);

                  section.isComplete = true;
                } else {
                  if (questionnaire.variation !== 'questionnaire') await actions.completeQuestionnaire(questionnaire.slug);
                  safeCallback(questionnaire.onCompleteAll);
                }

                actions.stopLoader(PagePath.analysisBehaviouralQuestionnaire);
              }

              window.document.body.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }}
          />

          {activeSubSection > 0 && (
            <span
              id="questionnaire-previous-button"
              className="previous"
              onClick={() => {
                if (activeSubSection > 0) setActiveSubSection(activeSubSection - 1);
              }}
            >
              previous
            </span>
          )}
        </questionnaireStyles.QuestionnaireFooter>
      </questionnaireStyles.QuestionnaireContent>
    </questionnaireStyles.QuestionnaireWrapper>
  );
}


