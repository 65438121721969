import React from 'react';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { SkeletonLoaderContainer, SkeletonLoaderRow, SkeletonLoaderColumn } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader.styles';

export function CoreSkillsMapSkeleton() {
  return (
    <SkeletonLoaderContainer>
      <SkeletonLoaderRow style={{ paddingTop: 40 }}>
        <SkeletonLoaderColumn flex={4}>
          <SkeletonLoader height="125" />
        </SkeletonLoaderColumn>

        <SkeletonLoaderColumn flex={1}>
          <SkeletonLoader height="125" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>

      <SkeletonLoaderRow>
        <SkeletonLoaderColumn>
          <SkeletonLoader height="calc(100vh - 165px)" />
        </SkeletonLoaderColumn>
      </SkeletonLoaderRow>
    </SkeletonLoaderContainer>
  );
}

