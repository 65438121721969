import React from 'react';
import * as onboardingStyles from '../../../onboarding/onboarding.styles';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { InterimReportsDownloadPageRoutingMap } from './interim-reports-download.map';
import { PDFReport } from '../../../../components/general/pdf-report/pdf-report';
import { PageHeader } from '@keplerco/core';

export function InterimReportDownloadsPage(): JSX.Element {
  return (
    <InterimReportsDownloadPageRoutingMap>
      {() => {
        return (
          <React.Fragment>
            <PageLoaderLayer path={PagePath.analysisInterimDownload} skeletonLoader={<React.Fragment />}>
              <div className="stack wrapper">
                <onboardingStyles.PageWrapper>
                  <onboardingStyles.ConfirmTeamContent>
                    <PageHeader
                      breadcrumbs={[
                        {
                          name: 'Skills analysis',
                          url: `${PagePath.analysisBase}`,
                        },
                        {
                          name: 'Download PDF Report',
                          url: `${PagePath.analysisBase}${PagePath.analysisInterimDownload}`,
                        },
                      ]}
                    />

                    <h1 className="heading1">Download PDF Report</h1>
                    <h6 className="subtitle">If you want to download a previous report from your learning, you can do that here without having to finish this year's assessment</h6>

                    <PDFReport path={PagePath.analysisInterimDownload} />
                  </onboardingStyles.ConfirmTeamContent>
                </onboardingStyles.PageWrapper>

                <div style={{ height: 100 }} />
              </div>
            </PageLoaderLayer>
          </React.Fragment>
        );
      }}
    </InterimReportsDownloadPageRoutingMap>
  );
}

