import styled, { css } from 'styled-components';

export const EmptyStateContainer = styled.div`
  text-align: center;
  margin: 30px 0;
  width: 100%;
  position: relative;
`;

interface EmptyStateBadgeProps {
  maxWidth: string;
  centerText?: boolean;
}

export const EmptyStateBadge = styled.div<EmptyStateBadgeProps>`
  position: relative;
  display: block;
  margin: 0 auto 15px;
  width: 90%;
  max-width: ${props => `${props.maxWidth}`};
  height: auto;
  
  object {
    display: block;
    width: 100%;
    height: auto;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  ${props =>
    props.centerText &&
    css`
      padding: 20px; 
    `}
`;

export const EmptyStateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
`;

interface TitleContainerProps {
  centerText?: boolean;
}

export const TitleContainer = styled.div<TitleContainerProps>`
  text-align: center;
  z-index: 1;

  ${props =>
    props.centerText &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;
