import { GraphColours, colourString } from '@keplerco/core';
import styled from 'styled-components';

export const RaterRadioButtonElement = styled.div`
  width: 42px;
  height: 42px;
  border: solid 1px var(--default);
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  padding: 2px;
  transition: border-color cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

  span {
    display: block;
    width: 100%;
    height: 100%;
    background: var(--colour);
    border-radius: 50%;
    position: relative;
    transform: scale(0.9);
    opacity: 0;
    will-change: transform, opacity;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 300ms;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 22px;
      height: auto;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 580px) {
      svg {
        width: 18px;
      }
    }
  }

  @media screen and (max-width: 580px) {
    width: 32px;
    height: 32px;
  }
`;

export const RaterRadioButtonListItem = styled.li<{ colour: GraphColours | 'primary' | 'link-text' }>`
  --colour: ${props => colourString(props.colour)};

  max-width: 70px;
  flex: 1;
  height: auto;
  position: relative;

  input {
    position: absolute;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  input:checked + ${RaterRadioButtonElement} {
    border-color: var(--colour);

    span {
      opacity: 1;
      transform: scale(1);
    }
  }

  label {
    font-size: 12px;
    display: block;
    width: 100%;
    text-align: center;
    line-height: 20px;
    padding-top: 7px;

    @media screen and (max-width: 580px) {
      font-size: 10px;
    }
  }
`;

export const RaterRadioButtonList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: space-between;
  flex: 1;

  &.disabled {
    opacity: 0.5;
  }

  @media screen and (max-width: 580px) {
    max-width: 100% !important;
    width: 100% !important;
    margin: auto;
  }
`;
