import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppActions } from '../../../../overmind';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PersonLayout } from './person.layout';

export function PersonPage(): JSX.Element {
  const { companySlug, personSlug } = useParams();

  const actions = useAppActions();

  const [loading, setLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('First Name');
  const [lastName, setLastName] = useState<string>('Last Name');
  const [keplerPoints, setKeplerPoints] = useState<number>(0);

  useEffect(() => {
    async function getData() {
      if (!personSlug) return setLoading(false);

      setLoading(true);

      const personData = await actions.getLearnerBySlug(personSlug);
      if (!personData) return setLoading(false);

      setFirstName(personData.firstName);
      setLastName(personData.lastName);
      setKeplerPoints(Math.floor(personData.keplerPoints));
      setLoading(false);
    }

    getData();
  }, [personSlug]);

  return (
    <PersonLayout
      loading={loading}
      path={PagePath.analyticsPerson}
      slug={personSlug!}
      firstName={firstName}
      lastName={lastName}
      keplerPoints={keplerPoints}

      breadcrumbs={[
        {
          name: 'Analytics',
          url: `${PagePath.analyticsBase}`,
        },
        {
          name: 'People',
          url: `${PagePath.analyticsBase}${PagePath.analyticsPeople.replace(':companySlug', companySlug!)}`,
        },
        {
          name: `${firstName} ${lastName}`,
          url: window.location.pathname,
        },
      ]}
    />
  );
}

