import React, { Fragment } from 'react';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import { LoginStreakDays } from './login-streak-modal.styles';
import { themedAssetUrl } from '../../../../library/helpers/themed-asset-url';
import { Days, LoginStreak } from '../../../../components/general/login-streak/login-streak';
import { Button, KeplerPoints } from '@keplerco/core';

export function LoginStreakModalLayout(props: INotificationLayoutProps): JSX.Element {
  const days: Days[] = ['MON', 'TUE', 'WED', 'THU', 'FRI'];

  return (
    <Fragment>
      <div className="dialogBackground">
        <object data={themedAssetUrl('backgrounds/stars.background.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('backgrounds/stars.background.svg')} alt="" />
        </object>
      </div>

      <div className="modalContent">
        <div className="badgeIconContainer">
          <object data="/badges/badge_Streak.svg" type="">
            <img src="/badges/badge_Streak.svg" alt="Flame" />
          </object>
        </div>

        <LoginStreakDays>
          <LoginStreak days={days} />
        </LoginStreakDays>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center', textAlign: 'center' }}>
          <h2 className="heading2">5 days in a row!</h2>

          <h6 className="subtitle">
            You've earned a total of
            <h3 className="heading3">
              <KeplerPoints forceFontWeight="bold" points={props.amount} />
            </h3>
            for your five day learning streak.
          </h6>

          <Button chubby type={'button'} filled arrow={false} onClick={props.onAction}>
            WooHoo!
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
