import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FocusPanelLoaderLayer } from '../../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { FetchType, ActivationStatus } from '../../../../enums';
import { TagType } from '../../../../enums/tag';
import { Employee, Department, Team, UniqueEntity } from '../../../../models';
import { useAppActions } from '../../../../overmind';
import { Assignee } from '../../../../models/tag';
import { CreateTagFocusPanelSkeleton } from './create-tag-focus-panel.skeleton';
import { ICreateTagProps as ICreateTagFocusPanelLayoutProps } from './create-tag-focus-panel.models';
import { AssignEntities } from './assign-entities/assign-entities';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Anchor, Button, FocusPanelHeader, FormControl, LettersValidator, Textfield } from '@keplerco/core';

export function CreateTagFocusPanelLayout(props: ICreateTagFocusPanelLayoutProps): JSX.Element {
  const params = useParams();

  const actions = useAppActions();

  const { control, handleSubmit } = useForm<any>({ mode: 'onBlur' });

  const [departments, setDepartments] = useState<Department[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [people, setPeople] = useState<Employee[]>();

  const [selectedDepartments, setSelectedDepartments] = useState<UniqueEntity[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<UniqueEntity[]>([]);
  const [selectedPeople, setSelectedPeople] = useState<UniqueEntity[]>([]);

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.administrationTags, type: FetchType.DialogFetching });

      const departmentsData = await actions.getAdministrationDepartments(params.companySlug!);
      setDepartments(departmentsData);

      const teamsData = await actions.getAdministrationTeams(params.companySlug!);
      setTeams(teamsData);

      const peopleData = await actions.getEmployeesByCompanySlugAndActivationStatus({ activationStatus: ActivationStatus.Active, companySlug: params.companySlug! });
      setPeople(peopleData);

      actions.stopLoader(PagePath.administrationTags);
    }

    getData();
  }, []);

  async function onSubmitHandler(values: any) {
    actions.startLoader({ path: PagePath.administrationTags, type: FetchType.Sending });

    const assignees: Assignee[] = [];

    selectedDepartments.forEach(department => {
      if (!!department.slug) assignees.push({ entitySlug: department.slug, tagType: TagType.Department });
    });

    selectedTeams.forEach(team => {
      if (!!team.slug) assignees.push({ entitySlug: team.slug, tagType: TagType.Team });
    });

    selectedPeople.forEach(person => {
      if (person.slug) assignees.push({ entitySlug: person.slug, tagType: TagType.User });
    });

    await actions.addAssignees({ assignees: assignees, companySlug: params.companySlug!, tagName: values.tagName });
    props.onSubmit();
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationTags} skeletonLoader={<CreateTagFocusPanelSkeleton />}>
      <div className="panelContent panelForm">
        <FocusPanelHeader title="Create tag" />

        <form id="createTag" className="panelForm" onSubmit={handleSubmit(onSubmitHandler)}>
          <div>
            <div className="row">
              <div className="column">
                <FormControl
                  paddingBottom={15}
                  control={control}
                  rules={new LettersValidator('Enter a tag name')}
                  name="tagName"
                  render={({ field, fieldState }) => {
                    return <Textfield {...field} haserror={!!fieldState.error} label="Tag name" type="text" responsive />;
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <AssignEntities type="departments" entities={departments?.map(department => ({ slug: department.slug, name: department.name } as UniqueEntity))} selectedEntities={selectedDepartments} onSelect={setSelectedDepartments} />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <AssignEntities type="teams" entities={teams?.map(team => ({ slug: team.slug, name: team.teamName } as UniqueEntity))} selectedEntities={selectedTeams} onSelect={setSelectedTeams} />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <AssignEntities type="individuals" entities={people?.map(person => ({ slug: person.learnerSlug, name: `${person.firstName} ${person.lastName}` } as UniqueEntity))} selectedEntities={selectedPeople} onSelect={setSelectedPeople} />
              </div>
            </div>
          </div>

          <footer className="panelFooter" style={{ justifyContent: 'space-between' }}>
            <Anchor onClick={props.onCancel}>Cancel</Anchor>

            <Button type="button">Submit</Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}
