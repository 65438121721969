import { useEffect } from 'react';
import { useAppState } from '../../overmind';
import { SyncStatus } from '../../enums';

export function useDataSyncReaction(dataSyncCallback: () => void): void {
  const { courseSyncStatus, lastSynced } = useAppState();

  useEffect(() => {
    async function getData() {
      await dataSyncCallback();
    }

    if (!!lastSynced && courseSyncStatus === SyncStatus.Synced) getData();
  }, [lastSynced]);
}
