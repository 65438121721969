import styled from 'styled-components';

export const DaterangeTrigger = styled.div``;

export const DaterangeWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: var(--background);
  border: 1px solid var(--accent-2);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 10px 0 18px;
  cursor: pointer;
  gap: 15px;
`;

export const DaterangeInner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  pointer-events: none;
  flex: 1;
  white-space: nowrap;
`;

export const DaterangeLabel = styled.label`
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 12px;
  color: var(--accent-3);
  font-weight: 500;
  font-family: var(--heading);
`;

export const DaterangeValue = styled.output`
  appearance: none;
  margin: 0;
  padding: 0;
  display: block;
  font-size: 14px;
  color: var(--text);
  white-space: nowrap;
`;

export const DaterangeIcon = styled.div`
  display: grid;
  place-items: center;
  width: 40px;

  svg {
    display: block;
    width: 20px;
    height: auto;
    margin: auto;
  }
`;

export const DaterangeModal = styled.dialog`
  appearance: none;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1006;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;

  &[open] {
    opacity: 1;
    pointer-events: all;
  }
`;

export const DaterangeModalBackground = styled.div`
  background: var(--background_1);
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  cursor: pointer;
`;

export const DaterangeModalInner = styled.div`
  display: block;
  width: 90%;
  max-width: 330px;
  margin: auto;
  background: var(--cards);
  border: 1px solid var(--borders);
  border-radius: 12px;
  padding: 35px 0 20px 0;
  z-index: 2;
  user-select: none;
  position: relative;
  will-change: transform;
  transform: translate3d(0, 0, 0);
`;

export const DaterangeCloseButton = styled.button`
  display: grid;
  place-items: center;
  width: 45px;
  height: 45px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    display: block;
    width: 16px;
    height: auto;
  }
`;

export const DaterangeModalHeader = styled.header`
  text-align: center;
  position: relative;

  .subtitle {
    font-weight: bold;
  }

  ${DaterangeCloseButton} {
    position: absolute;
    right: 10px;
    top: -25px;
  }
`;

export const DaterangeModalContentListWrapper = styled.div`
  display: block;
`;

export const DaterangeModalContentList = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  gap: 5px;
`;

export const DaterangeModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  /* border-top: 1px solid var(--borders); */
  text-align: right;
  gap: 15px;

  // Top Right Bottom Left
  padding: 5px 30px 5px 30px;

  button,
  span {
    cursor: pointer;
  }

  button {
    appearance: none;
    display: inline-block;
    border-radius: 30px;
    line-height: 30px;
    padding: 4px 14px;
    border: none;
    background: var(--primary);
    font-family: var(--body);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--contrast-text);
    text-transform: uppercase;
  }

  span {
    text-transform: uppercase;
    color: var(--link-text);
    font-family: var(--body);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
`;

// Radio Components
export const DaterangeModalContentListItem = styled.li`
  display: flex;
  cursor: pointer;
  padding: 0px 20px;
  margin: 0px 0px;
  border-top: solid 1px transparent;
  border-bottom: solid 1px transparent;
  background: transparent;
  will-change: padding, margin, background, border-color;
  transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 250ms;

  &.checked {
    padding: 10px 20px;
    margin: 5px 0;
    background: var(--background);
    border-top-color: var(--borders);
    border-bottom-color: var(--borders);
  }
`;

export const DaterangeModalContentListCustomItem = styled(DaterangeModalContentListItem)`
  align-items: flex-start;
  border-bottom: solid 1px var(--borders);
  padding-bottom: 15px;

  &.checked {
    padding-bottom: 15px;
    margin-bottom: 0px !important;
  }
`;

export const DaterangeModalContentListRadio = styled.div`
  display: block;
  width: 40px;
  height: 40px;
  flex: auto auto auto;
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    width: 14px;
    height: 14px;
    border: solid 1px var(--secondary);
    border-radius: 50%;
    margin: -7px;
  }

  &::after {
    width: 10px;
    height: 10px;
    margin: -5px;
    background: var(--secondary);
    border-radius: 50%;
    transform: scale(0.75);
    opacity: 0;
    will-change: opacity, transform;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 150ms;
  }

  &.checked::after {
    opacity: 1;
    transform: scale(1);
  }
`;

export const DaterangeModalContentListRadioContent = styled.div`
  display: block;
  flex: 1 0 auto;
  padding-top: 3px;
`;

export const DaterangeModalContentListRadioContentPlain = styled(DaterangeModalContentListRadioContent)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0;
`;

export const DaterangeModalRadioCaption = styled.h6`
  font-family: var(--body);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: var(--text);
`;

export const DaterangeModalRadioTag = styled.span`
  font-family: var(--body);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--accent-3);
`;

export const DaterangeModalPickerOutput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 10px;
`;
