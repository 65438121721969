import { Context } from '../..';
import { JourneyConfigItem } from '../../../models/planets';
import { Course, CourseSmallViewModel, LearnerSkillWithCourses, Lesson } from '../../../models/overmind/learner-skill-with-courses';
import * as base from '../base';
import { PagePath } from '../../../navigation/navigation.enums';
import { LearnerSkill, LearnerSkillDetail } from '../../../models/view/courses';

export async function getLearningJourney(context: Context, slug: string): Promise<LearnerSkillWithCourses | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `get-learning-journey`, slug), authenticated: true };
  const response: base.IResponse<LearnerSkillWithCourses> = await base.request(request);
  return response.data;
}

export async function getAssignedLearningJourney(context: Context, assignedLearningSlug: string): Promise<LearnerSkillWithCourses | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `get-assigned-learning-journey`, assignedLearningSlug), authenticated: true };
  const response: base.IResponse<LearnerSkillWithCourses> = await base.request(request);

  return response.data;
}

export async function getAssignedLearning(context: Context): Promise<void> {
  const request: base.IRequest = { url: base.url(`courses`, `get-assigned-learning`), authenticated: true };
  const response: base.IResponse<CourseSmallViewModel[]> = await base.request(request);

  context.state.assignedCourses = response.data ?? [];
}

export async function getAssessmentYearLearning(context: Context): Promise<void> {
  const request: base.IRequest = { url: base.url('courses', 'get-assessment-year-learning'), authenticated: true };
  const response: base.IResponse<CourseSmallViewModel[]> = await base.request(request);

  context.state.assessmentYearCourses = response.data ?? [];
}

export async function getContinueLearningLessons(): Promise<Lesson[] | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `get-continue-learning-lessons`), authenticated: true };
  const response: base.IResponse<Lesson[]> = await base.request(request);

  return response.data;
}

// TODO: remove
export async function getLearningMap(): Promise<JourneyConfigItem[] | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `load-journey-map`), authenticated: true };
  const response: base.IResponse<JourneyConfigItem[]> = await base.request(request);

  return response.data?.map(
    item =>
      ({
        id: item.id.toString(),
        skillName: item.skillName,
        slug: item.slug,
        totalLessons: item.totalLessons,
        completedLessons: item.completedLessons,
        isCurrent: item.isCurrent,
        score: item.score,
        skillGap: item.skillGap,
        nextLessonUrl: item.nextLessonUrl,
        keplerPointsAvailable: item.keplerPointsAvailable,
        keplerPoints: item.keplerPoints,
        usesExpectedProficiency: item.usesExpectedProficiency,
        courseCompletionStatus: item.courseCompletionStatus,
        skillSetName: item.skillSetName,

        // FE generated properties
        url: `${PagePath.learningJourneyBase}${PagePath.learningJourneySkill.replace(`:skillSlug`, item.slug)}`,

        // journey map properties
        rotation: 0,
        width: 0,
      } as JourneyConfigItem)
  );
}

export async function getContinueLearningCourse(context: Context, courseSlug: string): Promise<Course | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `${courseSlug}`), authenticated: true };
  const response: base.IResponse<Course> = await base.request(request);
  return response.data;
}

export async function getCoursesLearnerSkills({ state }: Context): Promise<LearnerSkillDetail[] | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `learner-skills?scoreComparison=${state.companyVariables.scoreComparison}`), authenticated: true };
  const response: base.IResponse<LearnerSkillDetail[]> = await base.request(request);
  return response.data;
}

export async function getLearnerCoreSkillsMap({ state }: Context): Promise<LearnerSkill[] | undefined> {
  const request: base.IRequest = { url: base.url(`courses`, `learner-core-skills-map?scoreComparison=${state.companyVariables.scoreComparison}`), authenticated: true };
  const response: base.IResponse<LearnerSkill[]> = await base.request(request);
  return response.data;
}

