import React from 'react';
import { LegendProps } from 'recharts';
import styles from './recharts-legend.module.css';

export function RechartsLegend(props: LegendProps) {
  const { payload } = props;

  return (
    <div className={styles.legend}>
      {payload?.map(item => (
        <div key={item.value} className={styles.legendItem}>
          <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill={item.color} />
          </svg>
          <span className="caption1">{item.value}</span>
        </div>
      ))}
    </div>
  );
}
