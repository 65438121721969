import React, { useEffect, useReducer, useState } from 'react';
import { SortField } from '../../../enums/sort-field';
import { FetchType } from '../../../enums';
import { useAppActions, useAppState } from '../../../overmind';
import { CourseMappingStatus } from '../../../enums/course-mapping-status';
import * as store from './course-mapping.store';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { CourseMappingName, LearningManagementActions } from '../learning-management.styles';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { PagePath } from '../../../navigation/navigation.enums';
import { CourseMappingsListItem, CourseMappingsSearchParams } from '../../../models/overmind/learning-management';
import { courseMappingStatuses, learningPlatformTypes } from '../learning-management.helpers';
import { LearningManagementSkeleton } from '../learning-management.skeleton';
import { Chip, MenuItem, DropdownSelectItem, PageHeader, Searchfield, Filters, DropdownSelect, Table, Pager, TableRow, KebabMenu, TableColumn, Anchor } from '@keplerco/core';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

export function CourseMappingPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const keplerNavigate = useKeplerNavigate();

  async function fetchData(searchParams: CourseMappingsSearchParams) {
    dispatch({ type: store.CourseMappingActionTypes.SetLoading, payload: true });

    const data = await actions.getCourseMappings(searchParams);
    dispatch({ type: store.CourseMappingActionTypes.SetData, payload: data });

    dispatch({ type: store.CourseMappingActionTypes.SetLoading, payload: false });
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.learningManagementCourseMapping, type: FetchType.PageFetching });

      if (!permissions) return;

      const company = await actions.getCompany(companyVariables.slug!);
      dispatch({ type: store.CourseMappingActionTypes.SetHeader, payload: { entityName: company?.companyName, companySlug: companyVariables.slug! } });

      const organizationLevel = extractHighestOrganizationLevel(permissions?.learningManagement.organizationLevels);
      const request: CourseMappingsSearchParams = { ...state.searchParams, organizationLevel: organizationLevel?.organizationLevel, companySlug: companyVariables.slug };
      dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: request });

      await fetchData(request);

      actions.stopLoader(PagePath.learningManagementCourseMapping);
    }

    if (!!permissions) initPage();
  }, [permissions]);

  useEffect(() => {
    if (!state.data) return;
    fetchData(state.searchParams);
  }, [state.searchParams]);

  async function onInputHandler(value: string) {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, search: value, page: 1 } });
  }

  async function onSortHandler(sortField: SortField, sortAscending: boolean) {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, sortField, sortAscending } });
  }

  function generateStatusChip(mapping: CourseMappingsListItem): JSX.Element {
    if (mapping.learningObjectMappingStatus === CourseMappingStatus.Mapped) return <Chip label="Mapped" backgroundColour="g" variant="tiny" />;

    return <Chip label="Not mapped" variant="tiny" />;
  }

  function onPageChangeHandler(page: number) {
    if (page === state.searchParams?.page) return;
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, page } });
  }

  function generateKebabMenuItems(learningObject: CourseMappingsListItem): MenuItem[] {
    return [
      {
        label: learningObject.learningObjectMappingStatus === CourseMappingStatus.Mapped ? 'Edit mapping' : 'Create mapping',
        onClick: () => keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMappingCourse.replace(':companySlug', companyVariables.slug!).replace(':courseSlug', learningObject.slug)}`),
      },
    ];
  }

  // filters
  function applyCourseMappingStatusItem(value: string) {
    setSelectedCourseMappingStatus(value);
  }
  function applyLearningPlatformTypeItem(value: string) {
    setSelectedLearningPlatform(value);
  }

  const [selectedCourseMappingStatus, setSelectedCourseMappingStatus] = useState<string>();
  const [courseMappingStatusItems] = useState<DropdownSelectItem[]>(courseMappingStatuses?.map(item => ({ ...item, onClick: () => applyCourseMappingStatusItem(item.value) })) ?? []);
  const [selectedLearningPlatform, setSelectedLearningPlatform] = useState<string>();
  const [learningPlatformItems] = useState<DropdownSelectItem[]>(learningPlatformTypes?.map(item => ({ ...item, onClick: () => applyLearningPlatformTypeItem(item.value) })) ?? []);

  function applyFilters() {
    dispatch({
      type: store.CourseMappingActionTypes.SetRequest,
      payload: {
        ...state.searchParams,
        page: 1,
        courseMappingStatus: !selectedCourseMappingStatus ? undefined : parseInt(selectedCourseMappingStatus),
        learningPlatformType: !selectedLearningPlatform ? undefined : parseInt(selectedLearningPlatform),
      },
    });
  }

  function clearFilters() {
    dispatch({ type: store.CourseMappingActionTypes.SetRequest, payload: { ...state.searchParams, page: 1, courseMappingStatus: undefined, learningPlatformType: undefined } });
    setSelectedCourseMappingStatus(undefined);
    setSelectedLearningPlatform(undefined);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningManagementCourseMapping} skeletonLoader={<LearningManagementSkeleton />}>
        <div className="wrapper stack">
          <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle} subtitle="Control which courses are mapped to each skill" divider />

          <LearningManagementActions>
            <Searchfield loading={!!state.searchParams.search && state.loading} onInput={onInputHandler} />

            <Filters onClickClear={clearFilters} onClickApply={applyFilters}>
              <DropdownSelect id="status" name="Status" label="Status" value={selectedCourseMappingStatus} items={courseMappingStatusItems} />

              <DropdownSelect id="learningPlatform" name="Learning Platform" label="Learning Platform" value={selectedLearningPlatform} items={learningPlatformItems} />
            </Filters>
          </LearningManagementActions>

          {!!state.data && state.loading && <SkeletonLoader height="460px" />}

          {!state.loading && (
            <Table
              onSort={() => onSortHandler(SortField.Name, !state.searchParams.sortAscending)}
              currentSortDirection={state.searchParams.sortAscending ? 'Ascending' : 'Descending'}
              currentSortBy="Name"
              standAloneConfigColumn
              emptyState={<EmptyState title="No course mappings found" />}
              footerContent={
                <PagerConnector onPageChange={onPageChangeHandler} defaultPageNumber={state.searchParams?.page} pageCount={state.data?.totalPages ?? 0}>
                  {connector => {
                    return <Pager {...connector} />;
                  }}
                </PagerConnector>
              }
            >
              {state.data?.learningObjectList.map(course => (
                <TableRow key={course.slug} id={course.slug} configCell={() => <KebabMenu items={generateKebabMenuItems(course)} />}>
                  <TableColumn sortable id="Name" label="Name">
                    <Anchor
                      hovertype="opacity"
                      textTransform="none"
                      onClick={event => {
                        event.stopPropagation();
                        keplerNavigate(`${PagePath.learningManagementBase}${PagePath.learningManagementCourseMappingCourse.replace(':companySlug', companyVariables.slug!).replace(':courseSlug', course.slug)}`);
                      }}
                    >
                      <CourseMappingName>{course.name}</CourseMappingName>
                    </Anchor>
                  </TableColumn>

                  <TableColumn id="contains" label="Contains">
                    {course.activityCount} activities
                  </TableColumn>

                  <TableColumn id="status" label="Status">
                    {generateStatusChip(course)}
                  </TableColumn>
                </TableRow>
              ))}
            </Table>
          )}
        </div>
      </PageLoaderLayer>
    </React.Fragment>
  );
}
