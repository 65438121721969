import React, { Fragment, useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { Anchor, CheckboxCard, ListItemLayout, ListLayout, search, SearchBar } from '@keplerco/core';
import classNames from 'classnames';
import { AssessmentsWizardDetails } from './assessments-wizard-peer.page';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';

interface AssessmentsWizardQuestionnaire {
  name: string;
  slug: string;
  skillSubTypeName: string;
  skillSubTypeDescription: string;
  totalQuestions: number;
  checked: boolean;
}

export interface AssessmentsWizardQuestionnaires extends AssessmentsWizardDetails {
  questionnaireSlugs: string[];
}

enum ErrorMessage {
  NoQuestionnaires = 'At least one questionnaire must be selected',
}

export function AssessmentsWizardQuestionnairesWidget({ details, onSaveAndContinue }: { details: AssessmentsWizardDetails | undefined, onSaveAndContinue: () => void }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>('');
  const [questionnaires, setQuestionnaires] = useState<AssessmentsWizardQuestionnaire[]>([]); // TODO
  const [searchedQuestionnaires, setSearchedQuestionnaires] = useState<AssessmentsWizardQuestionnaire[]>([]); // TODO
  const [dirty, setDirty] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  useEffect(() => {
    async function getData() {
      if (!details?.slug) return;

      setLoading(true);

      const assessment = await actions.getSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: details.slug });
      const questionnaireNames = await actions.getQuestionnaireNames(companyVariables.slug!) ?? [];

      const nextQuestionnaires: AssessmentsWizardQuestionnaire[] = questionnaireNames.map(questionnaireName => ({
        ...questionnaireName,
        checked: assessment?.questionnaireSlugs.includes(questionnaireName.slug) ?? false,
      }));

      setQuestionnaires(nextQuestionnaires);

      setLoading(false);
    }

    getData();
  }, []);

  useEffect(() => {
    setSearchedQuestionnaires(search(questionnaires, query, 'name'));
  }, [query, questionnaires]);

  useEffect(() => {
    setErrorMessage(!questionnaires.filter(questionnaire => questionnaire.checked).length ? ErrorMessage.NoQuestionnaires : undefined);
  }, [questionnaires]);

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <SkeletonLoader height="550px" />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SkeletonLoader width="150px" height="25px" />
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="card">
        <SearchBar
          label="Search"
          onInput={event => setQuery((event.target as HTMLInputElement).value)}
          responsive
        />

        <div style={{ marginTop: 15, marginBottom: 15, maxHeight: 400, overflowY: 'auto' }}>
          {/* TODO: EntitySelectionList */}
          <ListLayout>
            {searchedQuestionnaires.map(questionnaire => (
              <ListItemLayout key={questionnaire.slug}>
                <CheckboxCard
                  id={questionnaire.slug}
                  checked={questionnaire.checked}
                  onChange={() => {
                    setQuestionnaires(currentState => {
                      const nextState: AssessmentsWizardQuestionnaire[] = structuredClone(currentState);
                      const nextItem = nextState.find(item => item.slug === questionnaire.slug);
                      if (!!nextItem) nextItem.checked = !nextItem.checked;
                      return nextState;
                    });
                  }}
                >
                  <div className="card" key={questionnaire.slug}>
                    <span className="caption1">{questionnaire.name}</span>
                    <span className="caption2">{questionnaire.skillSubTypeDescription}</span>
                  </div>
                </CheckboxCard>
              </ListItemLayout>
            ))}
          </ListLayout>
        </div>

        <div className={classNames('formErrorMessage', { invisible: !dirty || !errorMessage })}>{errorMessage}&nbsp;</div>
      </div>

      <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Anchor
          arrow
          onClick={async () => {
            setDirty(true);

            if (!details?.slug) return;
            if (!!errorMessage) return;

            setLoading(true);

            const response = await actions.postAssessmentQuestionnaires({
              ...details,
              questionnaireSlugs: questionnaires.filter(questionnaire => questionnaire.checked).map(questionnaire => questionnaire.slug),
            });

            setLoading(false);

            !response
              ? actions.addNotification({
                active: true,
                id: crypto.randomUUID(),
                type: NotificationType.Error,
                priority: NotificationPriority.Toast,
                title: 'Something went wrong saving these questionnaires',
                message: 'Please try again',
                toastIncludeIcon: true,
              })
              : onSaveAndContinue();
          }}
        >
          Save & Continue
        </Anchor>
      </div>
    </Fragment>
  );
}