import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageHeader } from '@keplerco/core';
import { useParams } from 'react-router-dom';
import { RoleAverageWidget } from '../../../components/general/average/role-average/role-average.widget';
import { ActualExpectedProficiencyWidget } from '../comparison-tools/actual-expected-proficiency.widget';
import { RoleProficiencyRadarWidget } from '../../../widgets/analytics/radar/role-proficiency-radar.widget';

export function RolePage(): JSX.Element {
  const actions = useAppActions();
  const { roleSlug } = useParams();
  const { companyVariables } = useAppState();
  const [roleName, setRoleName] = useState('Role');

  useEffect(() => {
    async function getData() {
      const result = await actions.getCompanyRole({ companySlug: companyVariables.slug!, roleSlug: roleSlug! });
      if (!!result) setRoleName(result?.name ?? 'Role');
    }
    getData();
  }, []);

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader
        breadcrumbs={[
          {
            name: 'Analytics',
            url: PagePath.analyticsBase,
          },
          {
            name: 'Roles',
            url: `${PagePath.analyticsBase}${PagePath.analyticsRoles.replace(':companySlug', companyVariables.slug!)}`,
          },
          {
            name: roleName,
            url: window.location.pathname,
          },
        ]}
        title={roleName}
        divider
      />
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30 }}>
        <div style={{ flex: '1 1 25%' }}>
          <RoleAverageWidget roleSlug={roleSlug} />
        </div>

        <div style={{ flex: '2 1 630px' }}>
          <RoleProficiencyRadarWidget roleSlug={roleSlug} />
        </div>
      </div>

      <ActualExpectedProficiencyWidget roleSlug={roleSlug} />
    </div>
  );
}
