import React, { useState } from 'react';
import { PageHeader } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { RoleComparisonList } from './role-comparison/role-comparison-list';
import { ComparisonToolWidget } from '../../../components/general/comparison-tool/comparison-tool.widget';
import { ComparisonToolCondition } from '../../../components/general/comparison-tool/comparison-tool.models';
import { PeopleComparisonList } from './people-comparison/people-comparison-list';
import { UniqueEntity } from '../../../models';
import { RoleFitComparisonWidget } from './role-fit-comparison/role-fit-comparison.widget';
import { RoleFitAverageWidget } from './role-fit-average/role-fit-average.widget';

export function ComparisonToolsPage(): JSX.Element {
  const [pendingRole, setPendingRole] = useState<UniqueEntity>();
  const [selectedRole, setSelectedRole] = useState<UniqueEntity>();
  const [pendingPeopleSlugs, setPendingPeopleSlugs] = useState<string[]>([]);
  const [selectedPeopleSlugs, setSelectedPeopleSlugs] = useState<string[]>([]);

  const primaryCondition: ComparisonToolCondition = {
    title: 'Select a role',
    subtitle: 'Choose a role to compare',
    chipLabel: selectedRole?.name,
    onClick: () => setPendingRole(selectedRole),
    panel: {
      supertitle: 'Analytics',
      title: 'Select role',
      subtitle: 'Please select a role from the list',
      list: <RoleComparisonList pendingRoleSlug={pendingRole?.slug} setPendingRole={setPendingRole} />,
      onCancel: () => {
        setPendingRole(undefined);
        setSelectedRole(undefined);
        setPendingPeopleSlugs([]);
        setSelectedPeopleSlugs([]);
      },
      onAdd: () => {
        setPendingRole(undefined);
        setSelectedRole(pendingRole);
        setPendingPeopleSlugs([]);
        setSelectedPeopleSlugs([]);
      },
      onClose: () => {
        setPendingRole(undefined);
        setPendingPeopleSlugs([]);
      },
    },
  };

  const secondaryCondition: ComparisonToolCondition = {
    title: 'Select people',
    subtitle: 'Choose people to compare',
    chipLabel: selectedPeopleSlugs.length > 0 ? `${selectedPeopleSlugs.length} ${selectedPeopleSlugs.length > 1 ? 'people' : 'person'} selected` : undefined,
    onClick: () => setPendingPeopleSlugs(selectedPeopleSlugs),
    panel: {
      supertitle: 'Analytics',
      title: 'Select people',
      subtitle: 'Please select up to 5 people from the list', // TODO
      list: <PeopleComparisonList pendingPeopleSlugs={pendingPeopleSlugs} setPendingPeopleSlugs={setPendingPeopleSlugs} />,
      onCancel: () => {
        setPendingPeopleSlugs([]);
        setSelectedPeopleSlugs([]);
      },
      onAdd: () => {
        setPendingPeopleSlugs([]);
        setSelectedPeopleSlugs(pendingPeopleSlugs);
      },
      onClose: () => setPendingPeopleSlugs([]),
    },
  };

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader
        breadcrumbs={[
          {
            name: 'Analytics',
            url: PagePath.analyticsBase,
          },
          {
            name: 'Comparison Tools',
            url: window.location.pathname,
          },
        ]}
        title="Comparison Tools"
      />

      <ComparisonToolWidget title="Compare people for a role" primaryCondition={primaryCondition} secondaryCondition={secondaryCondition} />

      <RoleFitAverageWidget roleSlug={selectedRole?.slug} peopleSlugs={selectedPeopleSlugs} />

      <RoleFitComparisonWidget roleSlug={selectedRole?.slug} peopleSlugs={selectedPeopleSlugs} />
    </div>
  );
}
