import React, { Fragment } from 'react';
import { INotificationLayoutProps } from '../../../../notifications/notifications.models';
import { levelNameAndIconMap } from './level-achieved-modal.levels';
import confettiLottie from '../../../../design/lottie/confetti.lottie.json';
import { LottieAsset } from '../../../../design/lottie/lottie-asset/lottie-asset';
import { LevelAchievedModalChip } from './level-achieved-modal.styles';
import { BadgeIconChip, Button, colourString, levelColourType } from '@keplerco/core';

export function LevelAchievedModalLayout(props: INotificationLayoutProps): JSX.Element {
  const level = props.level ? Math.floor(props.level) : 1;
  const iconLevel = level > 5 ? 5 : level;
  const levelMetaData = levelNameAndIconMap[Math.max(iconLevel - 1, 0)];

  return (
    <Fragment>
      <div className="dialogBackground">
        <LottieAsset loop autoplay data={confettiLottie} />
      </div>

      <div className="modalContent" style={{ textAlign: 'center' }}>
        <h2 className="heading2">{props.title ?? 'You crushed it!'}</h2>

        <div className="badgeIconContainer">
          <object data={levelMetaData.iconUrl} type="image/svg+xml">
            <img src={levelMetaData.iconUrl} alt={levelMetaData.name} />
          </object>

          <BadgeIconChip colour={levelColourType(level)} value={level} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'center' }}>
          <h2 className="heading2">{levelMetaData.name}</h2>

          <LevelAchievedModalChip className="subtitle" backgroundColour={levelColourType(level)}>
            Level {level}
          </LevelAchievedModalChip>

          <h6 className="subtitle" style={{ color: colourString('accent-2') }}>
            You've just levelled up
          </h6>

          <Button onClick={props.onAction} chubby type={'button'} filled arrow={false}>
            Thanks!
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
